import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import ground from "../../../assets/jpeg/services/service5.jpg";
// import ground1 from "../../../assets/jpeg/ground/ground1.jpg";
import ground2 from "../../../assets/jpeg/ground/ground2.jpg";

import color from "../../../assets/styles/colors";
import translationsSwitch from "../../../translations";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "cover",
    width: "100%",

    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  image2: {
    width: "100%",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
}));

const GroundWires = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("groundCable", lang);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={ground}
        title={Data[0].text}
        naviChild1={Data[4].text}
        naviChild2={Data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              {Data[1].text}
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={ground2} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography variant="h4" className={classes.subtitle}>
              {Data[2].text}
            </Typography>
            {Data[3].text.map(function (data) {
              return (
                <ul>
                  <li>{data}</li>
                </ul>
              );
            })}
          </Grid>
          {/* <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={ground2} alt="" />
          </Grid> */}
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default GroundWires;
