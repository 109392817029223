export const translations = [
  {
    PL: (
      <>
        Zarejestrowano <br />w Sądzie Rejonowym Lublin – Wschód
        <br />w Lublinie z siedzibą w Świdniku VI Wydział Gospodarczy KRS
        <br />
        KRS: <b>0000120091</b>
        <br />
        NIP: <b>946-21-22-162</b>
        <br />
        Kapitał zakładowy: <b>1.816.000 PLN</b>
      </>
    ),
    DE: (
      <>
        Eingetragen <br />
        am Amtsgericht Lublin-Wschód in Lublin mit Sitz in Świdnik
        <br />
        VI. Wirtschaftsabteilung des Nationalen Handelsregisters
        <br />
        KRS: <b>0000120091</b>
        <br />
        NIP: <b>946-21-22-162</b>
        <br />
        Stammkapital: <b>1.816.000 PLN</b>
      </>
    ),
    FR: (
      <>
        Inscrite au tribunal de district de Lublin-Wschód à Lublin avec son
        siège social à Świdnik, 6e division commerciale du registre judiciaire
        national
        <br />
        KRS : <b>0000120091</b>
        <br />
        PIN : <b>946-21-22-162</b>
        <br />
        Capital social : <b>1 816 000 PLN</b>
      </>
    ),
    EN: (
      <>
        Registered <br />
        at District Court Lublin-Wschód in Lublin based in Świdnik
        <br />
        6th Commercial Division of National Court Register
        <br />
        KRS: <b>0000120091</b>
        <br />
        VAT: <b>PL9462122162</b>
        <br />
        Nominal Capital: <b>1.816.000 PLN</b>
      </>
    ),
    IT: (
      <>
        Registrato <br />
        presso il Tribunale distrettuale di Lublin - Wschód
        <br />
        a Lublin con sede legale in Świdnik VI Divisione economica del Registro
        Nazionale dei Tribunali (KRS)
        <br />
        KRS: <b>0000120091</b>
        <br />
        NIP: <b>946-21-22-162</b>
        <br />
        Capitale sociale: <b>PLN 1.816.000</b>
      </>
    ),
  },
  {
    PL: (
      <>
        DANE KONTAKTOWE:
        <br />
        <b>ELTEM Sp. z o.o.</b>
        <br />
        <b>ul. SMOLUCHOWSKIEGO 3 20-474 Lublin</b>
        <br />
        <br />
      </>
    ),
    DE: (
      <>
        KONTAKTDATEN:
        <br />
        <b>ELTEM Sp. z o.o.</b>
        <br />
        <b>ul. SMOLUCHOWSKIEGO 3 20-474 Lublin</b>
        <br />
        <br />
      </>
    ),
    FR: (
      <>
        Nous contacter
        <br />
        <b>ELTEM Sp. z o.o.</b>
        <br />
        <b>ul. SMOLUCHOWSKIEGO 3 20-474 Lublin</b>
        <br />
        <br />
      </>
    ),
    EN: (
      <>
        CONTACT DETAILS
        <br />
        <b>ELTEM Sp. z o.o.</b>
        <br />
        <b>ul. SMOLUCHOWSKIEGO 3 20-474 Lublin</b>
        <br />
        <br />
      </>
    ),
    IT: (
      <>
        CONTATTO
        <br />
        <b>ELTEM Sp. z o.o.</b>
        <br />
        <b>ul. SMOLUCHOWSKIEGO 3 20-474 Lublin</b>
        <br />
        <br />
      </>
    ),
  },
  {
    PL: "Polityka cookies",
    DE: "Cookie-Richtlinie",
    FR: "politique de cookies",
    EN: "Cookies Policy",
    IT: "Informativa sui cookie",
  },
  {
    PL: "Polityka prywatności",
    DE: "Datenschutzrichtlinie",
    FR: "politique de confidentialité",
    EN: "Policy Privacy Policy",
    IT: "Informativa sulla privacy",
  },
];
