const Data = {
  jobs: [
    {
      id: 1,
      title: "Monter wiązek elektrycznych",
      url: "/career/",
      nrRef: "PRAC/LUB/2023",
      JobList: [
        {
          title: "Obowiązki:",
          list: [
            {
              name: "Praca przy montażu wiązek elektrycznych",
            },
            {
              name: "Wykonywanie procesu produkcyjnego zgodnie z wymaganiami technologicznymi i jakościowymi",
            },
          ],
        },
        {
          title: "Wymagania:",
          list: [
            {
              name: "Doświadczenie przy pracy na produkcji będzie wielkim atutem",
            },
            {
              name: "Osoby bez doświadczenia – przyuczymy do zawodu",
            },
            {
              name: "Chęci do pracy",
            },
            {
              name: "Dyspozycyjność do pracy w systemie jednozmianowym od 7:00 do 15:00 (od poniedziałku do piątku)",
            },
            {
              name: "Mile widziane kobiety",
            },
          ],
        },
        {
          title: "Oferujemy:",
          list: [
            {
              name: "Przyuczenie do pracy",
            },
            {
              name: "Miłą i przyjemną atmosferę",
            },
            {
              name: "Zatrudnienie w stabilnej firmie o ugruntowanej pozycji na rynku",
            },
            {
              name: "Wypłata wynagrodzenia zawsze na czas",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Brygadzista Montażu",
      url: "/career/",
      nrRef: "BRYG/LUB/2023",
      JobList: [
        {
          title: "Obowiązki:",
          list: [
            {
              name: "Praca przy montażu wiązek elektrycznych",
            },
            {
              name: "Kierowanie pracą zespołem pracowników i sporządzanie raportów o wynikach (ocena i poprawa parametrów produkcji)",
            },
            {
              name: "Odpowiedzialność za realizację bieżącego planu produkcyjnego",
            },
            {
              name: "Prowadzenie rozliczenia produkcji",
            },
            {
              name: "Szkolenie podległego zespołu",
            },
            {
              name: "Dbanie o zachowanie standardów jakości przy współpracy z innymi komórkami firmy",
            },
            {
              name: "Motywowanie zespołu do jakościowej i wydajnej pracy",
            },
            {
              name: "Tworzenie planów pracy podległego zespołu",
            },
            {
              name: "Rozwiązywanie bieżących problemów produkcyjnych i organizacyjnych przy ścisłej współpracy z innymi komórkami firmy",
            },
            {
              name: "Wdrażanie nowych wyrobów",
            },
          ],
        },
        {
          title: "Wymagania:",
          list: [
            {
              name: "Doświadczenie na podobnym stanowisku minimum rok",
            },
            {
              name: "Znajomość funkcjonowania środowiska produkcyjnego zmysł techniczny",
            },
            {
              name: "Komunikatywność, sumienność, zdolności interpersonalne",
            },
            {
              name: "Umiejętność podejmowania decyzji",
            },
            {
              name: "Umiejętność obsługi komputera MS Office (Word, Excel)",
            },
            {
              name: "Dobra organizacja pracy własnej i podległego zespołu",
            },
            {
              name: "Umiejętność planowania i reagowania na zmiany",
            },
          ],
        },
        {
          title: "Oferta:",
          list: [
            {
              name: "Odpowiedzialną i pełna wyzwań pracę w międzynarodowym środowisku",
            },
            {
              name: "Atrakcyjne wynagrodzenie w oparciu o stabilne zatrudnienie",
            },
            {
              name: "Możliwość zdobywania nowych doświadczeń i rozwoju zawodowego",
            },
            {
              name: "Możliwość dołączenia do grupowego ubezpieczenia PZU",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu sportowego MEDICOVER SPORT",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: "MONTER WIĄZEK PROTOTYPOWYCH",
      url: "/career/",
      nrRef: "PROT/2023/08",
      JobList: [
        {
          title: "Obowiązki:",
          list: [
            {
              name: "Praca przy montażu wiązek elektrycznych",
            },
            {
              name: "Wprowadzanie zmian na dokumentacji.",
            },
            {
              name: "Wykonywanie procesu produkcyjnego zgodnie z wymaganiami technologicznymi i jakościowymi",
            },
          ],
        },
        {
          title: "Wymagania:",
          list: [
            {
              name: "Doświadczenie przy pracy na produkcji będzie wielkim atutem",
            },
            {
              name: "Osoby bez doświadczenia – przyuczymy do zawodu",
            },
            {
              name: "Chęci do pracy",
            },
            {
              name: "Dyspozycyjność do pracy w systemie jednozmianowym 7-15 od poniedziałku do piątku",
            },
            {
              name: "Mile widziane kobiety",
            },
          ],
        },
        {
          title: "Oferujemy:",
          list: [
            {
              name: " Przyuczenie do pracy (w tym: nauka czytana dokumentacji technicznej i wykonywania wszystkich wymaganych prac) oraz pomoc przy realizacji przydzielonych zadań podczas samodzielnej pracy",
            },
            {
              name: "Szkolenia stanowiskowe od pierwszych dni zatrudnienia",
            },
            {
              name: "Możliwość wieloletniej współpracy",
            },
            {
              name: "Miłą i przyjemną atmosferę",
            },
            {
              name: "Zatrudnienie w stabilnej firmie o ugruntowanej pozycji na rynku",
            },
            {
              name: "Wypłata wynagrodzenia zawsze na czas",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu medycznego PZU",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu sportowego MEDICOVER SPORT",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      title: "Pracownik produkcji - system dwuzmianowy",
      url: "/career/",
      nrRef: "OPP/LUB/2023",
      JobList: [
        {
          title: "Obowiązki:",
          list: [
            {
              name: "Obsługa automatów do cięcia i zaciskania(skręcanie przewodów, cięcie węży)",
            },
            {
              name: "Wykonywanie procesu produkcyjnego zgodnie z wymaganiami technologicznymi i jakościowymi",
            },
          ],
        },
        {
          title: "Wymagania:",
          list: [
            {
              name: "Doświadczenie przy pracy na produkcji będzie wielkim atutem, ale nie jest to warunek konieczny",
            },
            {
              name: "Osoby bez doświadczenia – przyuczymy do zawodu",
            },
            {
              name: "Chęci do pracy",
            },
            {
              name: "Dyspozycyjność do pracy od poniedziałku do piątku w systemie dwuzmianowym 6-14, 14-22",
            },
          ],
        },
        {
          title: "Oferujemy:",
          list: [
            {
              name: "Przyuczenie do pracy",
            },
            {
              name: "Miłą i przyjemną atmosferę",
            },
            {
              name: "Zatrudnienie w stabilnej firmie o ugruntowanej pozycji na rynku",
            },
            {
              name: "Wypłata wynagrodzenia zawsze na czas",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu medycznego PZU",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu sportowego MEDICOVER SPORT.",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      title: "Magazynier",
      url: "/career/",
      nrRef: "MAG/LUB11/2023",
      JobList: [
        {
          title: "Obowiązki:",
          list: [
            {
              name: "Praca przy montażu wiązek elektrycznych",
            },
            {
              name: "Wykonywanie procesu produkcyjnego zgodnie z wymaganiami technologicznymi i jakościowymi",
            },
          ],
        },

        {
          title: "Obowiązki:",
          list: [
            {
              name: "Przyjmowanie i wydawanie towarów",
            },
            {
              name: "Praca związana z prowadzeniem dokumentów magazynowych",
            },
            {
              name: "Prace związane z obsługą wózków widłowych",
            },
          ],
        },

        {
          title: "Wymagania:",
          list: [
            {
              name: "Doświadczenie w pracy na magazynie będzie wielkim atutem",
            },
            {
              name: "uprawnienia do obsługi wózków –warunek konieczny",
            },
            {
              name: "Chęci do pracy",
            },
            {
              name: "Dyspozycyjność do pracy w systemie jednozmianowym od 7:00 do 15:00",
            },
            {
              name: "Praca od poniedziałku do piątku",
            },
          ],
        },

        {
          title: "Oferujemy:",
          list: [
            {
              name: "Przyuczenie do pracy",
            },
            {
              name: "Miłą i przyjemną atmosferę",
            },
            {
              name: "Zatrudnienie w stabilnej firmie o ugruntowanej pozycji na rynku",
            },
            {
              name: "Wypłata wynagrodzenia zawsze na czas",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu medycznego PZU",
            },
            {
              name: "Możliwość dołączenia do grupowego pakietu sportowego MEDICOVER SPORT.",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      title: "Elektronik Konstruktor",
      url: "/career/",
      nrRef: "KON/ELE/2023",
      JobList: [
        {
          title: "Obowiązki:",
          list: [
            {
              name: "Praca przy montażu wiązek elektrycznych",
            },
            {
              name: "Wykonywanie procesu produkcyjnego zgodnie z wymaganiami technologicznymi i jakościowymi",
            },
          ],
        },

        {
          title: "Podstawowe obowiązki:",
          list: [
            {
              name: "Projektowanie urządzeń elektronicznych dla motoryzacji oraz rolnictwa",
            },
            {
              name: "Dokonywanie ustaleń technicznych z Klientem odnośnie nowych wyrobów i uzyskanie od Klienta złożeń i wymagań technicznych.",
            },
            {
              name: "Wykonywanie dokumentacji konstrukcyjnej i produkcyjnej",
            },
          ],
        },

        {
          title: "Wymagania:",
          list: [
            {
              name: "Umiejętność montażu i wykonywania podstawowych pomiarów w układach elektronicznych.",
            },
            {
              name: "Umiejętność pisania programów w języku ”C” dla mikrokontrolerów 32 i 8mio bitowych.",
            },
            {
              name: "Znajomość architektury mikrokontrolerów AVR i STM32",
            },
            {
              name: "Znajomość podstawowych zagadnień elektronicznych z techniki cyfrowej i analogowej",
            },
            {
              name: "Znajomość języka angielskiego w stopniu umożliwiającym czytanie dokumentacji technicznej.",
            },
            {
              name: "Wykształcenie średnie lub wyższe techniczne.",
            },
            {
              name: "Obsługa programów do projektowania płytek drukowanych i CADa (Altium Designer, AutoCad).",
            },
            {
              name: "Bardzo dobra umiejętność obsługi komputera, pakietu MS Office,",
            },
          ],
        },
        {
          title: "Oferujemy:",
          list: [
            {
              name: "Pracę w firmie o ugruntowanej pozycji na rynku",
            },
            {
              name: "Stabilne warunki zatrudnienia – umowa o pracę",
            },
            {
              name: "Wynagrodzenie adekwatne do realizowanych zadań",
            },
            {
              name: "Możliwość rozwoju zawodowego w firmie o międzynarodowym środowisku",
            },
            {
              name: "Możliwość dołączenia do grupowego ubezpieczenia PZU",
            },
            {
              name: "Możliwość dołączenia do pakietu MEDICOVER SPORT",
            },
          ],
        },
      ],
    },
  ],
};

export default Data;
