import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/baners/banerCertificates.jpg";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/styles/colors";
import pdf9001 from "../../../assets/pdf/ELTEM_9001_certificate_en_pl_de_2023_2.pdf";
import pdf14001 from "../../../assets/pdf/ELTEM_14001_certificate_en_pl_de_2023_2.pdf";
import qualityPolicy from "../../../assets/pdf/Polityka_jakosci_ELTEM.pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import cert9001 from "../../../assets/svg/sgs9001.svg";
import cert14001 from "../../../assets/svg/sgs14001.svg";
import { useParams } from "react-router-dom";
import translationsSwitch from "../../../translations";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "",
    width: "100%",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    margin: "20px",
  },
  text: {
    "&>b": {
      color: color.green3,
    },
  },
  grid: {
    padding: "20px",
  },
  imageContainer: {
    padding: "20px",
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      display: "block",
      "&>img": {
        margin: "0px",
        height: "300px",
      },
    },
  },
  link: {
    marginTop: "20px",
    color: color.green3,
    "&:hover": {
      color: color.green2,
    },
  },
  icon: {
    marginRight: "10px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
    marginTop: "20px",
    textTransform: "uppercase",
  },
}));

const QualityPolicy = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("qualitypolicy", lang);
  // console.log(Data);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title={Data[0].text}
        naviChild1={Data[9].text}
        naviChild2={Data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography className={classes.text}>{Data[1].text}</Typography>
          </Grid>
          <Grid variant="h4" className={classes.grid} item xs={12} md={12}>
            <Typography className={classes.subtitle}>{Data[2].text}</Typography>
            {Data[3].text.map(function (data) {
              return (
                <ul>
                  <Typography className={classes.text}>
                    {"- "}
                    {data}
                  </Typography>
                </ul>
              );
            })}

            <Typography className={classes.text}>{Data[4].text}</Typography>
          </Grid>

          <Grid
            className={classes.imageContainer}
            align="center"
            item
            xs={12}
            lg={4}
            md={6}
          >
            <img className={classes.image} src={cert9001} alt="" />
          </Grid>
          <Grid
            className={classes.imageContainer}
            align="center"
            item
            xs={12}
            lg={4}
            md={6}
          >
            <img className={classes.image} src={cert14001} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} sm={12} lg={4}>
            <Typography className={classes.subtitle} variant="h5">
              {Data[5].text}
            </Typography>
            <Typography>{Data[6].text}</Typography>
            <br />
            <a
              rel="noreferrer"
              className={classes.link}
              href={pdf9001}
              target="_blank"
            >
              <PictureAsPdfIcon className={classes.icon} />
              ISO 9001:2015 PL EN DE
            </a>
            <br />
            <a
              rel="noreferrer"
              className={classes.link}
              href={pdf14001}
              target="_blank"
            >
              <PictureAsPdfIcon className={classes.icon} />
              ISO 14001:2015 PL EN DE
            </a>
            <br />
            <a
              rel="noreferrer"
              className={classes.link}
              href={qualityPolicy}
              target="_blank"
            >
              <PictureAsPdfIcon className={classes.icon} />
              {Data[0].text}
            </a>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default QualityPolicy;
