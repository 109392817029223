export const translations = [
  {
    PL: "Zarządzanie jakością",
    DE: "Qualitätsmanagement",
    FR: "La gestion de la qualité",
    EN: "Quality management",
    IT: "Gestione della qualità",
  },

  {
    PL: "jakość",
    DE: "qualität",
    FR: "qualité",
    EN: "quality",
    IT: "qualità",
  },
  {
    PL: " Nasze wewnętrzne procedury kontrolne oraz wymagania jakościowe potwierdziła firma DNV, przyznając nam w 2005 certyfikat w zakresie systemu zarządzania jakością zgodny z normą ISO 9001:2000 oraz w roku 2008 certyfikat zarządzania środowiskowego według normy ISO 14001:2004. Od 15 Lutego 2023 zgodność naszego systemu zarządzania jakością i środowiskiem z normami ISO 9001:2015 oraz ISO 14001:2015 potwierdzają certyfikaty wydane przez firmę SGS.",
    DE: "Unsere internen Kontrollverfahren und Qualitätsanforderungen wurden von DNV bestätigt, die uns 2005 Zertifikat im Bereich des Qualitätsmanagementsystems nach ISO 9001:2000 und 2008 Umweltmanagementzertifikat nach ISO 14001: 2004 ausgestellt hat.\nDie Einhaltung unseres Qualitäts- und Umweltmanagementsystems mit den Normen ISO 9001: 2015 und ISO 14001:2015 bestätigen seit dem 15 Februar  . 2023 die Zertifikate von Fa. SGS.",
    FR: " Nos procédures de contrôle interne et nos exigences de qualité ont été confirmées par DNV, qui a certifié notre système de gestion de la qualité conformément à la norme ISO 9001:2000 en 2005 et notre certificat de gestion environnementale conformément à la norme ISO 14001:2004 en 2008. À compter du 15 février 2023, la conformité de notre système de gestion de la qualité et de l'environnement avec les normes ISO 9001:2015 et ISO 14001:2015 est confirmée par des certificats délivrés par SGS.",
    EN: "Our internal control procedures and quality requirements have been aproved by DNV company by awarding to us in 2005 a certificate in quality management system compliant with the standard ISO 9001:2000 and in 2008 environmental management certificate according to the standard ISO 14001:2004.\nSince 15 February 2023 the compliance of our quality and environmental management system with standards ISO 9001:2015 and ISO 14001:2015 have been certificated by the company SGS.",
    IT: "Le nostre procedure interne di controllo e le esigenze di qualità ha confermato la ditta DNV, che, nel 2005 ci ha concesso il certificato nell’ambito del sistema della gestione qualità conforme alla norma ISO 9001:2000 e nel 2008 il certificato della gestione ambientale secondo la norma ISO 14001:2004.\nDal 15 Febbraio 2023 la conformità del nostro sistema della gestione qualità ed ambientale con le norme ISO 9001:2015 e ISO 14001:2015 confermano i certificati emessi dall’azienda SGS.",
  },
  {
    PL: "Linki do pobrania:",
    DE: "Download-Links:",
    FR: "Liens à télécharger :",
    EN: "Download:",
    IT: "Link per il download:",
  },
  {
    PL: "(Otwierają się na nowej zakładce.)",
    DE: "(Sie werden in einem neuen Tab geöffnet).",
    FR: "Les liens s'ouvrent dans un nouvel onglet",
    EN: "(Open in a new tab.)",
    IT: "(Si aprono in una nuova scheda).",
  },
  {
    PL: "Polityka Jakości",
    DE: "Qualitätspolitik",
    FR: "Politique de qualité",
    EN: "Quality policy",
    IT: "Politica di qualità",
  },
  {
    PL: "Kontrola na produkcji",
    DE: "Produktionskontrolle",
    FR: "Contrôle de la production",
    EN: "Production control",
    IT: "Controllo di produzione",
  },
  {
    PL: "Stosujemy kontrolę wieloetapową i wielopunktową na każdym z etapów produkcji:",
    DE: "In jeder Produktionsphase führen wir mehrstufige und mehrstufige Kontrollen durch:",
    FR: "Nous procédons à des inspections en plusieurs étapes et en plusieurs points à chaque stade de la production :",
    EN: "We perform multidimensional control on each production stage:",
    IT: "Il controllo è composto da molti fasi, è multiplo in ogni tappa della produzione ",
  },
  {
    PL: "Magazyn materiałów",
    DE: "Materiallager",
    FR: "Stockage des matériaux",
    EN: "Material warehouse",
    IT: "Il magazzino dei materiali",
  },
  {
    PL: "kontrola dostaw materiałów wykorzystywanych do dalszej produkcji.",
    DE: "Einganskontrolle von den zur weiteren Produktion verwendeten  Komponenten.",
    FR: "le contrôle de la fourniture des matériaux utilisés pour la production ultérieure.",
    EN: "incoming goods inspection of materials used in further production",
    IT: "controllo delle consegne dei materiali usati nella nostra produzione",
  },
  {
    PL: "Hala maszyn",
    DE: "Maschinenhalle",
    FR: "Hall des machines",
    EN: "Automatic production hall",
    IT: "Sala macchine",
  },
  {
    PL: [
      "ciągła kontrola jakości zaciskanych terminali, dzięki wykorzystaniu modułów CFM na maszynach Komax i prasach mimośrodowych,",
      "dodatkowa kontrola półautomatyczna obejmującą pomiar wysokość zacisku oraz siłę zrywania i jej porównanie z wartościami wzorcowymi wykonywana przez wykwalifikowanych pracowników przed i po zakończeniu danego zlecenia produkcyjnego.",
    ],
    DE: [
      "kontinuierliche Qualitätskontrolle von gecrimpten Kontakten durch den Einsatz von CFM-Modulen auf Komax-Maschinen und Exzenterpressen,",
      "Eine zusätzliche halbautomatische Kontrolle durch Messung der Crimphöhe und der Reißkraft und deren Vergleich mit den Referenzwerten, die von qualifizierten Mitarbeitern vor und nach Abschluss des jeweiligen Fertigungsauftrags durchgeführt wird.",
    ],
    FR: [
      "un contrôle continu de la qualité des terminaux sertis, grâce à l'utilisation de modules CFM sur les machines Komax et les presses excentriques,",
      "un contrôle supplémentaire semi-automatique comprenant la mesure de la hauteur de serrage et de la force de rupture et sa comparaison avec les valeurs de référence, effectué par des employés qualifiés avant et après l'achèvement de l'ordre de production concerné.",
    ],
    EN: [
      "constant quality control of crimped terminals with crimp force monitors on Komax machines and eccentric presses",
      "an additional semi-automatic check that includes measurement of the clamp height and pull force and its verification against reference values carried out by qualified employees before and after production of each order.",
    ],
    IT: [
      "controllo continuo della qualità dei terminali aggraffati attraverso l’uso dei moduli CFM sulle macchine KOMAX e sulle presse eccentriche",
      "un ulteriore controllo semiautomatico che comprende la misurazione dell'altezza della pinza e della forza di trazione e la sua verifica rispetto ai valori di riferimento, effettuata da personale qualificato prima e dopo la produzione di ogni ordine.",
    ],
  },
  {
    PL: "Montaż",
    DE: "Montage",
    FR: "Assemblée",
    EN: "Assembly",
    IT: "Assemblaggio",
  },
  {
    PL: [
      "kontrola poprawności montażu przez pracowników zespołu jakości w toku produkcji.",
      "cykliczne szkolenia pracowników mające na celu podniesienie ich umiejętności oraz kwalifikacji.",
      "precyzyjna dokumentacja techniczna i pomoce w postaci zdjęć, opisów na stołach montażowych, itp. \nSystem zapobiegania błędom z wykorzystaniem narzędzi Poka Yoke.",
    ],
    DE: [
      "Kontrolle der Korrektheit der Montage durch die Qualitätsmitarbeiter während der Produktion.",
      "Regelmäßige Weiterbildung der Mitarbeiter zur Verbesserung ihrer Fähigkeiten und Qualifikationen.",
      "Präzise technische Dokumentation und Hilfsmittel in Form von Fotos, Beschreibungen auf Montagetafeln usw. \nFehlervermeidungssystem mit Poka Yoke Werkzeugen.",
    ],
    FR: [
      "le contrôle de la conformité de l'assemblage par les membres de l'équipe qualité au cours de la production.",
      "la formation périodique des employés afin d'améliorer leurs compétences et leurs qualifications.",
      "une documentation technique précise et des aides sous forme de photos, de descriptions sur les tables de montage, etc. \nSystème de prévention des erreurs à l'aide des outils Poka Yoke.",
    ],
    EN: [
      "control of the proper assembly by quality team members during production.",
      "periodic training of employees to improve their skills and qualifications.",
      "detailed technical documentation and supporting materials such as pictures, descriptions on assembly tables, etc. Error prevention system using Poka Yoke tools.",
    ],
    IT: [
      "controllo del corretto assemblaggio da parte dei membri del reparto qualità durante la produzione.",
      "formazione periodica dei dipendenti per migliorarne le competenze e le qualifiche.",
      "documentazione tecnica dettagliata e materiale di supporto come immagini, descrizioni su tabelle di montaggio, ecc. Sistema di prevenzione degli errori con strumenti Poka Yoke.",
    ],
  },
  {
    PL: "Kontrola jakości",
    DE: "Qualitätskontrolle",
    FR: "Contrôle de la qualité",
    EN: "Quality control",
    IT: "Controllo qualità",
  },
  {
    PL: [
      "Sprawdzenie zgodności wykonania wiązki z dokumentacją techniczną oraz estetyki wykonania. Wystawienie uzgodnionych z klientem dokumentów.",
    ],
    DE: [
      "Überprüfung der Übereinstimmung des Kabelsatzes mit den technischen Unterlagen und der Ästhetik der Ausführung. Ausstellung der mit dem Kunden vereinbarten Dokumente.",
    ],
    FR: [
      "Vérification de la conformité de la faisceau avec la documentation technique et de l'esthétique de l'exécution. Délivrance des documents convenus avec le client.",
    ],
    EN: [
      "Checking the conformity of the harness workmanship with the technical documentation and the aesthetically pleasing performance. Issue of documents as agreed with the customer.",
    ],
    IT: [
      "Verifica della conformità della lavorazione dei cablaggi alla documentazione tecnica e delle prestazioni estetiche. Rilascio dei documenti come concordato con il cliente.",
    ],
  },
  {
    PL: "Kontrola Elektryczna",
    DE: "Elektrische Kontrolle",
    FR: "Contrôle électrique",
    EN: "Electrical control",
    IT: "Controllo elettrico",
  },
  {
    PL: [
      "Gotowe wiązki sprawdzane na stołach kontrolnych. Indywidualny program kontrolny dla danego projektu wiązki, sprawdza poprawność wszystkich połączeń elektrycznych, wartości zastosowanych rezystorów, diod",
    ],
    DE: [
      "Fertige Kabelbäume werden auf Prüftischen geprüft. Individuelles Prüfprogramm für ein bestimmtes Kabelbaumdesign, prüft die Korrektheit aller elektrischen Verbindungen, Werte der verwendeten Widerstände, Dioden",
    ],
    FR: [
      "Les faisceaux finis sont vérifiés sur des tables de contrôle. Programme d'inspection individuel pour une conception de harnais donnée, vérifiant l'exactitude de toutes les connexions électriques, les valeurs des résistances utilisées, les diodes, etc.",
    ],
    EN: [
      "Ready-made harnesses are inspected on control tables. Individual inspection programme for each harness design, checks correctness of all electrical connections, values of resistors used, diodes",
    ],
    IT: [
      "I cablaggi pronti vengono verificati su tavole di controllo. Il programma di verifica individuale per ogni progetto di cablaggio controlla la correttezza di tutte le connessioni elettriche, i valori delle resistenze utilizzate e dei diodi.",
    ],
  },
  {
    PL: "Kompletacja i sprawdzanie",
    DE: "Kommissionierung und Kontrolle",
    FR: "Compléter et vérifier",
    EN: "Completion and testing",
    IT: "Completamento e collaudo",
  },
  {
    PL: [
      "Kompletacja wiązki, uzupełnienie o elementy dodatkowe, np. przekaźniki, bezpieczniki itp.",
      "Finalna kontrola jakościowa, zatwierdzenie wyrobu gotowego.",
    ],
    DE: [
      "Vervollständigung des Kabelbaums, Hinzufügen zusätzlicher Komponenten, z. B. Relais, Sicherungen usw.",
      "Endgültige Qualitätskontrolle, Freigabe des Endprodukts.",
    ],
    FR: [
      "Compléter le faisceau, ajouter des composants supplémentaires, par exemple des relais, des fusibles, etc.",
      "Contrôle final de la qualité, approbation du produit fini.",
    ],
    EN: [
      "Completion of the harness, supplementing with additional components, e.g. relays, fuses, etc.",
      "Final quality control, approval of the finished product.",
    ],
    IT: [
      "Completamento del cablaggio, integrazione con componenti aggiuntivi, ad esempio relè, fusibili, ecc.",
      "Controllo finale della qualità, approvazione del prodotto finito.",
    ],
  },
  {
    PL: "Oznakowanie i pakowanie",
    DE: "Kennzeichnung und Verpackung",
    FR: "Étiquetage et emballage",
    EN: "Labelling and packaging",
    IT: "Etichettatura e imballaggio",
  },
  {
    PL: [
      "wszystkie wiązki są oznaczone etykietami, pozwalającymi na pełną identyfikowalność wyrobu zarówno w dniu jego wykonania, jak i po wielu latach użytkowania. Etykiety zawierają dane wymagane przez Klienta umieszczone według jego wytycznych",
      "Wyroby gotowe pakowane są w indywidualne worki foliowe, umieszczane w kartonach zbiorczych i wysyłane na paletach. Możliwe jest stosowanie sposobu pakowania wyrobów według wytycznych klienta.",
    ],
    DE: [
      "Alle Kabelbäume sind mit Etiketten versehen, die eine vollständige Rückverfolgbarkeit des Produkts sowohl am Tag der Herstellung als auch nach vielen Jahren der Nutzung ermöglichen. Die Etiketten enthalten die vom Kunden geforderten Daten, die nach seinen Anweisungen angebracht werden",
      "Die fertigen Produkte werden in einzelne Plastikbeutel verpackt, in Umkartons gelegt und auf Paletten versendet. Die Verpackung von Produkten nach Kundenspezifikationen ist möglich.",
    ],
    FR: [
      "toutes les faisceaux sont marqués par des étiquettes, ce qui permet une traçabilité totale du produit, tant le jour de la fabrication qu'après de nombreuses années d'utilisation. Les étiquettes contiennent les données requises par le client, placées selon ses instructions",
      "Les produits finis sont emballés dans des sacs en plastique individuels, placés dans des cartons principaux et expédiés sur des palettes. Il est possible d'emballer les produits selon les spécifications du client.",
    ],
    EN: [
      "All harnesses are specially labeled and fully traceable both on the production day and after many years of exploitation. They have all data required by the customer placed on the label according to customer instructions.",
      "Finished products are packed in individual plastic bags, put into multipack cartons and shipped on pallets. Packaging of products according to customer specifications is possible.",
    ],
    IT: [
      "Tutti i cablaggi sono appositamente etichettati e completamente rintracciabili sia il giorno della produzione che dopo molti anni di utilizzo. Tutti i dati richiesti dal cliente sono riportati sull'etichetta secondo le istruzioni del cliente stesso.",
      "I prodotti finiti vengono confezionati in sacchetti di plastica individuali, inseriti in cartoni multipli e spediti su pallet. È possibile confezionare i prodotti secondo le specifiche del cliente.",
    ],
  },
];
