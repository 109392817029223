import serviceImage1 from "../../assets/jpeg/services/service1.jpg";
import serviceImage2 from "../../assets/jpeg/services/service2.jpg";
import serviceImage3 from "../../assets/jpeg/services/service3.jpg";
// import serviceImage4 from "../../assets/jpeg/services/service4.jpg";
import serviceImage5 from "../../assets/jpeg/services/service5.jpg";
// import serviceImage6 from "../../assets/jpeg/services/service6.webp"

const Data = {
  services: [
    {
      id: 1,
      label: {
        PL: "Wiązki przewodów elektrycznych",
        DE: "Elektrische Kabelbäume",
        FR: "Faisceaux de câbles électriques",
        EN: "Wire harnesses",
        IT: "Cablaggi elettrici",
      },
      url: "/offer/electrical-harnesses",
      image: serviceImage1,
    },
    {
      id: 2,
      label: {
        ID: 9,
        PL: "Zestawy wskaźników",
        DE: "Anzeigesysteme",
        FR: "l’ensemble des indicateurs",
        EN: "Instrument clusters",
        IT: "Quadri strumenti",
      },
      url: "/offer/indicator-set",
      image: serviceImage2,
    },
    {
      id: 3,
      label: {
        PL: "Elektronika na potrzeby klienta",
        DE: "Kundenspezifische Elektronik",
        FR: "Electronique à la demande du client",
        EN: "Customised electronics",
        IT: "Elettronica su richiesta del Cliente",
      },
      url: "/offer/customer-specific-electronics",
      image: serviceImage3,
    },
    // {
    //   id: 4,
    //   label: "USŁUGI BIURA KONSTRUCYJNEGO",
    //   url: "/offer/design-office-services",
    //   image: serviceImage4,
    // },
    {
      id: 5,
      label: {
        PL: "Przewody masowe i zasilające",
        DE: "Masse- und Stromkabel",
        FR: "Câbles de masse et d'alimentation",
        EN: "Mass cables and power cables",
        IT: "Cavi di massa e cavi di alimentazione",
      },
      url: "/offer/grounding-and-power-cables",
      image: serviceImage5,
    },
    // {
    //   id: 6,
    //   label: "Usługi dodatkowe",
    //   url: "/offer/additional-services",
    //   image: serviceImage6,
    // },
  ],
};

export default Data;
