import React, { useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {};

function GoogleMap(props) {
  const [stores] = useState([
    { latitude: 47.5524695, longitude: -122.0425407 },
  ]);

  function displayMarkers() {
    return stores.map((store, index) => {
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.latitude,
            lng: store.longitude,
          }}
          onClick={() => console.log("You clicked me!")}
        />
      );
    });
  }

  return (
    <Map
      google={props.google}
      zoom={8}
      style={mapStyles}
      initialCenter={{ lat: 47.444, lng: -122.176 }}
    >
      {displayMarkers()}
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC-JwHeCWs6tKVZK194vwL11pmVqJf3zR0",
})(GoogleMap);
