import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/svg/logo6_2.svg";
import {
  Grid,
  AppBar,
  List,
  ListItem,
  Typography,
  Card,
  Toolbar,
  Divider,
  Button,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import color from "../../assets/styles/colors";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import MenuIcon from "@material-ui/icons/Menu";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Hidden from "@material-ui/core/Hidden";
import LanguageBar from "./dynamic/LanguageBar";
import { useParams } from "react-router-dom";
import LanguageBarMobile from "./dynamic/LanguageBarMobile";
import menuItems from "../../translations/elements/menuItems";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  // MENU DESKTOP
  appBar: {
    background: "white",
    position: "fixed",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0),0px 4px 5px 0px rgba(0, 0, 0, 0.03),0px 1px 10px 0px rgba(0, 0, 0, 0)",
    // zIndex: 1000,
  },
  desktopListItem: {
    paddingRight: "12px",
    paddingLeft: "12px",
  },
  List: {
    display: "inline-flex",
    "&>li": { width: "auto !important" },
    padding: "0px",
  },
  toolBar: {
    justifyContent: "center",
  },
  logo: {
    height: "30px",
    display: "inline",
    marginLeft: "20px",
    transition: "1s",
    [theme.breakpoints.only("xs")]: {
      marginTop: "20px !important",
      marginBottom: "20px",
      marginRight: "auto",
      display: "block",
    },
    "&:hover": {
      transform: "scale(1.1)",
      transition: "1s",
    },
  },
  navChildLink: {
    transition: "2s",
    "&>a": { opacity: "0 !important" },
    "&:hover>a": {
      transition: "2s",
      opacity: "1 !important",
    },
    "&>#test": { opacity: "0 !important" },
    "&:hover>#test": { transition: "2s", opacity: "1 !important" },
  },
  menuItem: {
    color: color.green3,
    textTransform: "uppercase",
    fontWeight: "600",
    display: "relative !important",
    "&:hover": {
      color: color.green1,
      cursor: "pointer",
      "&:after": {
        transition: "1s",
        content: "''",
        height: "2px",
        width: "100%",
        background: color.green3,
        position: "absolute",
        left: "calc(100% - 100%)",
        bottom: "10px",
      },
    },
    "&:after": {
      content: "''",
      height: "2px",
      width: "0px",
      color: "black",
      background: color.green1,
      position: "absolute",
      left: "calc(60% - 100%)",
      bottom: "10px",
    },
  },
  menuItemDrop: {
    position: "unset !important",
    "&>p": {
      display: "contents",
    },
    "&>p>svg": {
      transition: "transform 1s ease-in-out",
      transform: "rotate(-90deg)",
      color: color.green1,
    },
    "&>p>a": {
      color: color.green1,
    },
    "&:hover": {
      "&>p>svg": {
        transition: "transform 0.5s ease-in-out",
        transform: "rotate(0deg)",
      },
    },
  },
  dropMenuCard: {
    boxShadow: "none",
    border: "1px black",
    position: "absolute",
    top: "32px",
    width: "350px",
    padding: "6px",
    "&>ul>li": {
      color: color.green3,
      "&:hover": {
        background: color.lightWhite,
        transition: "0.5s",
        color: color.green1,
        cursor: "pointer",
      },
    },
  },
  showMenu: {
    "&>li>div": {
      display: "none",
      transformOrigin: "top center",
    },
    "&:hover": {
      "&>li>div": {
        display: "block",
        transformOrigin: "top center",
      },
    },
  },
  //MENU DESKTOP END
  menuIcon: {
    "&>button": {
      color: color.green3,
      marginLeft: "auto",
      display: "block",
      marginRight: "10px",
      "&:active": {
        transition: "transform 0.5s ease-in-out",
        transform: "rotate(360deg)",
      },
    },
  },
  menuItemMobile: {
    color: color.green3,
    textTransform: "uppercase",
    fontWeight: "600",
    cursor: "pointer",
    "&>a": {
      color: color.green3,
      fontWeight: "400",
    },
  },
  arrowIconMobile: {
    color: color.green3,
    transition: "transform 0.5s ease-in-out",
    transform: "rotate(0deg)",
  },
  subListMobile: {
    // paddingLeft: "20px",
    // marginLeft: "20px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  rotate180: {
    transition: "transform 0.5s ease-in-out",
    transform: "rotate(180deg)",
  },
  rotate90: {
    transition: "transform 0.5s ease-in-out",
    transform: "rotate(90deg)",
    color: color.green3,
  },
  mobileMenuHide: {
    transition: "opacity 3s ease-out",
    opacity: 1,
    height: 0,
    overflow: "hidden",
    display: "block",
    webkitAnimation: "fadeIn 1s",
    animation: "fadeIn 1s",
  },
  mobileMenuShow: {
    opacity: 1,
    height: "unset",
    webkitAnimation: "fadeIn 1s",
    animation: "fadeIn 1s",
  },
  mobileMenuContainer: {
    position: "fixed",
    backgroundColor: "white",
    zIndex: "10000",
    // borderBottom: "solid 1px",
    // height: "64px",
  },
}));

function MobileMenu(props) {
  const params = useParams();
  const [showMenu, setShowMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [showOfferMenu, setShowOfferMenu] = useState(false);
  const [showProductionMenu, setShowProductionMenu] = useState(false);
  const [showQualityMenu, setShowQualityMenu] = useState(false);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={props.classes.mobileMenuContainer}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid xs={6} id="logo" item>
            <Link href={"/" + params.lang}>
              <img className={props.classes.logo} src={logo} alt="Eltem" />
            </Link>
          </Grid>
          <Grid xs={6} className={props.classes.menuIcon} id="logo" item>
            <Button onClick={() => setShowMenu(!showMenu)}>
              <MenuIcon
                className={
                  showMenu ? props.classes.rotate0 : props.classes.rotate180
                }
                fontSize="large"
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={
            showMenu
              ? props.classes.mobileMenuShow
              : props.classes.mobileMenuHide
          }
        >
          <Grid>
            <LanguageBarMobile />
          </Grid>
          <List direction="row">
            <ListItem>
              <Link style={{ textDecoration: "none" }} href={"/" + params.lang}>
                <Typography className={props.classes.menuItemMobile}>
                  {props.menuTranslation[0].text}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <ArrowForwardIosRoundedIcon
                className={
                  showAboutMenu
                    ? props.classes.rotate90
                    : props.classes.arrowIconMobile
                }
              />
              <Typography
                onClick={() => setShowAboutMenu(!showAboutMenu)}
                className={props.classes.menuItemMobile}
              >
                {props.menuTranslation[1].text}
              </Typography>
            </ListItem>
            <DropMenuMobile
              classes={props.classes}
              menuItems={menuItems.aboutCompany}
              showMenu={showAboutMenu}
              // lang={useParams().lang.toUpperCase}
            />
            <ListItem>
              <ArrowForwardIosRoundedIcon
                className={
                  showOfferMenu
                    ? props.classes.rotate90
                    : props.classes.arrowIconMobile
                }
              />
              <Typography
                onClick={() => setShowOfferMenu(!showOfferMenu)}
                className={props.classes.menuItemMobile}
              >
                {props.menuTranslation[2].text}
              </Typography>
            </ListItem>
            <DropMenuMobile
              classes={props.classes}
              menuItems={menuItems.offer}
              showMenu={showOfferMenu}
            />
            <ListItem>
              <ArrowForwardIosRoundedIcon
                className={
                  showProductionMenu
                    ? props.classes.rotate90
                    : props.classes.arrowIconMobile
                }
              />
              <Typography
                onClick={() => setShowProductionMenu(!showProductionMenu)}
                className={props.classes.menuItemMobile}
              >
                {props.menuTranslation[3].text}
              </Typography>
            </ListItem>
            <DropMenuMobile
              showMenu={showProductionMenu}
              classes={props.classes}
              menuItems={menuItems.production}
            />

            <ListItem>
              <ArrowForwardIosRoundedIcon
                className={
                  showQualityMenu
                    ? props.classes.rotate90
                    : props.classes.arrowIconMobile
                }
              />
              <Typography
                onClick={() => setShowQualityMenu(!showQualityMenu)}
                className={props.classes.menuItemMobile}
              >
                {props.menuTranslation[4].text}
              </Typography>
            </ListItem>
            <DropMenuMobile
              showMenu={showQualityMenu}
              classes={props.classes}
              menuItems={menuItems.quality}
            />

            {/* <ListItem>
              <Link
                href={"/" + params.lang + "/career"}
                style={
                  params.lang !== "pl"
                    ? { display: "none", textDecoration: "none" }
                    : { display: "unset", textDecoration: "none" }
                }
              >
                <Typography className={props.classes.menuItemMobile}>
                  {props.menuTranslation[5].text}
                </Typography>
              </Link>
            </ListItem> */}
            <ListItem>
              <Link
                style={{ textDecoration: "none" }}
                href={"/" + params.lang + "/contact-us"}
              >
                <Typography className={props.classes.menuItemMobile}>
                  {props.menuTranslation[6].text}
                </Typography>
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
//drop downmenu for Mobile VER.
const DropMenuMobile = (props) => {
  const params = useParams();
  const lang = params.lang.toUpperCase();
  return (
    <>
      <div style={props.showMenu ? { display: "unset" } : { display: "none" }}>
        {props.menuItems.map((item, index) => (
          <ListItem
            style={
              item.onlyPL === true && lang !== "PL"
                ? { display: "none" }
                : { display: "" }
            }
            key={index}
            className={props.classes.subListMobile}
          >
            <HorizontalRuleIcon className={props.classes.arrowIconMobile} />
            <Typography
              style={item.mainLink ? { display: "none" } : { display: "" }}
              className={props.classes.menuItemMobile}
            >
              <Link href={"/" + params.lang + item.url}>
                {" "}
                {item.name[`${lang}`]}
              </Link>
            </Typography>
            <Typography
              style={item.mainLink ? { display: "" } : { display: "none" }}
              className={props.classes.navChildLink}
            >
              <c
                style={{ fontWeight: "400", textDecoration: "underline" }}
                className={props.classes.menuItemMobile}
              >
                {item.name[`${lang}`]}{" "}
              </c>
              <React.Fragment>
                <ArrowForwardIosRoundedIcon
                  style={{ height: "10px", color: color.green3 }}
                  id="test"
                />
                <Link
                  className={props.classes.menuItemMobile}
                  style={{ color: color.green3 }}
                  target="_blank"
                  href={item.submenuUrl}
                >
                  {item.submenu}
                </Link>
              </React.Fragment>
            </Typography>
          </ListItem>
        ))}
      </div>
    </>
  );
};

//drop downmenu for DESKTOP VER.
const DropMenu = (props) => {
  const params = useParams();
  const lang = params.lang.toUpperCase();
  // console.log(menuItems.aboutCompany[0].name[`${lang}`]);
  return (
    <Grid item id="" className={props.classes.showMenu}>
      <ListItem className={props.classes.desktopListItem}>
        <Typography className={props.classes.menuItem}>{props.name}</Typography>
        <Card id="cards" className={props.classes.dropMenuCard}>
          <List>
            {props.menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  style={
                    item.onlyPL === true && lang !== "PL"
                      ? { display: "none" }
                      : { display: "" }
                  }
                  className={props.classes.menuItemDrop}
                >
                  <Typography
                    style={
                      item.mainLink ? { display: "none" } : { display: "" }
                    }
                  >
                    <ArrowForwardIosRoundedIcon />
                    <Link href={"/" + params.lang + item.url}>
                      {item.name[`${lang}`]}
                    </Link>
                  </Typography>
                  <Typography
                    style={
                      item.mainLink ? { display: "" } : { display: "none" }
                    }
                    className={props.classes.navChildLink}
                  >
                    <ArrowForwardIosRoundedIcon />
                    {item.name[`${lang}`]}{" "}
                    <React.Fragment>
                      <ArrowForwardIosRoundedIcon id="test" />
                      <Link target="_blank" href={item.submenuUrl}>
                        {item.submenu}
                      </Link>
                    </React.Fragment>
                  </Typography>
                </ListItem>

                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Card>
      </ListItem>
    </Grid>
  );
};

const DestkopMenu = (props) => {
  const params = useParams();
  // console.log(props.menuTranslation);
  return (
    <React.Fragment>
      {/* <title>test</title> */}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <AppBar className={props.classes.appBar} position="sticky">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12} lg={2}>
              <Link href={"/" + params.lang}>
                <img className={props.classes.logo} src={logo} alt="Eltem" />
              </Link>
            </Grid>
            <Grid item md={6}>
              <Toolbar className={props.classes.toolBar}>
                <List direction="row" className={props.classes.List}>
                  <ListItem className={props.classes.desktopListItem}>
                    <Link
                      style={{ textDecoration: "none" }}
                      href={"/" + params.lang}
                    >
                      <Typography className={props.classes.menuItem}>
                        {[props.menuTranslation[0].text]}
                      </Typography>
                    </Link>
                  </ListItem>
                  <DropMenu
                    classes={props.classes}
                    menuItems={menuItems.aboutCompany}
                    name={[props.menuTranslation[1].text]}
                  />
                  <DropMenu
                    classes={props.classes}
                    menuItems={menuItems.offer}
                    name={[props.menuTranslation[2].text]}
                  />
                  <DropMenu
                    classes={props.classes}
                    menuItems={menuItems.production}
                    name={[props.menuTranslation[3].text]}
                  />
                  <DropMenu
                    classes={props.classes}
                    menuItems={menuItems.quality}
                    name={[props.menuTranslation[4].text]}
                  />
                  {/* <ListItem
                    style={
                      params.lang !== "pl"
                        ? { display: "none", textDecoration: "none" }
                        : { display: "unset", textDecoration: "none" }
                    }
                    className={props.classes.desktopListItem}
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      href={"/" + params.lang + "/career"}
                    >
                      <Typography className={props.classes.menuItem}>
                        {[props.menuTranslation[5].text]}
                      </Typography>
                    </Link>
                  </ListItem> */}
                  <ListItem className={props.classes.desktopListItem}>
                    <Link
                      style={{ textDecoration: "none" }}
                      href={"/" + params.lang + "/contact-us"}
                    >
                      <Typography className={props.classes.menuItem}>
                        {[props.menuTranslation[6].text]}
                      </Typography>
                    </Link>
                  </ListItem>
                </List>
              </Toolbar>
            </Grid>
            <Grid item md={2}>
              <LanguageBar />
            </Grid>
          </Grid>
        </AppBar>
      </Grid>
    </React.Fragment>
  );
};

// structure of dropdown menu:

// const menuItems = {
//   aboutCompany: [
//     {
//       id: 1,
//       name: "Dofinansowanie ze środków Unii Europejskiej",
//       url: "/subsidy-from-the-European-Union",
//     },
//     { id: 2, name: "Historia", url: "/our-history" },
//     {
//       id: 3,
//       name: `Partnerzy`,
//       mainLink: true,
//       url: "#",
//       submenu: "MTA.IT",
//       submenuUrl: "https://mta.it/",
//     },
//     { id: 4, name: "Certyfikaty", url: "/certificates" },
//   ],
//   offer: [
//     {
//       id: 6,
//       name: "Wiązki przewodów elektrycznych",
//       url: "/offer/electrical-harnesses",
//     },
//     // {
//     //   id: 7,
//     //   name: "Przewody masowe i zasilające",
//     //   url: "/offer/grounding-and-power-cables",
//     // },
//     {
//       id: 8,
//       name: "Elektronika na potrzeby klienta",
//       url: "/offer/customer-specific-electronics",
//     },
//     // { id: 9, name: "Zestaw wskażników", url: "/offer/indicator-set" },
//     // {
//     //   id: 10,
//     //   name: "Usługi biura konstrukcyjnego",
//     //   url: "/offer/design-office-services",
//     // },
//     // { id: 11, name: "Usługi dodatkowe", url: "/offer/additional-services" },
//     {
//       id: 12,
//       name: "Odsprzedaż materiałów",
//       url: "/offer/sale-of-materials-and-components",
//     },
//   ],
//   production: [
//     { id: 13, name: "Park Maszynowy", url: "/production/machine-park" },
//     {
//       id: 14,
//       name: "Zastosowanie w branżach",
//       url: "/production/application-in-the-industry",
//     },
//   ],
//   quality: [
//     {
//       id: 15,
//       name: "Zarządzanie jakością",
//       url: "/quality/quality-management",
//     },
//     { id: 4, name: "Certyfikaty", url: "/certificates" },
//     { id: 5, name: "Polityka Jakości", url: "/quality/quality-policy" },
//   ],
// };

const Header = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Hidden mdDown>
        <DestkopMenu
          menuTranslation={props.menuTranslation}
          dropMenuTranslation={props.dropMenuTranslation}
          classes={classes}
        />
      </Hidden>
      <Hidden lgUp>
        <MobileMenu
          menuTranslation={props.menuTranslation}
          dropMenuTranslation={props.dropMenuTranslation}
          classes={classes}
          color={color}
        />
      </Hidden>
    </React.Fragment>
  );
};

export default Header;
