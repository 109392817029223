export const translations = [
  {
    ID: 1,
    PL: "Dofinansowanie ze środków Unii Europejskiej",
    FR: "Financement de l'Union européenne",
    EN: "EU funding",
    IT: "Finanziamento dell'Unione Europea",
    DE: "EU-Förderung",
  },
  {
    ID: 2,
    PL: "W ramach Działania 3.7 RPO WL na lata 2014 – 2020 ELTEM sp. z o.o. realizuje projekt pt:",
    FR: "Dans le cadre de la mesure 3.7 du RPO WL pour 2014 - 2020, ELTEM sp. z o.o. met en œuvre un projet intitulé :",
    EN: "As part of the Activity 3.7 RPO WL for years 2014 – 2020  ELTEM Sp. z o.o. is implementing project titled:",
    IT: "Nell'ambito della misura 3.7 della RPO WL per il periodo 2014 - 2020, ELTEM sp. z o.o. sta realizzando un progetto intitolato :",
    DE: "Im Rahmen der Maßnahme 3.7 RPO WL für die Jahre 2014-2020 setzt ELTEM Sp. z o.o. das Projekt mit dem Titel um:",
  },
  {
    ID: 3,
    PL: "„Wdrożenie do produkcji systemów inteligentnego sterowania wyposażeniem pojazdów specjalnych”",
    FR: '"Mise en production de systèmes de contrôle intelligents pour les équipements spéciaux des véhicules"',
    EN: " „Implementing of production of new controlling system, that are a result of the own work B+R”",
    IT: "Implementazione nella produzione di sistemi di controllo intelligenti per l'equipaggiamento di veicoli speciali.",
    DE: "„Einführung in die Produktion von intelligenten Steuerungssystemen für die Ausstattung von Spezialfahrzeugen“",
  },
  {
    ID: 4,
    PL: "Cel projektu:",
    FR: "Objectif du projet :",
    EN: "Project purpose:",
    IT: "Obiettivo del progetto :",
    DE: "Projektziel:",
  },
  {
    ID: 5,
    PL: "Celem projektu jest wdrożenie wyników samodzielnie przeprowadzonych prac badawczo–rozwojowych, zakończonych opracowaniem innowacyjnych systemów inteligentnego sterowania.",
    FR: "L'objectif du projet est de mettre en œuvre les résultats de travaux de recherche et de développement menés de manière indépendante, aboutissant au développement de systèmes de contrôle intelligents innovants.",
    EN: "The project purpose is implementing of results of individually carried out works B+R, which have leaded to elaboration of an innovative control system for agricultural machinery.",
    IT: "L'obiettivo del progetto è quello di implementare i risultati di un lavoro di ricerca e sviluppo condotto in modo indipendente, che culmini nello sviluppo di sistemi di controllo intelligenti innovativi.",
    DE: "Das Projektziel besteht darin, die Ergebnisse individuell durchgeführter Forschungs- und Entwicklungsarbeiten umzusetzen, die zur Ausarbeitung innovativer intelligenter Steuersysteme für landwirtschaftliche Maschinen geführt haben.",
  },
  {
    ID: 6,
    PL: "Planowane efekty:",
    FR: "Planification de projets :",
    EN: "Expected resultes:",
    IT: "Risultati previsti:",
    DE: "Geplante Effekte:",
  },
  {
    ID: 7,
    PL: "Rezultatem projektu będzie wprowadzenie na rynek innowacyjnych systemów inteligentnego sterowania. Wdrożeniu innowacji produktowej będzie towarzyszyło wprowadzenie innowacji organizacyjnej oraz wzrost stopnia internacjonalizacji przedsiębiorstwa. Produkt końcowy: Z dniem zakończeniem prac badawczo-rozwojowych został wprowadzony do oferty sprzedaży inteligentny system sterowania zabudową pojazdów specjalnego przeznaczenia.",
    FR: "Le résultat du projet sera l’introduction sur le marché de systèmes de contrôle intelligents innovants. La mise en œuvre de l'innovation produit s'accompagnera de l'introduction de l'innovation organisationnelle et d'une augmentation du degré d'internationalisation de l'entreprise. Produit final : À l'issue des travaux de recherche et de développement, un système de commande de carrosserie intelligent pour véhicules spéciaux a été introduit dans l'offre de vente.",
    EN: "The project result will be the introduction of innovative intelligent control systems to the market. The implementation of the product innovation will be accompanied by the introduction of an organisational innovation and an increase in the degree of internationalisation of the company. Final product: An intelligent control system for the bodywork of special-purpose vehicles has been introduced to the market on the day the research and development work was completed.",
    IT: "Il progetto porterà all'introduzione sul mercato di sistemi di controllo intelligenti innovativi. L'implementazione dell'innovazione di prodotto sarà accompagnata dall'introduzione di un'innovazione organizzativa e da un aumento del grado di internazionalizzazione dell'azienda. Prodotto finale: un sistema di controllo intelligente per la carrozzeria di veicoli speciali è stato introdotto sul mercato il giorno in cui è stato completato il lavoro di ricerca e sviluppo.",
    DE: "Wesentlicher Ergebnis des Projekts wird die Einführung innovativer intelligenter Steuerungssysteme auf dem Markt sein. Die Umsetzung der Produktinnovation wird von der Einführung einer organisatorischen Innovation begleitet sein sowie von einer Steigerung des Grades der Internationalisierung des Unternehmens. Endprodukt: Mit Abschluss der Forschungs- und Entwicklungsarbeiten wurde ein intelligentes Steuerungssystem für die Ausstattung von speziellen Fahrzeugen in das Verkaufsangebot aufgenommen.",
  },
  {
    ID: 8,
    PL: "Wartość projektu:",
    FR: "Valeur du projet :",
    EN: "Project value:",
    IT: "Valore del progetto:",
    DE: "Projektwert:",
  },
  {
    ID: 9,
    PL: "Wkład Europejskiego Funduszu Rozwoju Regionalnego:",
    FR: "Contribution du Fonds européen de développement régional :",
    EN: "Grant from European Regional Development Fund:",
    IT: "Contributo del Fondo europeo di sviluppo regionale:",
    DE: "Beitrag des Europäischen Fonds für regionale Entwicklung:",
  },
  {
    DE: "über uns",
    FR: "à propos de nous",
    EN: "about us",
    IT: "chi siamo",
  },
];
