import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import komax from "../../../assets/jpeg/production/machinePark/komax.jpg";
import tester from "../../../assets/jpeg/production/machinePark/tester.jpg";
import crimp2 from "../../../assets/jpeg/production/machinePark/crimp2.jpg";
import crimp4 from "../../../assets/jpeg/production/machinePark/crimp4.jpg";
import color from "../../../assets/styles/colors";
import { useParams } from "react-router-dom";
import translationsSwitch from "../../../translations";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "cover",
    width: "100%",
    // height: "450px",
    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  image2: {
    width: "100%",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
}));

const MachinePark = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("machinePark", lang);

  return (
    <React.Fragment>
      <PageWrapper
        banerImage={crimp4}
        title={Data[0].text}
        naviChild1={Data[1].text}
        naviChild2={Data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              {Data[2].text}
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography className={classes.text}>{Data[3].text}</Typography>
          </Grid>
          <Grid className={classes.grid} align="center" item xs={12} md={6}>
            <img className={classes.image} src={komax} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography variant="h4" className={classes.subtitle}>
              {Data[4].text}
            </Typography>

            <ul>
              <li>{Data[5].text}</li>
            </ul>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography variant="h4" className={classes.subtitle}>
              {Data[6].text}
            </Typography>

            {Data[7].text.map(function (data) {
              return (
                <ul>
                  <li>{data}</li>
                </ul>
              );
            })}
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={tester} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={crimp2} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography className={classes.subtitle} variant="h4">
              {Data[8].text}
            </Typography>
            {Data[9].text.map(function (data) {
              return (
                <ul>
                  <li>{data}</li>
                </ul>
              );
            })}
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default MachinePark;
