import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Grid, Typography, Link } from "@material-ui/core";
import HomeIcon from "@mui/icons-material/Home";
import color from "../../../assets/styles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  homeIcon: {
    marginRight: "20px",
    display: "inline",
    color: color.white,
    verticalAlign: "middle",
    transitions: "1s",
    "&:hover": {
      cursor: "pointer",
      transitions: "1s",
      transform: "scale(1.1)",
    },
  },
  backSlesh: {
    display: "inline",
    marginLeft: "1%",
    marginRight: "1%",
  },
  home: {
    display: "inline",
    fontWeight: "bold",
    color: color.white,
    fontSize: "13px",
  },
  container: {
    backgroundColor: color.green3,
    height: "45px",
    padding: "10px",
    marginTop: "-6px",
  },
  NavLink: {
    textDecoration: "none",
    color: color.white,
    textTransform: "uppercase",
    fontSize: "16px",
  },
  childs: {
    display: "contents",
    fontWeight: "800",
    textTransform: "uppercase",
  },
  displayNone: {
    display: "none",
  },
}));

const Navigator = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Hidden only={["xs", "sm"]}>
        <Grid
          className={classes.container}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={10} lg={10} xl={8}>
            <Link aria-label="home" className={classes.NavLink} href="/">
              <HomeIcon className={classes.homeIcon} />
            </Link>
            <Typography component="div" className={classes.home}>
              <Link className={classes.NavLink} href="/">
                HOME
              </Link>
              <Typography
                component="div"
                className={
                  props.child1 === undefined
                    ? classes.displayNone
                    : classes.backSlesh
                }
              >
                /
              </Typography>
              <Typography className={classes.childs}>{props.child1}</Typography>
              <Typography
                component="div"
                className={
                  props.child2 === undefined
                    ? classes.displayNone
                    : classes.backSlesh
                }
              >
                /
              </Typography>
              <Typography className={classes.childs}>{props.child2}</Typography>
              <Typography
                component="div"
                className={
                  props.child3 === undefined
                    ? classes.displayNone
                    : classes.backSlesh
                }
              >
                /
              </Typography>
              <Typography className={classes.childs}>{props.child3}</Typography>
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
};

export default Navigator;
