import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/aboutUs/image1.jpg";
import { Button, Grid, Typography } from "@material-ui/core";
import color from "../../../assets/styles/colors";
import Data from "./DataCareer";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "",
    width: "auto",
    height: "200px",
    objectPosition: "center center",
    borderRadius: "5px",
    margin: "20px",
  },
  text: {
    "&>b": {
      color: color.green3,
    },
    marginBottom: "50px",
  },
  titleContainer: {
    backgroundColor: color.green3,
    width: "100%",
    height: "160px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginBottom: "40px",
  },
  titleJob: {
    fontSize: "24px",
    color: color.white,
  },
  subtitle: {
    color: color.green2,
    fontSize: "22px",
    fontWeight: "600",
    marginTop: "20px",
    marginBottom: "20px",
  },
  buttonContainer: {
    padding: "20px",
  },
  button: {
    backgroundColor: color.green3,
    color: color.white,
    border: `solid 2px ${color.green3}`,
    transition: "1s",
    "&:hover": {
      color: color.green3,
      transition: "1s",
    },
  },
}));

// console.log(Data.jobs[1].JobList);

const JobData = () => {
  const classes = useStyles();
  const params = useParams();
  return (
    <>
      {Data.jobs[params.idJob - 1].JobList.map((item, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.subtitle}>
              {item.title}
            </Typography>
            <Typography>
              <ul>
                {item.list.map((item, index) => (
                  <React.Fragment key={index}>
                    <li>{item.name}</li>
                  </React.Fragment>
                ))}
              </ul>
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

const CareerJob = () => {
  const classes = useStyles();
  const params = useParams();

  // console.log(params.idJob - 1);
  console.log(params);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title="Dołącz do naszego zespołu!"
        naviChild1="Kariera"
        naviChild2={
          <a
            style={{ textDecoration: "none", color: color.white }}
            href={`/${params.lang}/career`}
          >
            OFERTY PRACY
          </a>
        }
        naviChild3={Data.jobs[params.idJob - 1].title}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Typography align="center" className={classes.text}>
              <b> ELTEM Sp. z o.o </b>jeden z czołowych w Polsce producentów
              okablowań elektrycznych do ciągników, maszyn rolniczych, pojazdów
              specjalistycznych, a także zestawów wskaźników i nietypowych
              rozwiązań z zakresu elektroniki, dla odbiorców z rynku rolniczego
              i motoryzacyjnego, działający na rynkach Europy, w związku z
              dalszym rozwojem Spółki, poszukuje osób na stanowiska:
            </Typography>
          </Grid>
          <Grid className={classes.titleContainer} align="center" item xs={12}>
            <Typography className={classes.titleJob}>
              <b style={{ textTransform: "uppercase" }}>
                {Data.jobs[params.idJob - 1].title}
              </b>
              <br /> Miejsce pracy: <b>Lublin</b> <br />
              Nr ref: <b>{Data.jobs[params.idJob - 1].nrRef}</b>
            </Typography>
          </Grid>
          <Grid style={{ padding: "20px" }} item xs={12}>
            <JobData classes={classes} data={Data} />

            <Typography style={{ padding: "10px" }} className={classes.text}>
              Osoby zainteresowane prosimy o przesyłanie CV na adres:{" "}
              <a
                style={{ color: color.green3 }}
                href="mailto:rekrutacja@eltem.pl"
              >
                <b>rekrutacja@eltem.pl</b>
              </a>{" "}
              Informujemy, że kontaktujemy się tylko z wybranymi Kandydatami.
            </Typography>
            <Typography
              className={classes.text}
              style={{ marginBottom: "40px" }}
            >
              <i>
                <b>Prosimy o dopisanie klauzuli poniżej:</b> „Wyrażam zgodę na
                przetwarzanie danych osobowych niezbędnych do realizacji procesu
                rekrutacji zgodnie z: Rozporządzeniem Parlamentu Europejskiego i
                Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony
                osób fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
              </i>
            </Typography>
          </Grid>
          <Grid className={classes.buttonContainer} xs={12} align="center" item>
            <Link to={`/${params.lang}/career/ `}>
              <Button className={classes.button}>
                Przejrzyj inne Oferty Pracy
              </Button>
            </Link>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default CareerJob;
