import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Box, Grid, Typography } from "@material-ui/core";
import wire from "../../../assets/jpeg/production/machinePark/komax.jpg";
import color from "../../../assets/styles/colors";
import Papa from "papaparse";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TableSortLabel,
} from "@material-ui/core";

import csvData from "../../../assets/data/resale/data.csv";
import translationsSwitch from "../../../translations";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 650,
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2",
    },
    "& td": {
      border: "1px solid black",
    },
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: color.green3,
    "& th": {
      color: "white",
      border: "1px solid black",
      fontWeight: "bold",
    },
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  image2: {
    width: "100%",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
  link: {
    color: color.green3,
  },
  TableCSV: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
    fontFamily: "Arial, sans-serif",
    "&>thead": {
      backgroundColor: "#f2f2f2",
      color: "#333",
      textAlign: "left",
      padding: "10px",
      borderBottom: "1px solid #ccc",
    },
    "&>td": {
      padding: "10px",
      borderBottom: "1px solid #ccc",
    },
  },
  container: {
    marginBottom: "25vh",
  },
}));

function CsvTable({ csvPath, translateData }) {
  const classes = useStyles();
  const [csvData, setCsvData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    // Wczytaj zawartość pliku CSV przy użyciu fetch
    fetch(csvPath)
      .then((response) => response.text())
      .then((data) => {
        // Ustaw zawartość CSV w stanie komponentu
        setCsvData(data);
        // Przy pierwszym załadowaniu, wyświetl wszystkie dane
        setFilteredRows(convertCSVToData(data));
      })
      .catch((error) => console.error("Błąd wczytywania pliku CSV:", error));
  }, [csvPath]);

  // Funkcja do konwersji danych CSV na tablicę
  function convertCSVToData(csvData) {
    const parsedData = Papa.parse(csvData, {
      header: true, // Pierwszy wiersz to nagłówki kolumn
      encoding: "ISO-8859-1", // Kodowanie ANSI (np. ISO-8859-1)
    });

    return parsedData.data;
  }

  const columns = Object.keys(convertCSVToData(csvData)[0] || {}).map(
    (columnName) => ({
      field: columnName,
      headerName: columnName,
    })
  );

  const handleSearch = () => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filteredData = convertCSVToData(csvData).filter((row) =>
      Object.values(row).some(
        (value) => value && value.toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
    setFilteredRows(filteredData);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // Jeśli kolumna jest już wybrana, zmień kierunek sortowania
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Jeśli wybierasz nową kolumnę, ustaw ją jako kolumnę sortowania
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Funkcja sortująca dane na podstawie wybranej kolumny i kierunku sortowania
  const sortedRows = [...filteredRows].sort((a, b) => {
    const columnA = a[sortColumn];
    const columnB = b[sortColumn];

    if (columnA && columnB) {
      if (sortDirection === "asc") {
        return columnA.localeCompare(columnB, "pl", { sensitivity: "base" });
      } else {
        return columnB.localeCompare(columnA, "pl", { sensitivity: "base" });
      }
    }

    return 0; // Jeśli jedna z wartości jest undefined, nie wykonujemy sortowania
  });

  return (
    <div>
      <TextField
        label={translateData[5].text}
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyUp={handleSearch}
        className={classes.searchInput}
      />
      <Typography variant="subtitle1" className={classes.rowCount}>
        {translateData[3].text} <b>{sortedRows.length}</b>{" "}
        {translateData[4].text}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>
                  <TableSortLabel
                    active={sortColumn === column.field}
                    direction={sortDirection}
                    onClick={() => handleSort(column.field)}
                  >
                    {column.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.field}>{row[column.field]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const ComponentsForSale = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("forSale", lang);

  return (
    <React.Fragment>
      <PageWrapper
        banerImage={wire}
        title={Data[0].text}
        naviChild1={Data[2].text}
        naviChild2={Data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            {/* <Typography align="center" className={classes.text}>
              Poniżej znajduje się lista materiałów, które mamy na odsprzedaż.
              Prosimy o kontakt z Działem Zakupów (tel.
              <a className={classes.link} href="tel:+48815612238">
                81 561-22-38
              </a>
              , e-mail{" "}
              <a className={classes.link} href="mailto:jacek.zglinski@eltem.pl">
                jacek.zglinski@eltem.pl
              </a>
              ) w celu sprawdzenia aktualnej dostępności materiałów oraz
              uzyskania innych interesujących Państwa informacji.
            </Typography> */}
            {Data[1].text}
          </Grid>
          <Grid align="center" item xs={12}>
            <Box>
              {" "}
              <CsvTable
                translateData={Data}
                csvPath={csvData}
                classes={classes}
              />
            </Box>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default ComponentsForSale;
