import React from "react";
import Header from "../components/elements/Header";
import Footer from "../components/elements/Footer";
import { Grid, Typography } from "@material-ui/core";
import Baner from "../components/elements/dynamic/Baner";
import Navigator from "../components/elements/dynamic/Navigator";
import { makeStyles } from "@material-ui/core/styles";
import color from "../assets/styles/colors";
import { useParams } from "react-router-dom";
import translationsSwitch from "../translations";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontWeight: "400!important",
    fontSize: "40px !important",
    fontFamily: `'Rajdhani', sans-serif !important`,
    marginTop: "40px !important",
    marginBottom: "40px !important",
    color: color.green3,
    padding: "10px",
    textAlign: "center",
  },
}));

const PageWrapper = (props) => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" id="container">
        <Grid container direction="column" justifyContent="space-between">
          <Grid id="Header" item>
            <Header
              dropMenuTranslation={translationsSwitch("dropMenu", lang)}
              menuTranslation={translationsSwitch("menu", lang)}
            />
          </Grid>
          <Grid
            style={
              props.notDisplayBaner ? { display: "none" } : { display: "block" }
            }
            item
          >
            <Baner image={props.banerImage} />
          </Grid>
          <Navigator
            child1={props.naviChild1}
            child2={props.naviChild2}
            child3={props.naviChild3}
          />

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            id="container"
          >
            <Grid xs={12} sm={11} md={10} lg={9} xl={8} item>
              <Typography className={classes.title}>{props.title}</Typography>
              {props.children}
            </Grid>
          </Grid>
          <Grid
            style={{
              position: "relative",
              bottom: "0 !important",
              width: "100%",
            }}
            id="Footer"
            item
          >
            <Footer dataFooter={translationsSwitch("footer", lang)} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PageWrapper;
