import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import wire from "../../../assets/png/wires.png";
import control from "../../../assets/jpeg/production/machinePark/oplatarka.jpg";

import color from "../../../assets/styles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  image2: {
    width: "100%",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
}));

const AdditionalServices = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={wire}
        title="Usługi dodatkowe"
        naviChild1="Oferta"
        naviChild2="Usługi dodatkowe"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              Z uwagi na niezliczone pomysły i potrzeby naszych klientów
              prowadzimy różnorodne usług dodatkowe związane z naszą główną
              gałęzią produkcji. Poniżej kilka podstawowych przykładów:
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography variant="h4" className={classes.subtitle}>
              Przeróbki i modyfikacje wiązek
            </Typography>
            <ul>
              <li>
                przerabianie istniejących wiązek elektrycznych do innych wersji
                wg dokumentacji klienta (np. wprowadzanie modyfikacji związanych
                ze zmianą odbiornika - złącza, lub zmiany wymiarów wiązki)
              </li>
              <li>
                wykonywanie wiązek przejściowych pomiędzy rożnymi typami złącz
              </li>
            </ul>
            <Typography>
              <b>UWAGA</b>- nie wykonujemy jednostkowych napraw (lub częściowej
              odbudowy) instalacji elektrycznej z prywatnych samochodów
              osobowych lub dostawczych.
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={6}>
            <Typography variant="h4" className={classes.subtitle}>
              Tablice rozdzielcze i sterownicze z okablowaniem
            </Typography>
            <ul>
              <li>
                Wykonujemy szafy sterownicze i elektryczne wg wymiarów i
                specyfiki klienta. Szafy te mogą być wykonywane jako oddzielne
                elementy, lub układy powiązane z okablowaniem elektrycznym np.
                pojazdu specjalnego.
              </li>
            </ul>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={control} alt="" />
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default AdditionalServices;
