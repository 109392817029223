export const translations = [
  {
    ID: 1,
    PL: "Certyfikaty",
    DE: "Zertifikate",
    FR: "Certificats",
    EN: "Certifications",
    IT: "Certificati",
  },
  {
    ID: 2,
    PL: "Jesteśmy dumnymi posiadaczami certyfikatów ISO 9001:2015 i ISO 14001:2015, które potwierdzają nasze zaangażowanie w wysoką jakość produkcyjną oraz ochronę środowiska.",
    DE: "Wir sind stolz auf die Zertifizierungen ISO 9001:2015 und ISO 14001:2015, die unser Engagement für hohe Produktionsqualität und Umweltschutz bestätigen.",
    FR: "Nous sommes fiers de posséder les certifications ISO 9001:2015 et ISO 14001:2015, qui confirment notre engagement en faveur d'une qualité de production élevée et de la protection de l'environnement.",
    EN: "We are proud to be certified with ISO 9001:2015 and ISO 14001:2015, confirming our commitment to high production quality and environmental protection. ",
    IT: "Siamo orgogliosi di possedere le certificazioni ISO 9001:2015 e ISO 14001:2015, che confermano il nostro impegno per l'alta qualità della produzione e la tutela dell'ambiente.",
  },
  {
    ID: 3,
    PL: "Linki do pobrania:",
    DE: "Download-Links",
    FR: "Liens de téléchargement :",
    EN: "Download:",
    IT: "Link per il download:",
  },
  {
    ID: 4,
    PL: "(Otwierają się na nowej zakładce.)",
    DE: "(Sie werden in einem neuen Tab geöffnet).",
    FR: "s'ouvrent dans un nouvel onglet",
    EN: "(Open in a new tab.)",
    IT: "(Si aprono in una nuova scheda).",
  },
  {
    ID: 5,
    PL: "ISO 9001:2015",
    DE: "ISO 9001:2015",
    FR: "ISO 9001:2015",
    EN: "ISO 9001:2015",
    IT: "ISO 9001:2015",
  },
  {
    ID: 6,
    PL: "ISO 14001:2015",
    DE: "ISO 14001:2015",
    FR: "La norme ISO 14001:2015",
    EN: "ISO 14001:2015",
    IT: "ISO 14001:2015",
  },
  {
    ID: 7,
    PL: " to międzynarodowy standard dla systemów zarządzania jakością, który gwarantuje, że nasze procesy produkcyjne są skuteczne, efektywne i zgodne z najwyższymi standardami. Stosując się do wymagań tego standardu, stale doskonalimy nasze procedury, kontrolujemy jakość naszych produktów na każdym etapie produkcji oraz dbamy o satysfakcję naszych klientów",
    DE: " ist die internationale Norm für Qualitätsmanagementsysteme, die gewährleistet, dass unsere Produktionsprozesse effektiv und effizient sind und den höchsten Standards entsprechen. Gemäß den Anforderungen dieser Norm verbessern wir kontinuierlich unsere Verfahren, kontrollieren die Qualität unserer Produkte in jeder Phase der Produktion und stellen die Zufriedenheit unserer Kunden sicher",
    FR: " est la norme internationale pour les systèmes de gestion de la qualité, qui garantit que nos processus de production sont efficaces, efficients et conformes aux normes les plus élevées. En suivant les exigences de cette norme, nous améliorons continuellement nos procédures, contrôlons la qualité de nos produits à chaque étape de la production et assurons la satisfaction de nos clients",
    EN: " is the international standard for quality management systems, which ensures that our production processes are effective, efficient and to the highest standards. Following the requirements of this standard, we continuously improve our procedures, control the quality of our products at every stage of production and ensure the satisfaction of our customers.",
    IT: " è lo standard internazionale per i sistemi di gestione della qualità, che garantisce che i nostri processi produttivi siano efficaci, efficienti e conformi agli standard più elevati. Seguendo i requisiti di questo standard, miglioriamo continuamente le nostre procedure, controlliamo la qualità dei nostri prodotti in ogni fase della produzione e garantiamo la soddisfazione dei nostri clienti.",
  },
  {
    ID: 8,
    PL: " jest standardem dla systemów zarządzania środowiskowego. Dążymy do minimalizacji naszego wpływu na środowisko naturalne poprzez odpowiedzialne i zrównoważone podejście do produkcji. Przestrzegając wymogów ISO 14001:2015, identyfikujemy, monitorujemy i kontrolujemy nasze aspekty środowiskowe, podejmujemy działania mające na celu minimalizację emisji, redukcję zużycia energii i surowców oraz optymalizację zarządzania odpadami.",
    DE: " ist die Norm für Umweltmanagementsysteme. Unser Ziel ist es, unsere Auswirkungen auf die Umwelt durch einen verantwortungsvollen und nachhaltigen Ansatz in der Produktion zu minimieren. Durch die Einhaltung der Anforderungen von ISO 14001:2015 ermitteln, überwachen und kontrollieren wir unsere Umweltaspekte und ergreifen Maßnahmen zur Minimierung von Emissionen, zur Verringerung des Energie- und Rohstoffverbrauchs und zur Optimierung der Abfallwirtschaft.",
    FR: " s'applique aux systèmes de gestion de l'environnement. Notre objectif est de minimiser notre impact sur l'environnement grâce à une approche responsable et durable de la production. En adhérant aux exigences de la norme ISO 14001:2015, nous identifions, surveillons et contrôlons nos aspects environnementaux et prenons des mesures pour minimiser les émissions, réduire la consommation d'énergie et de matières premières et optimiser la gestion des déchets.",
    EN: " is the standard for environmental management systems. We are committed to minimising our impact on the environment through a responsible and sustainable production model. By following the requirements of ISO 14001:2015, we identify, monitor and control our environmental aspects, take measures to minimise emissions, reduce energy and raw material consumption and optimise waste management.",
    IT: " è lo standard per i sistemi di gestione ambientale. Il nostro obiettivo è ridurre al minimo l'impatto sull'ambiente attraverso un approccio responsabile e sostenibile alla produzione. Aderendo ai requisiti della norma ISO 14001:2015, identifichiamo, monitoriamo e controlliamo i nostri aspetti ambientali e adottiamo misure per minimizzare le emissioni, ridurre il consumo di energia e di materie prime e ottimizzare la gestione dei rifiuti.",
  },
  {
    ID: 9,
    PL: "Dzięki posiadaniu tych certyfikatów możemy zapewnić naszym klientom, że nasze produkty są wytwarzane zgodnie z najwyższymi standardami jakościowymi i ekologicznymi. Nasze zaangażowanie w ciągłe doskonalenie oraz dbałość o środowisko to fundamenty naszej działalności produkcyjnej.",
    DE: "Durch diese Zertifizierungen können wir unseren Kunden versichern, dass unsere Produkte nach den höchsten Qualitäts- und Umweltstandards hergestellt werden. Unser Engagement für die kontinuierliche Verbesserung und die Sorge um die Umwelt sind die Eckpfeiler unserer Produktionsabläufe.",
    FR: "Grâce à ces certifications, nous pouvons garantir à nos clients que nos produits sont fabriqués dans le respect des normes de qualité et d'environnement les plus strictes. Notre engagement en faveur de l'amélioration continue et le respect de l'environnement sont les pierres angulaires de nos opérations de fabrication.",
    EN: "With these certifications, we can guarantee our customers that our products are manufactured to the highest quality and environmental standards. Our commitment to continuous improvement and care for the environment are the pillars of our manufacturing operations.",
    IT: "Grazie a queste certificazioni, possiamo garantire ai nostri clienti che i nostri prodotti sono realizzati secondo i più alti standard qualitativi e ambientali. Il nostro impegno per il miglioramento continuo e l'attenzione per l'ambiente sono le pietre miliari delle nostre attività produttive.",
  },
  {
    PL: "o firmie",
    DE: "über uns",
    FR: "à propos de nous",
    EN: "about us",
    IT: "chi siamo",
  },
];
