export const translations = [
  {
    PL: "Zestaw wskaźników",
    DE: "Anzeigegerät",
    FR: "Combiné d’instruments",
    EN: "Instrument clusters",
    IT: "Quadri strumenti",
  },
  {
    PL: "Od ponad 10 lat projektujemy i produkujemy zestawy wskaźników do pojazdów, ciągników i maszyn budowlanych.",
    DE: "Seit mehr als 10 Jahren entwickeln und produzieren wir Anzeigegeräte für Fahrzeuge, Traktoren und Baumaschinen.",
    FR: "Depuis plus de 10 ans, nous concevons et fabriquons des combinés d'indicateurs pour véhicules, tracteurs et engins de chantier.",
    EN: "We have been designing and manufacturing instrument clusters for vehicles, tractors and construction machinery for more than 10 years.",
    IT: "Da oltre 10 anni progettiamo e produciamo kit di indicatori per veicoli, trattori e macchine edili.",
  },
  {
    PL: [
      "Wskazówki w naszych wskaźnikach są napędzane przez niezawodne silniki krokowe w wykonaniu odpornym na wibracje i ciężkie warunki pracy.",
      "Do wyświetlania informacji stosujemy całą gamę wyświetlaczy wykonanych w technologii LCD, TFT oraz OLED.",
      "Zestawy wykonujemy w obudowach naszego projektu lub dostarczanych przez klienta",
      "Grafika jest zawsze projektowana z uwzględnieniem wymagań klienta.",
    ],
    DE: [
      "Die Zeiger unserer Anzeiger werden von zuverlässigen Schrittmotoren in vibrationsfester und hochbelastbarer Ausführung angetrieben.",
      "Wir verwenden eine Reihe von LCD-, TFT- und OLED-Displays zur Anzeige von Informationen.",
      "Die Sets werden in selbst entworfenen oder vom Kunden gelieferten Gehäusen hergestellt.",
      "Bei der Gestaltung der Grafiken werden stets die Anforderungen des Kunden berücksichtigt.",
    ],
    FR: [
      "Les aiguilles de nos indicateurs sont entraînées par des moteurs pas à pas fiables, résistants aux vibrations et à l'usure.",
      "Nous utilisons une gamme d'écrans LCD, TFT et OLED pour afficher les informations.",
      "Les sets sont fabriqués dans des conteneurs de notre propre conception ou fournis par le client.",
      "Les graphiques sont toujours conçus en tenant compte des exigences du client.",
    ],
    EN: [
      "The pointers in our indicators are driven by reliable stepper motors that are resistant to vibration and hard working conditions.",
      "For the display of information, we use a range of screens manufactured in LCD, TFT and OLED technology.",
      "Instrument clusters are made in housings of our own design or supplied by the customer",
      "Design is always developed according to the customer's requirements.",
    ],
    IT: [
      "I nostri aghi indicatori sono azionati da motori passo-passo affidabili e resistenti alle vibrazioni e all'usura.",
      "Per visualizzare le informazioni utilizziamo una serie di display LCD, TFT e OLED.",
      "I kit sono prodotti in clusters di nostra progettazione o forniti dal cliente.",
      "La grafica viene sempre progettata tenendo conto delle esigenze del cliente.",
    ],
  },
  {
    PL: "Oferta",
    DE: "Angebot",
    FR: "Offre",
    EN: "Offer",
    IT: "Offerta",
  },
];
