import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import banerBg from "../../../assets/jpeg/banerCarousel/16_9/2.jpg";

import image1 from "../../../assets/png/electronic/device1.png";
import image2 from "../../../assets/png/electronic/device2.png";
import image3 from "../../../assets/png/electronic/pilot.png";
import image4 from "../../../assets/png/electronic/mta.png";

import color from "../../../assets/styles/colors";
import translationsSwitch from "../../../translations";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    // objectFit: "cover",
    width: "100%",
    // height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  image2: {
    width: "100%",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
}));

const CustomerElectronics = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("electronic", lang);

  // console.log(Data);

  return (
    <React.Fragment>
      <PageWrapper
        banerImage={banerBg}
        title={Data[0].text}
        naviChild1={Data[9].text}
        naviChild2={Data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              {Data[1].text}
            </Typography>
          </Grid>
          <Grid style={{ backgroundColor: "rgb(248, 248, 249)" }} container>
            <Grid className={classes.grid} item xs={12} md={8} lg={9}>
              <Typography variant="h4" className={classes.subtitle}>
                {Data[2].text}
              </Typography>
              {Data[3].text.map(function (data) {
                return (
                  <ul>
                    <li>{data}</li>
                  </ul>
                );
              })}
            </Grid>
            <Grid className={classes.grid} item xs={12} md={4} lg={3}>
              <img className={classes.image} src={image1} alt="" />
            </Grid>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={4} lg={3}>
            <img className={classes.image} src={image2} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={8} lg={9}>
            <Typography variant="h4" className={classes.subtitle}>
              {Data[4].text}
            </Typography>

            {Data[5].text.map(function (data) {
              return (
                <ul>
                  <li>{data}</li>
                </ul>
              );
            })}
          </Grid>
          <Grid style={{ backgroundColor: "rgb(248, 248, 249)" }} container>
            <Grid className={classes.grid} item xs={12} md={8} lg={9}>
              <Typography className={classes.subtitle} variant="h4">
                {Data[6].text}
              </Typography>
              {Data[7].text}
              {Data[8].text.map(function (data) {
                return (
                  <ul>
                    <li>{data}</li>
                  </ul>
                );
              })}
            </Grid>
            <Grid className={classes.grid} item xs={12} md={4} lg={3}>
              <img className={classes.image} src={image3} alt="" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid className={classes.grid} item xs={12} md={12} lg={7} xl={8}>
              <img className={classes.image} src={image4} alt="" />
            </Grid>
            <Grid className={classes.grid} item xs={12} md={12} lg={5} xl={4}>
              <Typography className={classes.subtitle} variant="h4">
                {Data[10].text}
              </Typography>
              {Data[11].text.map(function (data) {
                return (
                  <ul>
                    <li>{data}</li>
                  </ul>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default CustomerElectronics;
