export const translations = [
  {
    PL: "Produkcja",
    DE: "Produktion",
    FR: "Production",
    EN: "Production",
    IT: "Produzione",
  },
  {
    PL: "Zastosowanie wiązek elektrycznych w branżach",
    DE: "Die Verwendung von elektrischen Kabelbäumen in der Industrie",
    FR: "L'utilisation des faisceaux électriques dans les industries",
    EN: "Application of electrical harnesses in industries",
    IT: "L'uso dei cablaggi elettrici nell'industria",
  },
  {
    PL: "Wiązki elektryczne rewolucjonizują branże motoryzacyjną, zarówno w przypadku samochodów, jak i pojazdów rolniczych. Dzięki ich wykorzystaniu możliwe stało się wprowadzenie nowoczesnych rozwiązań, które poprawiają zarówno wydajność, jak i bezpieczeństwo tych pojazdów. Oto kilka obszarów, w których wiązki elektryczne mają kluczowe znaczenie.",
    DE: "Elektrische Kabelbäume revolutionieren die Automobilindustrie, sowohl für Pkw als auch für landwirtschaftliche Fahrzeuge. Ihr Einsatz hat es ermöglicht, moderne Lösungen einzuführen, die sowohl die Leistung als auch die Sicherheit dieser Fahrzeuge verbessern. Hier sind einige Bereiche, in denen elektrische Kabelbäume von entscheidender Bedeutung sind.",
    FR: "Les faisceaux électriques révolutionnent l'industrie automobile, tant pour les voitures que pour les véhicules agricoles. Leur utilisation a permis d'introduire des solutions modernes qui améliorent à la fois les performances et la sécurité de ces véhicules. Voici quelques domaines dans lesquels les faisceaux électriques sont essentiels.",
    EN: "Electrical harnesses are revolutionising the automotive industry, both for cars and agricultural vehicles. Thanks to their application, it has become possible to introduce modern solutions that improve both the performance and safety of these vehicles. Here are some areas where electrical harnesses are crucial.",
    IT: "I cablaggi elettrici stanno rivoluzionando l'industria automobilistica, sia per le auto che per i veicoli agricoli. Il loro utilizzo ha permesso di introdurre soluzioni moderne che migliorano le prestazioni e la sicurezza di questi veicoli. Ecco alcuni settori in cui i cablaggi elettrici sono fondamentali.",
  },
];

export const vehicles = [
  {
    PL: "koparki",
    DE: "bagger",
    FR: "pelleteuse",
    EN: "excavators",
    IT: "escavatori",
  },
  {
    PL: "pojazdy elektryczne",
    DE: "elektrofahrzeuge",
    FR: "véhicules électriques",
    EN: "electric vehicles",
    IT: "veicoli elettrici",
  },
  {
    PL: "samochody osobowe",
    DE: "personenwagen",
    FR: "voitures particulières",
    EN: "passenger cars",
    IT: "auto private",
  },

  {
    PL: "jachty",
    DE: "yachten",
    FR: "yachts",
    EN: "yachts",
    IT: "imbarcazioni da diporto",
  },

  {
    PL: "karetki",
    DE: "krankentransporter",
    FR: "ambulances",
    EN: "ambulances",
    IT: "ambulanze",
  },
  {
    PL: "traktory",
    DE: "traktoren",
    FR: "tracteurs",
    EN: "tractors",
    IT: "trattori",
  },

  {
    PL: "naczepy",
    DE: "anhänger",
    FR: "remorques",
    EN: "trailers",
    IT: "rimorchi",
  },

  {
    PL: "maszyny rolnicze",
    DE: "landmaschinen",
    FR: "machines agricoles",
    EN: "agricultural machinery",
    IT: "macchine agricole",
  },
];

export const Examples = [
  {
    PL: "Przykładowe zastosowania:",
    DE: "Anwendungsbeispiele:",
    FR: "Exemples d'applications :",
    EN: "Examples of applications:",
    IT: "Esempi di applicazione:",
  },
];
