export const translations = [
  /// What we do.
  {
    ID: 1,
    PL: "CZYM SIĘ ZAJMUJEMY?",
    DE: "Über uns",
    FR: "Qui sommes-nous ? ",
    EN: "ABOUT US",
    IT: "Chi siamo?",
  },
  {
    ID: 2,
    PL: "Jesteśmy wiodącym producentem wysokiej jakości wiązek elektrycznych oraz elektroniki od 1990 roku.",
    DE: "Wir sind seit 1990 ein führender Hersteller von hochwertigen Kabelbäumen und Elektronik.",
    FR: "Nous sommes l'un des principaux fabricants de faisceaux électriques et électroniques de haute qualité depuis 1990.",
    EN: "We are a leading manufacturer of high quality wiring harnesses and electronics since 1990.",
    IT: "Siamo un produttore leader di cablaggi elettrici ed elettronica di alta qualità dal 1990.",
  },
  {
    ID: 3,
    PL: "Wiązki przewodów elektrycznych",
    DE: "Elektrische Kabelbäume",
    FR: "Faisceaux de câbles électriques",
    EN: "Wire harnesses",
    IT: "Cablaggi elettrici",
  },
  {
    ID: 4,
    PL: "Oferujemy kompleksowe usługi z zakresu projektowania i wykonawstwa okablowania dla pojazdów i maszyn o różnym stopniu skomplikowania i rozmaitych wymaganiach. Bez względu na to, czy potrzebujesz projektu od podstaw, czy też możemy oprzeć się na dostarczonych przez Ciebie wytycznych - chętnie podejmiemy się tego zadania (bez konieczności posiadania specjalistycznej dokumentacji).",
    DE: "Wir bieten einen umfassenden Service für die Konstruktion und Herstellung von Verkabelungen für Fahrzeuge und Maschinen mit unterschiedlichem Komplexitätsgrad und vielfältigen Anforderungen an. Egal, ob Sie einen Entwurf von Grund auf benötigen oder wir auf den von Ihnen bereitgestellten Richtlinien aufbauen können - wir übernehmen die Aufgabe gerne (ohne die Notwendigkeit einer speziellen Dokumentation).",
    FR: "Nous proposons des services complets dans le domaine de la conception et de la fabrication de faisceau pour véhicules et machines de différents degrés de complexité et d'exigences diverses. Que vous ayez besoin d'une conception à partir de zéro ou que nous puissions nous fier aux directives que vous nous fournissez, nous serons heureux d'entreprendre cette tâche (sans avoir besoin de documentation spécialisée).",
    EN: "We offer a comprehensive service for the design and manufacture of wiring for vehicles and machinery with various levels of complexity and variety of requirements. No matter whether you need a design from scratch or whether you provide a specification, we are glad to take on this task (without needing any specialist documentation).",
    IT: "Offriamo un servizio completo di progettazione e  produzione di cablaggi per veicoli e macchinari con vari gradi di complessità e una varietà di requisiti. Sia che abbiate bisogno di un progetto a partire da zero, sia che possiate basarvi sulle linee guida da voi fornite, saremo lieti di svolgere il compito (senza bisogno di documentazione specialistica).",
  },
  {
    ID: 5,
    PL: "Elektronika na potrzeby klienta",
    DE: "Kundenspezifische Elektronik",
    FR: "Electronique à la demande du client",
    EN: "Customised electronics",
    IT: "Elettronica su richiesta del Cliente",
  },
  {
    ID: 6,
    PL: "Nasza oferta obejmuje także projektowanie i wykonawstwo elektroniki, dostosowanej do Twoich indywidualnych wymagań. Specjalizujemy się w zestawach wskaźników dla maszyn i urządzeń, a także w tworzeniu pulpitów sterujących i zarządzających. Ponadto, oferujemy szerokie doradztwo w zakresie projektowania, doboru komponentów, technologii i innych aspektów.",
    DE: "Unser Angebot umfasst auch die Entwicklung und Herstellung von Elektronik, die auf Ihre individuellen Anforderungen zugeschnitten ist. Wir sind spezialisiert auf Anzeigesysteme für Maschinen und Anlagen sowie auf die Erstellung von Kontroll- und Management-Dashboards. Darüber hinaus bieten wir eine umfassende Beratung zu Design, Komponentenauswahl, Technologie und anderen Aspekten.",
    FR: "Notre offre comprend également la conception et la construction d’appareils électroniques adaptés à vos besoins individuels. Nous sommes spécialisés dans les ensembles d'indicateurs pour machines et appareils, ainsi que dans la création de pupitres de contrôle et de gestion. De plus, nous proposons des conseils approfondis dans le domaine de la conception, de la sélection des composants, de la technologie et d'autres aspects.",
    EN: "Our offer also includes the design and manufacture of electronics, adapted to your individual requirements. We are specialised in instrument clusters for machinery and devices as well as in the production of control and management deskops. In addition, we offer extensive advice on design, component selection, technology and other aspects.",
    IT: "La nostra offerta comprende anche la progettazione e la costruzione di dispositivi elettronici su misura per le vostre esigenze individuali. Siamo specializzati in gruppi di indicatori per macchine e dispositivi, nonché nella creazione di pannelli di controllo e gestione. Offriamo inoltre una consulenza approfondita sulla progettazione, la selezione dei componenti, la tecnologia e altri aspetti.",
  },
  {
    ID: 7,
    PL: "NASZA OFERTA:",
    DE: "UNSER ANGEBOT:",
    FR: "NOTRE OFFRE:",
    EN: "OUR OFFER:",
    IT: "NOSTRA OFFERTA:",
  },
  {
    ID: 8,
    PL: "Wiązki przewodów elektrycznych",
    DE: "Elektrische Kabelbäume",
    FR: "Faisceaux de câbles électriques",
    EN: "Wire harnesses",
    IT: "Cablaggi elettrici",
  },
  {
    ID: 9,
    PL: "Zestawy wskaźników",
    DE: "Anzeigesysteme",
    FR: "l’ensemble des indicateurs",
    EN: "Instrument clusters",
    IT: "Quadri strumenti",
  },
  {
    ID: 10,
    PL: "Elektronika na potrzeby klienta",
    DE: "Kundenspezifische Elektronik",
    FR: "Electronique à la demande du client",
    EN: "Customised electronics",
    IT: "Elettronica su richiesta del Cliente",
  },
  {
    ID: 11,
    PL: "Przewody masowe i zasilające",
    DE: "Masse- und Stromkabel",
    FR: "Câbles de masse et d'alimentation",
    EN: "Mass cables and power cables",
    IT: "Cavi di massa e cavi di alimentazione",
  },
  {
    ID: 12,
    PL: "Dlaczego warto wybrać naszą firmę?",
    DE: "Warum sollten Sie unser Unternehmen wählen?",
    FR: "Pourquoi vaut-il la peine de choisir notre entreprise ?",
    EN: "Why is it worth choosing us?",
    IT: "Perché scegliere la nostra azienda?",
  },
  {
    ID: 13,
    PL: "Niech mówią liczby!",
    DE: "Lassen Sie die Zahlen sprechen!",
    FR: "Laissez parler les chiffres !",
    EN: "Let the numbers speak!",
    IT: "Lasciate parlare i numeri!",
  },
  {
    ID: 14,
    PL: "Przy wystarczającej ilości danych liczby mówią same za siebie.",
    DE: "Bei ausreichenden Daten sprechen die Zahlen für sich selbst.",
    FR: "Avec assez de données, les chiffres parlent d'eux-mêmes.",
    EN: "With sufficient data, the figures speak for themselves.",
    IT: "Con dati sono sufficienti, le cifre parlano da sole.",
  },
  {
    ID: 15,
    PL: (
      <>
        Lat
        <br />
        na rynku
      </>
    ),
    DE: "Jahre auf dem Markt",
    FR: "La présence d'Eltem sur le marché",
    EN: "Years on the market",
    IT: "Anni di presenza sul mercato",
  },
  {
    ID: 16,
    PL: "Zatrudnionych pracowników",
    DE: "Angestellte Mitarbeiter",
    FR: "Nombre d'employés",
    EN: "Number of employees",
    IT: "Numero di dipendenti",
  },
  {
    ID: 17,
    PL: "Wyprodukowanych wiązek",
    DE: "Produzierte Kabelbäume",
    FR: "Nombre de faisceaux produits",
    EN: "Number of harnesses produced",
    IT: "Numero di cablaggi prodotti",
  },
  {
    ID: 18,
    PL: "Kraje, w których mamy klientów",
    DE: "Länder, in denen wir Kunden haben",
    FR: "Pays où nous avons des clients",
    EN: "Countries where we have customers",
    IT: "Paesi in cui abbiamo clienti",
  },
  {
    ID: 2,
    PL: "Nasza firma z pasją projektuje i wytwarza rozwiązania, które spełniają najwyższe standardy jakości i wydajności.",
    DE: "Unser Unternehmen entwickelt und fertigt mit Leidenschaft Lösungen, die den höchsten Qualitäts- und Leistungsstandards entsprechen.",
    FR: "Notre entreprise conçoit et produit avec passion des solutions répondant aux plus hauts standards de qualité et de performance.",
    EN: "Our company is dedicated to design and manufacture solutions that meet the highest standards of quality and performance.",
    IT: "La nostra azienda è appassionata di progettazione e produzione di soluzioni che soddisfano i più elevati standard di qualità e prestazioni.",
  },
];

export default translations;
