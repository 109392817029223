const menuItems = {
  aboutCompany: [
    {
      id: 1,
      name: {
        PL: "Dofinansowanie ze środków Unii Europejskiej",
        DE: "Fördermittel aus EU-Mitteln",
        FR: "Financement de l'Union européenne",
        EN: "EU Funds Support",
        IT: "Finanziamenti dall'Unione Europea",
      },
      onlyPL: true,
      url: "/subsidy-from-the-European-Union",
    },
    {
      id: 2,
      name: {
        PL: "Historia",
        DE: "Geschichte",
        FR: "Histoire",
        EN: "History",
        IT: "Storia",
      },
      onlyPL: false,
      url: "/our-history",
    },
    {
      id: 3,
      name: {
        PL: "Partnerzy",
        DE: "Partner",
        FR: "Partenaires",
        EN: "Partners",
        IT: "Partner",
      },
      onlyPL: false,

      mainLink: true,
      url: "",
      submenu: "MTA.IT",
      submenuUrl: "https://mta.it/",
    },
    {
      id: 4,
      name: {
        PL: "Certyfikaty",
        DE: "Zertifikate",
        FR: "Certifications",
        EN: "Certificates",
        IT: "Certificazioni",
      },
      onlyPL: false,

      url: "/certificates",
    },
  ],

  offer: [
    {
      id: 6,
      name: {
        PL: "Wiązki przewodów elektrycznych",
        DE: "Elektrische Kabelbäume",
        FR: "Faisceaux de câbles électriques",
        EN: "Wire harnesses",
        IT: "Cablaggi elettrici",
      },
      onlyPL: false,

      url: "/offer/electrical-harnesses",
    },
    // {
    //   id: 7,
    //   name: "Przewody masowe i zasilające",
    //   url: "/offer/grounding-and-power-cables",
    // },
    {
      id: 8,
      name: {
        PL: "Elektronika na potrzeby klienta",
        DE: "Elektronik nach Kundenwunsch",
        FR: "Électronique sur mesure",
        EN: "Custom Electronics",
        IT: "Elettronica personalizzata",
      },
      onlyPL: false,

      url: "/offer/customer-specific-electronics",
    },
    // { id: 9, name: "Zestaw wskażników", url: "/offer/indicator-set" },
    // {
    //   id: 10,
    //   name: "Usługi biura konstrukcyjnego",
    //   url: "/offer/design-office-services",
    // },
    // { id: 11, name: "Usługi dodatkowe", url: "/offer/additional-services" },
    {
      id: 12,
      name: {
        PL: "Odsprzedaż materiałów",
        DE: "Materialverkauf",
        FR: "Revente de matériaux",
        EN: "Material Resale",
        IT: "Rivendita di materiali",
      },
      onlyPL: false,

      url: "/offer/sale-of-materials-and-components",
    },
  ],
  production: [
    {
      id: 13,
      name: {
        PL: "Park Maszynowy",
        DE: "Maschinenpark",
        FR: "Parc de machines",
        EN: "Machine Park",
        IT: "Parco macchine",
      },
      onlyPL: false,

      url: "/production/machine-park",
    },
    {
      id: 14,
      name: {
        PL: "Zastosowanie w branżach",
        DE: "Anwendung in Branchen",
        FR: "Application dans les industries",
        EN: "Application in Industries",
        IT: "Applicazione nelle industrie",
      },
      onlyPL: false,

      url: "/production/application-in-the-industry",
    },
  ],
  quality: [
    {
      id: 15,
      name: {
        PL: "Zarządzanie jakością",
        DE: "Qualitätsmanagement",
        FR: "Gestion de la qualité",
        EN: "Quality Management",
        IT: "Gestione della qualità",
      },
      onlyPL: false,

      url: "/quality/quality-management",
    },
    {
      id: 4,
      name: {
        PL: "Certyfikaty",
        DE: "Zertifikate",
        FR: "Certifications",
        EN: "Certificates",
        IT: "Certificazioni",
      },
      url: "/certificates",
      onlyPL: false,
    },
    {
      id: 5,
      name: {
        PL: "Polityka Jakości",
        DE: "Qualitätspolitik",
        FR: "Politique de qualité",
        EN: "Quality Policy",
        IT: "Politica di qualità",
      },
      onlyPL: false,
      url: "/quality/quality-policy",
    },
  ],
};

export default menuItems;
