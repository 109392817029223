const Data = {
  History: [
    {
      year: 1990,
      content: {
        PL: "powstaje Eltech Jacek Kamiński. Produkujemy elektronikę i wiązki przewodów do schładzarek i sprzętu gospodarstwa domowego",
        DE: 'entsteht "Eltech" Jacek Kamiński. Das Unternehmen produziert gedruckte Schaltungen und Kabelbäume für Kühlgeräte und Haushaltsgeräte',
        FR: "Création de la société Eltech Jacek Kamiński. Elle fabrique des composants électroniques et des faisceaux de câbles pour les réfrigérateurs et les appareils électroménagers.",
        EN: '"Eltech" Jacek Kamiński is founded. Production of printed circuit boards and wire harnesses for coolers and household appliances.',
        IT: "nasce la ditta Etech Jacek Kaminski. Produciamo i circuiti stampati e i cablaggi elettrici montati nei frigoriferi e negli altri impianti",
      },
    },
    {
      year: 1991,
      content: {
        PL: "rozpoczęcie produkcji wiązek przewodów dla samochodu ŻUK",
        DE: "Beginn der Produktion von Kabelbäumen für das Auto ŻUK",
        FR: "début de la production des faisceaux de câbles pour la voiture ŻUK",
        EN: "start of production of harnesses for ŻUK car",
        IT: "produzione dei cablaggi elettrici per le macchine ZUK",
      },
    },
    {
      year: 1994,
      content: {
        PL: "rozpoczęcie produkcji wiązek do samochodu LUBLIN",
        DE: "Beginn der Herstellung von Kabelbäumen für das Auto LUBLIN",
        FR: "début de la production des faisceaux pour la voiture LUBLIN",
        EN: "start of production of harnesses for LUBLIN car",
        IT: "avviamento della produzione dei cablaggi elettrici per i autocarri del tipo LUBLIN",
      },
    },
    {
      year: 1995,
      content: {
        PL: "rozpoczęcie produkcji miniaturowych wiązek do głośników samochodowych",
        DE: "Herstellung von Miniatur-Kabelbäumen für Auto-Lautsprecher ",
        FR: "lancement de la production de faisceaux miniatures pour haut-parleurs de voiture",
        EN: "start of production of miniature harnesses for car speakers",
        IT: "avviamento della produzione dei cablaggi in miniatura per autoparlanti per automobili",
      },
    },
    {
      year: 1996,
      content: {
        PL: "rozpoczęcie produkcji wiązek do halogenowych zestawów oświetleniowych",
        DE: "Beginn der KBB-Produktion für Halogenlampen",
        FR: "Début de la production de faisceaux pour les ensembles d'éclairage halogène",
        EN: "start of production of harnesses for halogen lighting sets ",
        IT: "avviamento della produzione dei cablaggi per KIT fanali di alogeno",
      },
    },
    {
      year: 1997,
      content: {
        PL: "rozpoczęcie produkcji wiązek do ciągników rolniczych dla odbiorców polskich i włoskich",
        DE: "Beginn der KBB-Produktion für Traktoren für polnische und italienische Kunden",
        FR: " lancement de la production de faisceaux pour tracteurs agricoles pour des clients polonais et italiens",
        EN: "start of production of harnesses for farm tractors for Polish and Italian customers",
        IT: "avviamento della produzione dei cablaggi per i trattori agricoli per i clienti polacchi ed italiani",
      },
    },
    {
      year: 1999,
      content: {
        PL: "rozpoczęcie produkcji zestawów oświetlenia awaryjnego dla odbiorcy z Niemiec",
        DE: "Beginn der Produktion zusätzlicher Notleuchten für den Empfänger aus Deutschland",
        FR: " Début de la production de faisceaux électriques pour des kits d' éclairage de secours pour un client allemand. ",
        EN: "start of production of emergency lighting sets for German customer",
        IT: "avviamento della produzione dell’illuminazione supplementare d’emergenza per cliente tedesco",
      },
    },
    {
      year: 2000,
      content: {
        PL: "rozpoczęcie produkcji odbłyśników do lamp ulicznych",
        DE: "Produktionsbeginn der Reflektoren für Straßenlampen ",
        FR: "Début de la production des reflètes pour les lampadaires.",
        EN: "start of production of reflectors for street lamps",
        IT: "l'inizio della produzione di specchi riflettori per lampioni",
      },
    },
    {
      year: 2001,
      content: {
        PL: [
          "firma ELTECH Jacek Kamiński powołuje ELTECH SP. Z O.O, rozpoczęcie produkcji zestawów wskaźników do ciągników rolniczych",
          "nawiązanie z włoską firmą MTA Sp. z o.o., co zaowocowało wzrostem produkcji wiązek przewodów dla ciągników rolniczych eksportowanych do Włoch i Niemiec\n",
        ],
        DE: [
          "Die Firma ELTECH Jacek Kamiński ernennt ELTECH SP. Z O.O., Produktionsbeginn der Kombiinstrumente für Traktoren",
          "Aufnahme der Zusammenarbeit mit dem italienischen Unternehmen MTA Sp. z o.o., was zu einer Steigerung der Produktion von Kabelbäumen für landwirtschaftliche Traktoren führte, die nach Italien und Deutschland exportiert werden\n",
        ],
        FR: [
          " L'Entreprise “ELTECH Jacek Kamiński” devient „ELTECH Société à responsabilité limitée”. Début de la production de kits d'indicateurs (tableaux de bord) pour les tracteurs agricoles. ",
          "établir une coopération avec la société italienne MTA Sp. z o. o., ce qui a entraîné une augmentation de la production de faisceaux de câbles pour tracteurs agricoles exportés vers l'Italie et l'Allemagne",
        ],
        EN: [
          "ELTECH Jacek Kamiński company sets up the ELTECH SP. Z O.O. company, start of production of intsrument cluster for agricultural tractors",
          "beginning of cooperation with the Italian company MTA Sp. z o.o., which resulted in increased production of wiring harnesses for agricultural tractors exported to Italy and Germany",
        ],
        IT: [
          'la ditta "ELTECH - Jacek Kaminski" si transforma nell ditta "ELTECH SP.Z O.O." (ELTECH S.R.L.), avviamento della produzione dei kit indicatori per macchine agricoli',
          "inizio della collaborazione con l'azienda italiana MTA Sp. z o.o., che ha portato a un aumento della produzione di cablaggi per trattori agricoli esportati in Italia e Germania",
        ],
      },
    },
    {
      year: 2002,
      content: {
        PL: [
          "rozpoczęcie produkcji wiązek do gazowych instalacji samochodowych i alarmowych",
          "rozpoczęcie produkcji wiązek do samochodów specjalizowanych typu ambulanse, policja, inspekcja drogowa, służba graniczna dla odbiorców niemieckich",
          "rozpoczęcie produkcji generatorów ultradźwiękowych do nawilżaczy powietrza",
        ],
        DE: [
          "Produktionsbeginn von Kabelbäumen für CNG- und Alarmanlagen  ",
          "Produktionsbeginn von Kabelbäumen für Sonderfahrzeuge wie Krankenwagen, Polizei, Straßeninspektion, Grenzdienst usw. für deutsche Kunden",
          "Beginn der Produktion von Ultraschallgeneratoren für Luftbefeuchter",
        ],
        FR: [
          "lancement de la production de faisceaux pour les voitures à gaz et les systèmes d'alarme",
          "la production de faisceaux de câbles pour les véhicules spécialisés tels que les ambulances, les voitures de police, les services d'inspection routière et de contrôle aux frontières pour les clients allemands",
          "lancement de la production de générateurs d'ultrasons pour humidificateurs d'air",
        ],
        EN: [
          "start of production of harnesses for gas car and alarm installations",
          "start of production of harnesses for special vehicles such as ambulance, police, road inspection, border service for German customers ",
          "start of production of ultrasonic generators for air damper",
        ],
        IT: [
          "avvio della produzione di cablaggi per auto a gas e sistemi di allarme",
          "avviamento della produzione dei cablaggi alle macchine specializzatesi del tipo le ambulanze, la polizia, l’ispezione stradale, il confinante servizio per clienti tedeschi ",
          "avviamento della produzione dei generatori a ultrasuoni per i umidificatori",
        ],
      },
    },
    {
      year: 2003,
      content: {
        PL: "powstaje firma ELTEM SP. Z O.O.",
        DE: "Entsteht eine neue Firma ELTEM SP Z O.O.",
        FR: "Création de la société ELTEM Sp. z O.O. ",
        EN: "ELTEM SP. Z O.O. is founded",
        IT: " nasce ditta nuova ELTEM SP.Z O.O. ",
      },
    },
    {
      year: 2004,
      content: {
        PL: "inwestycje w infrastrukturę firmy, zakup przyległych gruntów pod przyszłą rozbudowę zakładu",
        DE: "Investitionen in die Unternehmensinfrastruktur, Erwerb von Nachbargrundstücken für den zukünftigen Ausbau der Betriebsstätte",
        FR: "investissements dans les infrastructures de l'entreprise, achat de terrains adjacents pour l'agrandissement futur de l'usine",
        EN: "investments in the infrastructure of the company, purchase of adjacent land for future expansion of the plant",
        IT: "Investimenti nell'infrastruttura aziendale, acquisto di terreni adiacenti per la futura espansione dell'azienda",
      },
    },
    {
      year: 2005,
      content: {
        PL: "certyfikacja Systemu Zarządzania Jakością wg normy ISO 9001:2000 w zakresie produkcji wiązek elektrycznych",
        DE: "Zertifizierung des Qualitätsmanagementsystems nach ISO 9001: 2000 im Bereich der Herstellung von elektrischen Kabelbäumen",
        FR: "certification du système de gestion de la qualité selon la norme ISO 9001:2000 pour la production de faisceaux électriques",
        EN: "certification of quality management system according to norm ISO 9001:2000 in scope of production of electrical harnesses",
        IT: "Certificazione del Sistema di Gestione della Qualità secondo la norma ISO 9001: 2000 per la produzione di cablaggi elettrici",
      },
    },
    {
      year: 2006,
      content: {
        PL: "opracowanie i uruchomienie produkcji seryjnej zestawów wskaźników nowej generacji opartych na nowoczesnych rozwiązaniach technologicznych",
        DE: "Entwicklung und Einführung der Serienproduktion einer neuen Generation von Anzeigegeräten, basierend auf modernen technologischen Lösungen",
        FR: "le développement et le lancement de la production de masse d'ensembles d'indicateurs de nouvelle génération basés sur des solutions technologiques modernes",
        EN: "development and start of serial production of new generation instrument cluster based on modern technical solutions",
        IT: "Sviluppo e avviamento della produzione di serie di nuovi kit indicatori basati su moderne soluzioni tecnologiche",
      },
    },
    {
      year: 2007,
      content: {
        PL: "uczestnictwo w realizacji nowatorskiego projektu trójkołowego pojazdu o napędzie elektrycznym",
        DE: "Teilnahme an der Umsetzung eines innovativen Dreirad Elektrofahrzeug ",
        FR: "participer à la réalisation d'un projet innovant de tricycle électrique",
        EN: "participation in implementation of an innovative project of three-wheeled vehicle with electric drive",
        IT: "Partecipazione alla realizzazione di un progetto innovativo di veicoli elettrici a tre ruote",
      },
    },
    {
      year: 2008,
      content: {
        PL: "uzyskanie certyfikatu zarządzania środowiskowego według normy ISO 14001:2004",
        DE: "Zertifizierung für Umweltmanagement nach ISO 14001:2004 ",
        FR: "l'obtention d'une certification de gestion environnementale conformément à la norme ISO 14001:2004",
        EN: "certification in environmental management according to ISO 14001:2004",
        IT: "Ottenere un certificato di gestione ambientale secondo norma ISO 14001: 2004",
      },
    },
    {
      year: 2009,
      content: {
        PL: [
          "rozpoczęcie produkcji zestawów wskaźników z pełną obsługą szyny CAN",
          "rozwój kooperacji z potentatem w produkcji ciągników rolniczych firmą ARGO TRACTORS SPA (LANDINI) z Włoch i podpisanie porozumień, na podstawie których ELTEM Sp. z o.o. stał się jednym z głównych dostawców tej firmy",
        ],
        DE: [
          "Produktionsbeginn der Kombiinstrumente mit voller CAN-Schiene Unterstützung ",
          "die Entwicklung der Zusammenarbeit mit dem italienischen Traktorenhersteller ARGO TRACTORS SPA (LANDINI) und die Unterzeichnung von Verträgen, durch die ELTEM Sp. z o.o. zu einem der Hauptlieferanten des Unternehmens wurde",
        ],
        FR: [
          "Début de la production de kit d'indicateurs avec prise en charge complète du bus de donees CAN.",
          "développement de la coopération avec un leader dans la production de tracteurs agricoles, ARGO TRACTORS SPA (LANDINI) d'Italie et signature d'accords sur la base desquels ELTEM Sp. z o.o. zoo. est devenu l'un des principaux fournisseurs de l'entreprise",
        ],
        EN: [
          "start of production of instrument cluster with full CAN bus support",
          "increase of cooperation with the Italian agricultural tractor manufacturing giant ARGO TRACTORS SPA (LANDINI), and signing agreements under which ELTEM Sp. z o.o. became one of the company's main suppliers",
        ],
        IT: [
          "avviamento della produzione di kit indicatori con piena operazione linea CAN ",
          "sviluppo della collaborazione con un leader nella produzione di trattori agricoli, ARGO TRACTORS SPA (LANDINI) dall'Italia e firma di accordi in base ai quali ELTEM Sp. z o.o. zoo. è diventato uno dei principali fornitori dell'azienda",
        ],
      },
    },
    {
      year: 2010,
      content: {
        PL: [
          "rozpoczęcie produkcji zestawów wskaźników do maszyn przemysłu ciężkiego i sterowników do stołu medycznego",
          "wdrożenie do produkcji obsługi szyny CAN w zestawach wskaźników",
          "prace projektowe nad zestawem elektronicznym do sterowania stołem medycznym wykorzystywanym w produkowanych przez naszego partnera z Niemiec (firmy WAS WIETMARSCHER) pojazdach specjalistycznych – karetkach pogotowia ratunkowego",
          "opracowanie prototypów zestawów wskaźników do zastosowania w maszynach przemysłu ciężkiego (ekstremalne warunki użytkowania)",
        ],
        DE: [
          "Produktionsbeginn der Kombiinstrumente für Schwerindustriemaschinen und Steuerungen für den medizinischen Tisch ",
          "Produktionsimplementierung von CAN-Bus-Unterstützung in Anzeigageräten",
          "Entwicklung eines elektronischen Kits zur Steuerung des medizinischen Tisches, der in Spezialfahrzeugen - Krankenwagen - unseres deutschen Partners (WAS GmbH) verwendet wird",
          "Entwicklung von Prototyp-Anzeiger-Sets für den Einsatz in Maschinen der Schwerindustrie (extreme Betriebsbedingungen)",
        ],
        FR: [
          "début de la production d'ensembles d'indicateurs pour les machines de l'industrie lourde et de contrôleurs pour la table médicale",
          "réalisation en série de la prise en charge du bus CAN dans les tableaux de bord",
          "le travail de conception d'un kit électronique pour le contrôle de la table médicale utilisée dans les véhicules spécialisés - ambulances - fabriqués par notre partenaire allemand (WAS WIETMARSCHER)",
          "l'élaboration de prototypes d'indicateurs destinés aux machines de l'industrie lourde (conditions de fonctionnement extrêmes)",
        ],
        EN: [
          "start of production of instrument cluster for heavy duty machines and controllers for medical table",
          "start of production of instrument cluster with full CAN bus support",
          "project work on an electronic kit for controlling the medical table used in specialised vehicles - ambulances - manufactured by our partner from Germany (WAS WIETMARSCHER)",
          "development of prototype instrument cluster for heavy industry machinery (extreme operating conditions)",
        ],
        IT: [
          "avviamento della produzione di kit indicatori per macchine per l'industria pesante e dispositivi di comando per tavoli per usi medici",
          "avvio della produzione del quadro strumenti con supporto completo per il CAN bus",
          "la progettazione di un kit elettronico per il controllo del tavolo medico utilizzato nei veicoli specializzati - ambulanze - prodotti dal nostro partner tedesco (WAS WIETMARSCHER)",
          "sviluppo di prototipi di indicatori per l'uso in macchinari dell'industria pesante (condizioni operative estreme)",
        ],
      },
    },
    {
      year: 2011,
      content: {
        PL: [
          "wdrożenie specjalistycznego oprogramowania do obróbki dokumentacji technicznej",
          "automatyzacja procesu produkcji",
        ],
        DE: [
          "Implementierung von Spezialsoftware für die Bearbeitung der technischen Dokumentation",
          "Automatisierung des Produktionsprozesses",
        ],
        FR: [
          "l'application d'un programme spécialisé pour le traitement de la documentation technique",
          "l'automatisation du processus de production",
        ],
        EN: [
          "implementation of specialised software for processing technical documentation",
          "automation of the production process",
        ],
        IT: [
          "applicazione di un programma specializzato per l'elaborazione della documentazione tecnica",
          "automazione del processo produttivo",
        ],
      },
    },
    {
      year: 2012,
      content: {
        PL: "rozpoczęcie współpracy z kolejnym potentatem w produkcji ciągników rolniczych firmą SAME DEUTZ FAHR",
        DE: "Beginn der Zusammenarbeit mit einem anderen Riesen in der Produktion von landwirtschaftlichen Traktoren, der Firma SAME DEUTZ FAHR",
        FR: "Début de la coopération avec un autre géant de la production de tracteurs agricoles, l'entreprise SAME DEUTZ FAHR",
        EN: "start of cooperation with SAME DEUTZ FAHR - another giant in the production of agricultural tractors",
        IT: "Inizio della collaborazione con un altro gigante della produzione di trattori agricoli, SAME DEUTZ FAHR",
      },
    },
    {
      year: 2013,
      content: {
        PL: "rozpoczęcie produkcji wiązek do ciągników rolniczych dla odbiorców fińskich",
        DE: "Beginn der KBB-Produktion für Traktoren für finnische Kunden ",
        FR: "Début de la production de faisceaux pour tracteurs agricoles pour les clients finlandais",
        EN: "start of production of harnesses for tractors for Finnish customers",
        IT: "Avvio della produzione di cablaggi per trattori agricoli per i clienti finlandesi",
      },
    },
    {
      year: 2014,
      content: {
        PL: "rozwój kooperacji z firmą URSUS w Lublinie",
        DE: "Ausbau der Zusammenarbeit mit URSUS in Lublin",
        FR: "le développement de la coopération avec l'URSUS à Lublin",
        EN: "increase of cooperation with the company URSUS in Lublin",
        IT: "lo sviluppo della cooperazione con l'URSS a Lublin",
      },
    },
    {
      year: 2015,
      content: {
        PL: "rozpoczęcie produkcji wiązek do samochodów elektrycznych",
        DE: "Beginn der KBB-Produktion für Elektrofahrzeuge ",
        FR: "Début de la production de faisceaux de câbles pour voitures électriques",
        EN: "start of production of harnesses for electrical vehicles",
        IT: "Avvio della produzione di cablaggi per auto elettriche",
      },
    },
    {
      year: 2016,
      content: {
        PL: "rozpoczęcie produkcji sterowników elektronicznych do maszyn rolniczych, nawiązanie współpracy z firmami ukraińskimi",
        DE: "Beginn der Produktion von elektronischen Steuerungen für landwirtschaftliche Maschinen, Aufbau der Zusammenarbeit mit ukrainischen Unternehmen ",
        FR: "Début de la production de dispositifs de contrôle électroniques pour les machines agricoles et le début de la coopération avec des sociétés ukrainiennes.",
        EN: "start of production of electronic controllers for agricultural machinery, establishing cooperation with Ukrainian companies",
        IT: "Avvio della produzione di dispositivi di controllo elettronico per macchine agricole e avvio della collaborazione con aziende ucraine",
      },
    },
    {
      year: 2017,
      content: {
        PL: "rozpoczęcie produkcji wiązek do maszyn budowlanych, podwojenie powierzchni produkcyjnej zakładu do 8.000 m2",
        DE: "Beginn der KBB-Produktion für Baumaschinen, Verdoppelung der Produktionsfläche auf 8.000 m2",
        FR: "Début de la production de faisceaux électriques pour les machines de construction. Double augmentation de la surface de production de l'usine à 8000 m2.",
        EN: "start of production of harnesses for construction machines, doubling the production area of the plant to 8.000 m2",
        IT: "avviamento della produzione di cablaggi per macchine edili, raddoppiamento della superficie di produzione dell'azienda a 8.000 m2 ",
      },
    },
    {
      year: 2018,
      content: {
        PL: "nawiązanie współpracy z niemiecką firmą dostarczającą wyroby do wielu firm działających w branży AUTOMOTIVE",
        DE: "Aufnahme der Zusammenarbeit mit einem deutschen Unternehmen, das zahlreiche Unternehmen der Automobilindustrie mit seinen Produkten beliefert",
        FR: "l'établissement d'une coopération avec une société allemande fournissant des produits à de nombreuses entreprises de l'industrie automobile",
        EN: "start of cooperation with a German supplier of  products for the automotive industry",
        IT: "collaborazione con Tima che fornisce prodotti a numerose aziende del settore OFF ROAD",
      },
    },
    {
      year: 2019,
      content: {
        PL: "wejście firmy na nowy rynek zbytu, Ukrainę. Nawiązanie współpracy w zakresie dostawy części do produkcji maszyn rolniczych i budowalnych",
        DE: "der Eintritt des Unternehmens in einen neuen Markt, die Ukraine. Aufbau einer Zusammenarbeit bei der Lieferung von Teilen für die Herstellung von Land- und Baumaschinen",
        FR: "l'entrée de l'entreprise sur un nouveau marché, l'Ukraine. Mise en place d'une coopération dans la fourniture de pièces pour la fabrication de machines agricoles et de construction",
        EN: "entry into a new market, Ukraine. Start of cooperation in terms of supplying parts for the manufacture of agricultural and construction machinery",
        IT: "L'ingresso dell'azienda in un nuovo mercato, l'Ucraina. Collaborazione per la fornitura di parti per la produzione di macchine agricole e per l'edilizia.",
      },
    },
    {
      year: 2020,
      content: {
        PL: "rozpoczęcie współpracy z międzynarodowym koncernem dostarczającym urządzenia transportowe do ciężkich ładunków w wielu gałęziach gospodarki",
        DE: "Beginn der Zusammenarbeit mit einem multinationalen Unternehmen, das Transportmittel für schwere Lasten in vielen Wirtschaftszweigen liefert",
        FR: "début de la coopération avec une multinationale fournissant des équipements de transport pour les charges lourdes dans de nombreux secteurs de l'économie",
        EN: "start of cooperation with a multinational company supplying transport equipment for heavy loads in numerous sectors of the economy",
        IT: "avvio della collaborazione con una multinazionale che fornisce attrezzature per il trasporto di carichi pesanti in molti settori dell'economia",
      },
    },
    {
      year: 2021,
      content: {
        PL: "wykonanie projektu i rozpoczęcie współpracy z firmą polską w zakresie produkcji części do autobusu wodorowego",
        DE: "Entwurf und Beginn der Zusammenarbeit mit einem polnischen Unternehmen für die Herstellung von Komponenten für den Wasserstoffbus",
        FR: "la conception et le début de la coopération avec une entreprise polonaise pour la production de composants pour le bus à hydrogène",
        EN: "development of a design and start of cooperation with a Polish company on the production of components for the hydrogen bus",
        IT: "la progettazione e l'avvio della collaborazione con un'azienda polacca per la produzione di componenti per l'autobus a idrogeno",
      },
    },
    {
      year: 2022,
      content: {
        PL: "uruchomienie produkcji dla europejskiego koncernu produkującego maszyny budowlane",
        DE: "Produktionsstart für einen europäischen Baumaschinenkonzern",
        FR: "Le lancement de la production pour un groupe européen de machines de construction",
        EN: "start of production for a European construction machinery concern",
        IT: "Avvio della produzione per un gruppo europeo di macchine edili",
      },
    },
    {
      year: 2023,
      content: {
        PL: "wejście firmy na nowy rynek zbytu, Finlandię. Nawiązanie kooperacji z firmą produkującą maszyny komunalne",
        DE: "Eintritt des Unternehmens in einen neuen Markt, Finnland. Gründung einer Partnerschaft mit einem Unternehmen, das Kommunalmaschinen herstellt",
        FR: "l'entrée de l'entreprise sur un nouveau marché, la Finlande. Établissement d'un partenariat avec une entreprise de fabrication de machines municipales",
        EN: "entry into a new market, Finland. Start of cooperation with a company producing municipal machines",
        IT: "l'ingresso dell'azienda in un nuovo mercato, la Finlandia. Creazione di una partnership con un'azienda produttrice di macchine municipali",
      },
    },
  ],
};

export default Data;
