export const translations = [
  {
    PL: "Odsprzedaż materiałów",
    DE: "Wiederverkauf von Materialien",
    FR: "Revente de matériaux",
    EN: "Resale of materials",
    IT: "Rivendita dei componenti",
  },
  {
    PL: "Poniżej znajduje się lista materiałów, które mamy na odsprzedaż. Prosimy o kontakt z Działem Zakupów (tel.81 561-22-38, e-mail jacek.zglinski@eltem.pl) w celu sprawdzenia aktualnej dostępności materiałów oraz uzyskania innych interesujących Państwa informacji.",
    DE: "Nachstehend finden Sie eine Liste der Materialien, die wir zum Wiederverkauf anbieten. Bitte wenden Sie sich an die Einkaufsabteilung (Tel. 81 561-22-38, E-Mail jacek.zglinski@eltem.pl), um die aktuelle Verfügbarkeit der Materialien zu prüfen und weitere Informationen zu erhalten.",
    FR: "Vous trouverez ci-dessous une liste du matériel que nous avons à revendre. Veuillez contacter le service des achats (tél. +48 81 561-22-38, e-mail jacek.zglinski@eltem.pl) pour vérifier la disponibilité actuelle du matériel et pour toute autre information utile.",
    EN: "Below you will find a list of the materials we have for resale. Please contact the Purchasing Department (tel.81 561-22-38, e-mail jacek.zglinski@eltem.pl) to check the current availability of materials and for other information of interest.",
    IT: "Qui di seguito l’elenco dei materiali destinati alla rivendita. Si prega di contattare il reparto di acquisti (tel. 81 561-22-38,  e-mail jacek.zglinski@eltem.pl)  al fine di verificare la disponibilità attuale dei materiali e l’ottenimento delle informazioni interessanti per Voi. ",
  },
  {
    PL: "Oferta",
    DE: "Angebot",
    FR: "Offre",
    EN: "Offer",
    IT: "Offerta",
  },
  {
    PL: "Znaleziono",
    DE: "Gefunden",
    FR: "Trouvé",
    EN: "Found",
    IT: "Trovato",
  },
  {
    PL: "pozycji",
    DE: "Position",
    FR: "Position",
    EN: "Position",
    IT: "Posizione",
  },
  {
    PL: "szukaj",
    DE: "suchen",
    FR: "rechercher",
    EN: "search",
    IT: "cerca",
  },
];
