import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/baners/banerCertificates.jpg";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/styles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "",
    width: "100%",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    margin: "20px",
  },
  text: {
    "&>b": {
      color: color.green3,
    },
  },
  grid: {
    padding: "20px",
  },
  imageContainer: {
    padding: "20px",
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      display: "block",
      "&>img": {
        margin: "0px",
        height: "300px",
      },
    },
  },
  link: {
    marginTop: "20px",
    color: color.green3,
    "&:hover": {
      color: color.green2,
    },
  },
  icon: {
    marginRight: "10px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
    marginTop: "20px",
    textTransform: "uppercase",
  },
}));

const RodoPolicy = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title="Polityka Prywatności - RODO"
        naviChild1="Polityka Prywatności - RODO"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography className={classes.text}>
              Wszystkie wiadomości otrzymywane od <b>ELTEM Sp. z o.o</b> drogą
              elektroniczną wraz z załączonymi do niej plikami stanowią
              tajemnicę firmy będącej Administratorem Danych Osobowych:{" "}
              <b>
                <br />
                <br />
                ELTEM Sp. z o.o. z siedzibą: ul. Smoluchowskiego 3,20-474 Lublin{" "}
                <br />
                <br />
              </b>
              i są przeznaczone tylko dla wymienionych adresatów. Jeżeli nie są
              Państwo zamierzonym odbiorcą, proszę poinformować o tym fakcie
              nadawcę oraz usunąć wiadomość ze swojego systemu, a także nie
              powinni Państwo ujawniać otrzymanych informacji nikomu ani
              sporządzać/zachowywać/dystrybuować żadnej kopii otrzymanych
              informacji. Dane zawarte w treści wiadomości e-mail będą
              przetwarzane w celach wynikających z treści korespondencji przez
              okres niezbędny do załatwienia sprawy, której dotyczy
              korespondencja, a jeśli jest to ważne ze względu na prawnie
              usprawiedliwiony interes administratora i osób trzecich, których
              dotyczy korespondencja w celach dochodzenia roszczeń. Dane mogą
              być również przetwarzane przez podmiot serwisujący program
              pocztowy administratora. Prawa otrzymującego określone są w
              rozdziale III ogólnego rozporządzenia o ochronie danych osobowych.
            </Typography>
          </Grid>
          <div>
            <h2>
              Polityka prywatności opisuje zasady przetwarzania przez nas
              informacji na Twój temat, w tym danych osobowych oraz ciasteczek,
              czyli tzw. cookies.
            </h2>

            <div>
              <h2>
                <strong>1. Informacje ogólne</strong>
              </h2>
              <ol>
                <li>
                  Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
                  adresem url: <b>https://eltem.pl</b>
                </li>
                <li>
                  Operatorem serwisu oraz Administratorem danych osobowych jest:
                  <b>ELTEM Sp. z o.o. ul. SMOLUCHOWSKIEGO 3</b>
                </li>

                <li>
                  Adres kontaktowy poczty elektronicznej operatora:
                  <b> office@eltem.pl</b>
                </li>

                <li>
                  Operator jest Administratorem Twoich danych osobowych w
                  odniesieniu do danych podanych dobrowolnie w Serwisie.
                </li>
                <li>Serwis wykorzystywany jest w celach:</li>
                <ul>
                  <li>Prezentacja oferty lub informacji o firmie</li>
                </ul>
                <li>
                  Serwis realizuje funkcje pozyskiwania informacji o
                  użytkownikach i ich zachowaniu w następujący sposób:
                  <ol>
                    <li>
                      Poprzez zapisywanie w urządzeniach końcowych plików cookie
                      (tzw. „ciasteczka”).
                    </li>
                  </ol>
                </li>
              </ol>
              <h2>
                <strong>
                  2. Wybrane metody ochrony danych stosowane przez Operatora
                </strong>
              </h2>
              <ol>
                <li>
                  Strona jest zabezpieczona przez certyfikat SSL w celach
                  bezpieczeństwa.
                </li>
              </ol>
              <h2>
                <strong>3. Hosting</strong>
              </h2>
              <ol>
                <li>
                  Serwis jest hostowany (technicznie utrzymywany) na serwerach
                  operatora: <b>unixstorm.org</b>
                </li>

                <li>
                  Firma hostingowa w celu zapewnienia niezawodności technicznej
                  prowadzi logi na poziomie serwera. Zapisowi mogą podlegać:
                  <ul>
                    <li>
                      zasoby określone identyfikatorem URL (adresy żądanych
                      zasobów – stron, plików),
                    </li>
                    <li>czas nadejścia zapytania,</li>
                    <li>czas wysłania odpowiedzi,</li>
                    <li>
                      nazwę stacji klienta – identyfikacja realizowana przez
                      protokół HTTP/HTTPS,
                    </li>
                    <li>
                      informacje o błędach jakie nastąpiły przy realizacji
                      transakcji HTTP/HTTP,
                    </li>
                    <li>
                      adres URL strony poprzednio odwiedzanej przez użytkownika
                      (referer link) – w przypadku gdy przejście do Serwisu
                      nastąpiło przez odnośnik,
                    </li>
                    <li>informacje o przeglądarce użytkownika,</li>
                    <li>informacje o adresie IP,</li>
                    <li>
                      informacje diagnostyczne związane z procesem samodzielnego
                      zamawiania usług poprzez rejestratory na stronie,
                    </li>
                    <li>
                      informacje związane z obsługą poczty elektronicznej
                      kierowanej do Operatora oraz wysyłanej przez Operatora.
                    </li>
                  </ul>
                </li>
              </ol>
              <h2>
                <strong>
                  4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania
                  danych
                </strong>
              </h2>
              <ol>
                <li>
                  W niektórych sytuacjach Administrator ma prawo przekazywać
                  Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne
                  do wykonania zawartej z Tobą umowy lub do zrealizowania
                  obowiązków ciążących na Administratorze. Dotyczy to takich
                  grup odbiorców:
                  <ul>
                    <li>firma hostingowa na zasadzie powierzenia</li>
                  </ul>
                </li>
                <li>
                  Twoje dane osobowe przetwarzane przez Administratora nie
                  dłużej, niż jest to konieczne do wykonania związanych z nimi
                  czynności określonych osobnymi przepisami (np. o prowadzeniu
                  rachunkowości). W odniesieniu do danych marketingowych dane
                  nie będą przetwarzane dłużej niż przez 3 lata.
                </li>
                <li>
                  Przysługuje Ci prawo żądania od Administratora:
                  <ul>
                    <li>dostępu do danych osobowych Ciebie dotyczących,</li>
                    <li>ich sprostowania,</li>
                    <li>usunięcia,</li>
                    <li>ograniczenia przetwarzania,</li>
                    <li>oraz przenoszenia danych.</li>
                  </ul>
                </li>
                <li>
                  Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
                  przetwarzania wskazanego w pkt 3.2 wobec przetwarzania danych
                  osobowych w celu wykonania prawnie uzasadnionych interesów
                  realizowanych przez Administratora, w tym profilowania, przy
                  czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku
                  istnienia ważnych prawnie uzasadnionych podstaw do
                  przetwarzania, nadrzędnych wobec Ciebie interesów, praw i
                  wolności, w szczególności ustalenia, dochodzenia lub obrony
                  roszczeń.
                </li>
                <li>
                  Na działania Administratora przysługuje skarga do Prezesa
                  Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193
                  Warszawa.
                </li>
                <li>
                  Podanie danych osobowych jest dobrowolne, lecz niezbędne do
                  obsługi Serwisu.
                </li>
                <li>
                  W stosunku do Ciebie mogą być podejmowane czynności polegające
                  na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w
                  celu świadczenia usług w ramach zawartej umowy oraz w celu
                  prowadzenia przez Administratora marketingu bezpośredniego.
                </li>
                <li>
                  Dane osobowe nie są przekazywane od krajów trzecich w
                  rozumieniu przepisów o ochronie danych osobowych. Oznacza to,
                  że nie przesyłamy ich poza teren Unii Europejskiej.
                </li>
              </ol>
              <h2>
                <strong>5. Informacje w formularzach</strong>
              </h2>
              <ol>
                {/* <li>
                  Serwis zbiera informacje podane dobrowolnie przez użytkownika,
                  w tym dane osobowe, o ile zostaną one podane.
                </li> */}
                {/* <li>
                  Serwis może zapisać informacje o parametrach połączenia
                  (oznaczenie czasu, adres IP).
                </li> */}

                {/* <li>
                  Serwis, w niektórych wypadkach, może zapisać informację
                  ułatwiającą powiązanie danych w formularzu z adresem e-mail
                  użytkownika wypełniającego formularz. W takim wypadku adres
                  e-mail użytkownika pojawia się wewnątrz adresu url strony
                  zawierającej formularz.
                </li>
                <li>
                  Dane podane w formularzu są przetwarzane w celu wynikającym z
                  funkcji konkretnego formularza, np. w celu dokonania procesu
                  obsługi zgłoszenia serwisowego lub kontaktu handlowego,
                  rejestracji usług itp. Każdorazowo kontekst i opis formularza
                  w czytelny sposób informuje, do czego on służy.
                </li> */}
                <li>Serwis nie posiada formularzy</li>
              </ol>

              <h2>
                <strong>6. Logi Administratora</strong>
              </h2>
              <ol>
                <li>
                  Serwis nie posiada systemu zbierających logów.
                  {/* Informacje zachowaniu użytkowników w serwisie mogą podlegać
                  logowaniu. Dane te są wykorzystywane w celu administrowania
                  serwisem. */}
                </li>
              </ol>
              {/* <h2>
                <strong>7. Istotne techniki marketingowe</strong>
              </h2>
              <ol>
                <li>
                  Operator stosuje analizę statystyczną ruchu na stronie,
                  poprzez Google Analytics (Google Inc. z siedzibą w USA).
                  Operator nie przekazuje do operatora tej usługi danych
                  osobowych, a jedynie zanonimizowane informacje. Usługa bazuje
                  na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika.
                  W zakresie informacji o preferencjach użytkownika gromadzonych
                  przez sieć reklamową Google użytkownik może przeglądać i
                  edytować informacje wynikające z plików cookies przy pomocy
                  narzędzia: https://www.google.com/ads/preferences/
                </li>
              </ol> */}

              <h2>
                <strong>7. Informacja o plikach cookies</strong>
              </h2>
              <ol>
                <li>Serwis korzysta z plików cookies:</li>
                <b>"cookiesAccepted"</b> - zmienna określająca czy polityka
                prywatności została zaakceptowana przez użytkownika.
                <li>
                  Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne,
                  w szczególności pliki tekstowe, które przechowywane są w
                  urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do
                  korzystania ze stron internetowych Serwisu. Cookies zazwyczaj
                  zawierają nazwę strony internetowej, z której pochodzą, czas
                  przechowywania ich na urządzeniu końcowym oraz unikalny numer.
                </li>
                <li>
                  Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
                  Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
                  operator Serwisu.
                </li>
                <li>
                  Pliki cookies wykorzystywane są w następujących celach:
                  <ol>
                    <li>W celu poprawności poprawności działania strony.</li>
                  </ol>
                </li>
                <li>
                  W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
                  cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
                  cookies). Cookies „sesyjne” są plikami tymczasowymi, które
                  przechowywane są w urządzeniu końcowym Użytkownika do czasu
                  wylogowania, opuszczenia strony internetowej lub wyłączenia
                  oprogramowania (przeglądarki internetowej). „Stałe” pliki
                  cookies przechowywane są w urządzeniu końcowym Użytkownika
                  przez czas określony w parametrach plików cookies lub do czasu
                  ich usunięcia przez Użytkownika.
                </li>
                <li>
                  Oprogramowanie do przeglądania stron internetowych
                  (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza
                  przechowywanie plików cookies w urządzeniu końcowym
                  Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień
                  w tym zakresie.&nbsp;Przeglądarka internetowa umożliwia
                  usunięcie plików cookies. Możliwe jest także automatyczne
                  blokowanie plików cookies Szczegółowe informacje na ten temat
                  zawiera pomoc lub dokumentacja przeglądarki internetowej.
                </li>
                <li>
                  Ograniczenia stosowania plików cookies mogą wpłynąć na
                  niektóre funkcjonalności dostępne na stronach internetowych
                  Serwisu.
                </li>
                <li>
                  Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                  Serwisu wykorzystywane mogą być również przez współpracujące z
                  operatorem Serwisu podmioty, w szczególności dotyczy to firm:
                  Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc.
                  z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).
                </li>
              </ol>
              <h2>
                <strong>
                  8. Zarządzanie plikami cookies – jak w praktyce wyrażać i
                  cofać zgodę?
                </strong>
              </h2>
              <ol>
                <li>
                  Jeśli użytkownik nie chce otrzymywać plików cookies, może
                  zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie
                  obsługi plików cookies niezbędnych dla procesów
                  uwierzytelniania, bezpieczeństwa, utrzymania preferencji
                  użytkownika może utrudnić,&nbsp;a w skrajnych przypadkach może
                  uniemożliwić korzystanie ze stron www
                </li>
                <li>
                  W celu zarządzania ustawienia cookies wybierz z listy poniżej
                  przeglądarkę internetową, której używasz i postępuj zgodnie z
                  instrukcjami:
                  <ul>
                    <li>
                      <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
                        Edge
                      </a>
                    </li>
                    <li>
                      <a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                        Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                        Chrome
                      </a>
                    </li>
                    <li>
                      <a href="http://support.apple.com/kb/PH5042">Safari</a>
                    </li>
                    <li>
                      <a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">
                        Firefox
                      </a>
                    </li>
                    <li>
                      <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                        Opera
                      </a>
                    </li>
                  </ul>
                  <p>Urządzenia mobilne:</p>
                  <ul>
                    <li>
                      <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                        Android
                      </a>
                    </li>
                    <li>
                      <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">
                        Safari (iOS)
                      </a>
                    </li>
                    <li>
                      <a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">
                        Windows Phone
                      </a>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default RodoPolicy;
