export const translations = [
  {
    ID: 0,
    PL: "Dofinansowanie ze środków Unii Europejskiej",
    DE: "Fördermittel aus EU-Mitteln",
    FR: "Financement de l'Union européenne",
    EN: "EU Funds Support",
    IT: "Finanziamenti dall'Unione Europea",
  },
  {
    ID: 1,
    PL: "Historia",
    DE: "Geschichte",
    FR: "Histoire",
    EN: "History",
    IT: "Storia",
  },
  {
    ID: 2,
    PL: "Partnerzy",
    DE: "Partner",
    FR: "Partenaires",
    EN: "Partners",
    IT: "Partner",
  },
  {
    ID: 3,
    PL: "Certyfikaty",
    DE: "Zertifikate",
    FR: "Certifications",
    EN: "Certificates",
    IT: "Certificazioni",
  },
  {
    ID: 4,
    PL: "Polityka Jakości",
    DE: "Qualitätspolitik",
    FR: "Politique de qualité",
    EN: "Quality Policy",
    IT: "Politica di qualità",
  },
  {
    ID: 5,
    PL: "Wiązki przewodów elektrycznych",
    DE: "Elektrische Kabelbäume",
    FR: "Faisceaux de câbles électriques",
    EN: "Wire harnesses",
    IT: "Cablaggi elettrici",
  },

  {
    ID: 6,
    PL: "Elektronika na potrzeby klienta",
    DE: "Elektronik nach Kundenwunsch",
    FR: "Électronique sur mesure",
    EN: "Custom Electronics",
    IT: "Elettronica personalizzata",
  },

  {
    ID: 7,
    PL: "Odsprzedaż materiałów",
    DE: "Materialverkauf",
    FR: "Revente de matériaux",
    EN: "Material Resale",
    IT: "Rivendita di materiali",
  },

  {
    ID: 8,
    PL: "Park Maszynowy",
    DE: "Maschinenpark",
    FR: "Parc de machines",
    EN: "Machine Park",
    IT: "Parco macchine",
  },

  {
    ID: 9,
    PL: "Zastosowanie w branżach",
    DE: "Anwendung in Branchen",
    FR: "Application dans les industries",
    EN: "Application in Industries",
    IT: "Applicazione nelle industrie",
  },

  {
    ID: 10,
    PL: "Zarządzanie jakością",
    DE: "Qualitätsmanagement",
    FR: "Gestion de la qualité",
    EN: "Quality Management",
    IT: "Gestione della qualità",
  },

  {
    ID: 11,
    PL: "Polityka Jakości",
    DE: "Qualitätspolitik",
    FR: "Politique de qualité",
    EN: "Quality Policy",
    IT: "Politica di qualità",
  },
];
