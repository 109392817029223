export const translations = [
  {
    PL: "Park Maszyn",
    DE: "Maschinenpark",
    FR: "Parc de machines",
    EN: "Machinery Park",
    IT: "Parco macchine",
  },
  {
    PL: "Produkcja",
    DE: "Produktion",
    FR: "Production",
    EN: "Production",
    IT: "Produzione",
  },
  {
    PL: "Rozbudowana i zaawansowana technicznie baza produkcyjna obejmuje zautomatyzowane maszyny, urządzenia i sprzęty niezbędne w procesie produkcji wiązek elektrycznych oraz elektroniki na potrzeby klienta. Dzięki naszym innowacyjnym rozwiązaniom, zapewniamy pełną kontrolę nad obróbką przewodów o różnych rozmiarach, od 0,12 do 120 mm2.",
    DE: "Unsere umfangreiche und technisch fortschrittliche Produktionsbasis umfasst die automatisierten Maschinen, Ausrüstungen und Anlagen, die für die Herstellung von elektrischen Kabelbäumen und Elektronik für Kundenanforderungen erforderlich sind. Dank unserer innovativen Lösungen bieten wir die volle Kontrolle über die Verarbeitung von Kabeln verschiedener Größen, von 0,12 bis 120 mm2.",
    FR: "La base de production étendue et techniquement avancée comprend des machines automatisées, des dispositifs et des équipements nécessaires à la production de faisceaux électriques et électroniques pour les besoins des clients. Grâce à nos solutions innovantes, nous offrons une maîtrise totale sur le traitement de câbles de différentes tailles, de 0,12 à 120 mm2.",
    EN: "Our extensive and technically advanced manufacturing facilities include the automated machinery, equipment and devices necessary for the production of electrical harnesses and customided electronics. Thanks to our innovative solutions, we provide full control over the processing of wires of various sizes, from 0.12 to 120 mm2.",
    IT: "La nostra base produttiva, ampia e tecnicamente avanzata, comprende i macchinari, le attrezzature e gli impianti automatizzati necessari per la produzione di cablaggi elettrici ed elettronici per le esigenze dei clienti. Grazie alle nostre soluzioni innovative, forniamo un controllo completo sulla lavorazione di cavi di varie dimensioni, da 0,12 a 120 mm2.",
  },
  {
    PL: "Nasz nowoczesny park maszynowy oparty na renomowanych maszynach firm KOMAX i SCHLEUNIGER, jest wyposażony w technologię cut & crimp, co umożliwia precyzyjne cięcie i zaciskanie przewodów. Dysponujemy obecnie 9 maszynami tego typu, które gwarantują efektywność i wysoką jakość naszych produktów.",
    DE: "Unser hochmoderner Maschinenpark, der auf renommierten KOMAX- und SCHLEUNIGER-Maschinen basiert, ist mit der Cut & Crimp-Technologie ausgestattet, die ein präzises Schneiden und Crimpen von Leitungen ermöglicht. Derzeit verfügen wir über 9 Maschinen dieses Typs, die die Effizienz und hohe Qualität unserer Produkte garantieren.",
    FR: "Notre parc de machines moderne, basé sur les machines renommées de KOMAX et SCHLEUNIGER, est équipé de la technologie Cut & Sertissage, qui permet une coupe et un sertissage précis des câbles. Nous disposons actuellement de 9 machines de ce type, qui garantissent l'efficacité et la haute qualité de nos produits.",
    EN: "Our high-tech machinery, based on renowned KOMAX and SCHLEUNIGER machines, is equipped with cut & crimp technology, which enables precise cutting and crimping of wires. We currently have 9 machines of this type, which guarantee the efficiency and high quality of our products.",
    IT: "I nostri moderni macchinari, basati sulle rinomate macchine KOMAX e SCHLEUNIGER, sono dotati della tecnologia Cut & Crimp, che consente di tagliare e crimpare i cavi con precisione. Attualmente disponiamo di 9 macchine di questo tipo, che garantiscono l'efficienza e l'alta qualità dei nostri prodotti.",
  },
  {
    PL: "Stanowiska do pomiaru sił zacisku końcówek:",
    DE: "Stationen für die Messung der Cripmkräfte von den Kontakten",
    FR: "Postes de mesure des forces de serrage des pointes :",
    EN: "Stations for measuring wire crimping parameters:",
    IT: "Posti per la misurazione la forza di aggraffatura dei terminali",
  },
  {
    PL: "Przy automatach znajdują się urządzenia do pomiaru siły zrywania i wysokości zacisku. Porównują one parametry zaciśniętej końcówki z wzorcowymi parametrami w bazie danych. Jeżeli mieszczą się one w granicach dopuszczonej tolerancji możliwe jest uruchomienie produkcji seryjnej.",
    DE: "An den Automaten befinden sich Geräte zur Messung der Abzugskraft und der Crimphöhe. Diese vergleichen die Parameter des gecrimpten Endes mit den Referenzparametern in der Datenbank. Wenn diese innerhalb der zulässigen Toleranz liegen, kann die Serienproduktion gestartet werden.",
    FR: "Les machines automatiques sont équipées de dispositifs de mesure de la force de rupture et de la hauteur des pinces. Ces appareils comparent les paramètres de l'extrémité sertie avec les paramètres de référence de la base de données. Si ces paramètres se situent dans la tolérance autorisée, la production en série peut être lancée.",
    EN: "The automatic devices are equipped with the crimping/tearing power measurement equipment. They compare the parameters of a crimped wire with the benchmark parameters from the data base. If they are within the range of tolerance the production of wire may be continued.",
    IT: "Accanto alle macchine automatiche si trovano gli impianti per la misurazione della forza di rottura e l’altezza dell’aggraffatura che confrontano i parametri del terminale aggraffato con i parametri di riferimento in database. Se rientrano nei limiti di tolleranza è possibile avviare la  produzione serie.",
  },
  {
    PL: "Stoły kontrolne:",
    DE: "Prüftische:",
    FR: "Tables de contrôle :",
    EN: "Control tables:",
    IT: "Tavole di collaudo:",
  },
  {
    PL: [
      "Wszystkie zmontowane wiązki trafiają na stoły kontrolne. Dedykowany program kontrolny do każdego rodzaju wiązki, sprawdza poprawność wszystkich połączeń elektrycznych, wartości zastosowanych rezystorów, diod",
      "Kontroli podlega również estetyka wykonania oraz kompletność wiązki.",
    ],
    DE: [
      "Alle gebauten Kabelbäume geraten auf den Prüftisch.  Auf der Prüfeinrichtung überprüft ein speziell geschriebenes Programm, individuell für jede Art vom Kabelbaum, alle elektrischen Verbindungen, Werte der verwendeten Dioden, Widerstände und Relais.",
      "Ästhetik der Ausführung und KBB-Vollständigkeit werden auch kontrolliert.",
    ],
    FR: [
      "Tous les faisceaux assemblés passent sur les tables de contrôle. Un programme de contrôle dédié à chaque type de faisceau vérifie l'exactitude de toutes les connexions électriques, les valeurs des résistances utilisées, des diodes, des câbles et de l'alimentation.",
      "L'esthétique de l'exécution et l'exhaustivité de la liasse sont également contrôlées.",
    ],
    EN: [
      "All assembled harnesses reach the control tables. A specially written program, individual for each type of harness, checks all electrical connections, values of used diodes and resistors.",
      "The aesthetics and completeness of the harness are also controlled.",
    ],
    IT: [
      "Tutti i cablaggi assemblati vengono sottoposti ai tavoli di collaudo. Un programma di collaudo dedicato per ogni tipo di cablaggio, verifica la correttezza di tutti i collegamenti elettrici, i valori delle resistenze utilizzate, i diodi e le connessioni elettriche.",
      "Vengono controllati anche l'estetica della lavorazione e la completezza del cablaggio.",
    ],
  },
  {
    PL: "Dodatkowo dysponujemy:",
    DE: "Darüber hinaus verfügen wir über:",
    FR: "En outre, nous avons :",
    EN: "Additionally, we have:",
    IT: "Disponiamo anche di:",
  },
  {
    PL: [
      "Drukarki i aplikatory uszczelek współpracujące z maszynami KOMAX i SCHLEUNIGER",
      "Drukarki offline do nadruków specjalnych",
      "Zgrzewarki ultradźwiękowe Schunk",
      "Skręcarki przewodów",
      "Automaty firmy Schleuniger do obróbki przewodów specjalnych i wielożyłowych",
      "Prasy mimośrodowe do zacisku terminali specjalnych",
      "Specjalistyczne miniaplikatory, dostosowane do zacisku stosowanych przez nas złącz. Obecnie posiadamy ponad 400 miniaplikatorów firmy MECAL, TE i MOLEX, które gwarantują prawidłową jakość zacisku",
      "Prasy hydrauliczne",
      "Maszyny do taśmowania",
      "Maszyny do cięcia osłon",
      "Maszyny i urządzenia do obkurczania koszulek termokurczliwych promieniowaniem podczerwonym",
      "i wiele innnych ...",
    ],
    DE: [
      "Drucker und Applikatoren für den Einastz von Dichtungen für KOMAX und SCHLEUNIGER Maschinen",
      "Offline-Drucker für Spezialdrucke",
      "Schunk Ultraschallschweißmaschinen ",
      "Verdrillmaschinen für Kabel",
      "Schleuniger Automaten für die Bearbeitung von Spezial- und mehradrigen Kabeln",
      "Exzenterpressen zum Crimpen von Sonderkontakte",
      "Spezialisierte Miniaplikatoren, die an die Klemmung der von uns verwendeten Steckverbinder angepasst sind. Derzeit verfügen wir über mehr als 400 Miniaplikatoren von MECAL, TE und MOLEX, die eine korrekte Crimpqualität garantieren.",
      "Hydraulische Pressen",
      "Umreifungsmaschinen",
      "Schneidemaschinen für Abdeckungen",
      "Maschinen und Anlagen zum Schrumpfen von Schrumpfschläuchen mit Infrarotstrahlung",
      "und viele Andere…",
    ],
    FR: [
      "Imprimantes et applicateurs de scellés pour les machines KOMAX et SCHLEUNIGER",
      "Imprimantes hors ligne pour l'impression spécialisée",
      "Machines à souder par ultrasons Schunk",
      "Dénudeurs de câbles",
      "Machines automatiques Schleuniger pour l'usinage de câbles spéciaux et multiconducteurs",
      "Presses excentriques pour le serrage de terminaux spéciaux",
      "Des miniaplicateurs spécialisés et adaptés au serrage des connecteurs que nous utilisons. Nous disposons actuellement de plus de 400 miniaplicateurs de MECAL, TE et MOLEX, qui garantissent une qualité de sertissage correcte.",
      "Presses hydrauliques",
      "Machines à cercler",
      "Machines à couper les gaines ",
      "Machines et équipements pour la rétraction d'enveloppes rétractables par rayonnement infrarouge",
      "et bien plus encore …",
    ],
    EN: [
      "Printers and seal applicators for KOMAX and SCHLEUNIGER machines",
      "Offline printers for specialised printing",
      "Schunk ultrasonic welding machines",
      "Wire strippers",
      "Schleuniger automatic machines for processing special and multicore cables",
      "Eccentric presses for crimping special terminals",
      "Specialised miniaplicators, adapted to the crimping of the connectors we use. We currently have more than 400 miniaplicators produced by MECAL, TE and MOLEX, which guarantee proper crimping quality.",
      "Hydraulic presses",
      "Strapping machines",
      "Cover cutting machines",
      "Machines and equipment for shrink wrapping with infrared radiation",
      "and many others ...",
    ],
    IT: [
      "Stampanti e applicatori di guarnizioni per macchine KOMAX e SCHLEUNIGER",
      "Stampanti offline per la stampa specializzata",
      "Macchine per le saldature con ultrasuoni Schunk ",
      "Macchine per twistare i cavi ",
      "Macchine automatiche di tipo Schleuniger per la lavorazione dei cavi specifici e multipolari",
      "presse eccentriche per aggraffatura dei terminali specifici",
      "Mini crimper specializzati e  adattati ai connettori che utilizziamo. Attualmente disponiamo di oltre 400 mini crimper di MECAL, TE e MOLEX che garantiscono la qualità dell’aggraffatura corretta ",
      "Presse idrauliche ",
      "Macchine per nastratura",
      "Macchine per taglio dei cover ",
      "Macchine ed apparecchi per fonare le guaine termorestringenti attraverso la radiazione infrarossa ",
      "e molto di più...",
    ],
  },
];
