// import React from "react"

export const color = {
  lightWhite: "#F0F0F0",
  green1: "#3F6B18",
  green2: "#154D1E",
  green3: "#2A5C1B",
  black: "#000000",
  white: "#fff",
  gradient:
    "linear-gradient(90deg, rgba(42,92,27,1) 0%, rgba(21,77,30,1) 35%, rgba(63,107,24,1) 100%);",
  gradientLinear: "rgb(42,92,27)",
};
// #15401E
// #154D1E
// #003E21

// https://colorkit.io/ we should try it ..

// lightWhite: "#F0F0F0",
// green1: "#098B54",
// green2: "#067242",
// green3: "#003E21",
// black: "#000000",
// white: "#fff",

export default color;
