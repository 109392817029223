import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import image from "../../assets/mp4/desktop.mp4";
import { Grid } from "@material-ui/core";
import color from "../../assets/styles/colors";
import { TypeAnimation } from "react-type-animation";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import img1 from "../../assets/jpeg/banerCarousel/16_9/1.jpg";
import img2 from "../../assets/jpeg/banerCarousel/16_9/2.jpg";
import img3 from "../../assets/jpeg/banerCarousel/16_9/5.jpg";
import img4 from "../../assets/jpeg/banerCarousel/16_9/4.jpg";
import img5 from "../../assets/jpeg/production/machinePark/crimp4.jpg";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {},
  imgBanerBackground: {
    width: "100%",

    pointerEvents: " none",
    objectFit: "cover",
    zIndex: "-1",
    top: "64px",
    marginBottom: "64px",

    position: "relative",
    [theme.breakpoints.only("xl")]: {
      height: "650px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "450px",
      // height: "625px",
    },
    [theme.breakpoints.down("md")]: {
      height: "460px",
      // height: "625px",
      margin: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      height: "270px",
      margin: "unset",
    },
    [theme.breakpoints.only("xs")]: {
      height: "260px",
    },
  },
  content: {
    position: "absolute",

    backgroundColor: "white",
    opacity: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      padding: "unset",
      height: "130px !important",
      top: "211px",
      display: "none",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100% !important",
      padding: "unset",
      height: "130px !important",
      top: "211px !important",
    },
    [theme.breakpoints.only("md")]: {
      padding: "unset",
      height: "130px !important",
      top: "210px !important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "440px",
      top: "304px !important",
    },
    [theme.breakpoints.up("xl")]: {
      top: "504px !important",
    },

    height: "210px",
    color: color.green3,
    zIndex: 1,

    padding: "20px",
  },
  contentMobile: {
    backgroundColor: "white",
    opacity: "80%",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      padding: "20px",
      marginTop: "20px",
      marginBottom: "20px",
      height: "130px !important",
      top: "211px",
    },

    height: "210px",
    color: color.green3,
    zIndex: 1,
  },
  banerText: {
    marginLeft: "40px",
    zIndex: 2,
    display: "flex",
    top: "50%",
    transform: "translateY(-50%)",
    position: "absolute",
    lineHeight: "0.5",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      display: "unset",
    },
    "&>p>b": {
      fontSize: "60px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    "&>span": {
      fontSize: "30px",
      marginLeft: "40px",
      fontWeight: "400",
    },
  },
  hrLine: {
    color: color.green1,
    width: "100px",
    float: "left",
    border: `6px solid ${color.green3} `,
    marginLeft: "40px",
  },
  banerTextMobile: {
    marginTop: "20px",
    lineHeight: "0.5",
    "&>p>b": {
      fontSize: "60px",
    },
    "&>span": {
      fontWeight: "400",
    },
  },
}));

const BanerCarousel = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid className={classes.paper}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          className={classes.content}
        >
          <Grid item>
            <div className={classes.banerText}>
              <p>
                <b>Connection</b> <br />
                <br />
                <br />
                <br />
                <TypeAnimation
                  sequence={["made with Trust", 1000]}
                  wrapper="span"
                  speed={10}
                  style={{ fontSize: "2em", display: "inline-block" }}
                  repeat={Infinity}
                />
              </p>
            </div>
            <Grid xs={12} item>
              <hr className={classes.hrLine} />
            </Grid>
          </Grid>
        </Grid>
        {/* https://www.npmjs.com/package/react-responsive-carousel read more. */}
        <Carousel
          showIndicators={false}
          infiniteLoop
          autoPlay
          showThumbs={false}
          interval={5000}
          transitionTime={1500}
          autoFocus
        >
          <div>
            <img
              src={img1}
              // loading="eager"
              // decoding="async"
              alt=""
              className={classes.imgBanerBackground}
            />
          </div>
          <div>
            <img src={img2} alt="" className={classes.imgBanerBackground} />
          </div>
          <div>
            <img src={img3} alt="" className={classes.imgBanerBackground} />
          </div>
          <div>
            <img src={img4} alt="" className={classes.imgBanerBackground} />
          </div>
          <div>
            <img src={img5} alt="" className={classes.imgBanerBackground} />
          </div>
        </Carousel>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        className={classes.contentMobile}
      >
        <Grid item>
          <div className={classes.banerTextMobile}>
            <p>
              <b>Connection</b> <br />
              <br />
              <br />
              <br />
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "made with Trust",
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={10}
                style={{ fontSize: "2em", display: "inline-block" }}
                repeat={Infinity}
              />
            </p>
          </div>
          <Grid xs={12} item>
            <hr className={classes.hrLine} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BanerCarousel;
