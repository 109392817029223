export const translations = [
  {
    ID: 1,
    PL: "Elektronika na potrzeby klienta",
    DE: "Kundenspezifische Elektronik",
    FR: "Électronique personnalisée",
    EN: "Customised electronics",
    IT: "Elettronica personalizzata",
  },
  {
    ID: 2,
    PL: "Projektujemy urządzenia elektroniczne do maszyn i pojazdów w oparciu o nowoczesne mikrokontrolery. Oferujemy między innymi: zestawy wskaźników, systemy sterowania do maszyn rolniczych i budowlanych oraz sterowniki i układy elektroniczne według specyfikacji i potrzeb klienta. Wieloletnie doświadczenie pozwala nam na opracowywanie wysoce skomplikowanych i bezawaryjnych systemów. Jesteśmy w stanie rozwijać i zmieniać funkcjonalność produkowanych urządzeń, dostosowując się do wymagań Klientów",
    DE: "Wir entwickeln elektronische Geräte für Maschinen und Fahrzeuge, die auf modernen Mikrocontrollern basieren. Wir bieten unter anderem an: Anzeigegeräte, Steuerungen für Land- und Baumaschinen sowie Steuerungen und elektronische Systeme nach Kundenwunsch und -bedarf. Unsere langjährige Erfahrung ermöglicht es uns, hochkomplexe und fehlerfreie Systeme zu entwickeln. Wir sind in der Lage, die Funktionalität der von uns hergestellten Geräte zu entwickeln und zu verändern, um sie an die Kundenanforderungen anzupassen.",
    FR: "Nous créons des produits électroniques pour les machines et les véhicules sur la base de microcontrôleurs modernes. Nous proposons notamment des kits d'affichage, des systèmes de contrôle pour les machines agricoles et de construction, ainsi que des contrôleurs et des systèmes électroniques selon les spécifications et les besoins du client. Nos nombreuses années d'expérience nous permettent de développer des systèmes très complexes et sans faille. Nous sommes en mesure de développer et de modifier la fonctionnalité des équipements que nous produisons, en nous adaptant aux exigences des clients",
    EN: "We design electronic devices for machines and vehicles based on modern microcontrollers. Among many other products, we offer: instrument clusters, control systems for agricultural and construction machinery, as well as controllers and electronic systems according to customer specifications and needs. Many years of experience allow us to develop highly complex and fault-free systems. We are able to develop and change the functionality of the equipment we produce, adapting to customer requirements.",
    IT: "Progettiamo dispositivi elettronici per macchine e veicoli basati su moderni microcontrollori. Offriamo, tra l'altro, kit di visualizzazione, sistemi di controllo per macchine agricole e da costruzione, nonché controllori e sistemi elettronici in base alle specifiche e alle esigenze dei clienti. Molti anni di esperienza ci permettono di sviluppare sistemi altamente complessi e privi di errori. Siamo in grado di sviluppare e modificare le funzionalità delle apparecchiature che produciamo, adattandole alle esigenze dei clienti.",
    ZMIANY: "ZMIANY",
  },
  {
    ID: 3,
    PL: "Projekty",
    DE: "Projekte",
    FR: "Projets",
    EN: "Designs",
    IT: "Progetti",
  },
  {
    ID: 4,
    PL: [
      "Zestawy oraz wszelkie inne urządzenia wykonujemy wg wymagań elektrycznych oraz graficznych klienta.",
      "Klient może wybrać jeden z naszych wcześniej przygotowanych projektów graficznych urządzenia, możemy również wspólnie stworzyć nowy indywidualny, wykorzystując pomysł i potrzebę Klienta.",
      "zespołem konstrukcyjnym elektroniki,",
      "Stale rozszerzamy ofertę wykonań niekatalogowych, ukierunkowanych na indywidualne wymagania naszych Klientów.",
    ],
    DE: [
      "Wir stellen die Anzeigegeräte und alle anderen Ausrüstungen nach den elektrischen und grafischen Anforderungen des Kunden her.",
      "Sie können gerne eins der von uns vorher erstellten grafischen Projekten des Gerätes wählen, oder wir können auch zusammen ein komplett neues, individuelles Layout gestalten, indem wir eigene Idee des Kunden und deren Bedarf ausnutzen.",
      "Team für die Elektronikkonstruktion",
      "Wir erweitern ständig das Angebot von Nicht-Katalog-Ausführungen, die auf die individuellen Anforderungen unserer Kunden ausgerichtet sind.",
    ],
    FR: [
      "Nous fabriquons les ensembles et tous les autres équipements en fonction des exigences électriques et graphiques du client.",
      "Le client peut choisir l'une de nos conceptions graphiques préconçues pour l'appareil, ou nous pouvons en créer une nouvelle ensemble, en utilisant l'idée et le besoin du client.",
      "l'équipe de construction électronique,",
      "Nous élargissons continuellement notre gamme de produits hors catalogue pour répondre aux besoins individuels de nos clients.",
    ],
    EN: [
      "We produce clusters and all other devices according to electrical and graphic specification of the customer.",
      "Our customers can select one of our earlier elaborated graphic designs of the device or we can also create together a new individual design applying customers' ideas and needs.",
      "the electronics construction team,",
      "We are constantly expanding the offer of non-catalog products, focusing at individual requirements of our Customers.",
    ],
    IT: [
      "Realizziamo i set e tutte le altre attrezzature in base alle esigenze elettriche e grafiche del cliente.",
      "Il Cliente può scegliere uno dei nostri progetti di dispositivi già realizzati o insieme possiamo crearne nuovo ed individuale utilizzando idee e necessità del Cliente.",
      "Reparto tecnico di elettronica",
      "Stiamo costantemente ampliando l'offerta di prodotti non standard, orientati alle esigenze individuali dei nostri Clienti.",
    ],
  },
  {
    ID: 8,
    PL: "Wykonanie i funkcjonalność",
    DE: "Ausführung und Funktionalität",
    FR: "Performance et fonctionnalité",
    EN: "Production and functionality",
    IT: "Realizzazione e funzionalità",
  },
  {
    ID: 9,
    PL: [
      "Oferujemy produkty przeznaczone do pracy w trudnych warunkach terenowych odporne na kurz, brud oraz warunki atmosferyczne. Gwarantujemy odporność na możliwie dużą ilość błędów niezależnych od urządzenia, związanych z wadliwym działaniem lub użytkowaniem urządzeń zewnętrznych, z którymi współpracuje zestaw wskaźników,",
      "technologia wykonania oparta jest o komponenty typu SMD. przeznaczone do użytku w automotive oraz przemyśle ciężkim,",
      "w zestawach wskaźników stosujemy silniki krokowe, które umożliwiają szeroko pojętą parametryzację wskazań z zastosowaniem nieliniowej skali. Są one odporne na wstrząsy, zanieczyszczenia i inne czynniki zewnętrzne,",
      "Wykorzystanie magistrali CAN umożliwia niezawodną komunikację z innymi urządzeniami. Otrzymane dane najczęściej prezentowane są na graficznych wyświetlaczach LCD,",
      "zestawy posiadają złącza serwisowe za pomocą których, poza diagnostyką, można zaprogramować urządzenie w siedzibie klienta lub w punkcie serwisowym,",
      "Nasze urządzenia pozwalają klientowi na samodzielną zmianę oprogramowania. Dzięki temu w łatwy i prosty sposób można wykorzystać je w nowych modelach maszyn. Aktualizacje oprogramowania przesyłane są do klienta w formie elektronicznej.",
    ],
    DE: [
      "Wir bieten Produkte an, die gegen Staub, Schmutz und Witterungseinflüsse resistent sind. Wir garantieren die Beständigkeit gegen möglichst viele geräteunabhängige Fehler, die mit der Fehlfunktion oder der Verwendung externer Geräte zusammenhängen, mit denen das Anzeigeset zusammenarbeitet,",
      "Die Fertigungstechnologie basiert auf SMD-Bauteilen und ist für den Einsatz in der Automobil- und Schwerindustrie vorgesehen,",
      "In Anzeigegeräten setzen wir Schrittmotoren ein, die eine breit begriffene Parametrisierung von Anzeigen mit Anwendung von dem nichtlinearen Maßstab ermöglichen. Die sind gegen Stöße, Verschmutzung und andere externe Faktoren widerstandsfähig, ",
      "Das CAN-Modul ermöglicht es, sich zuverlässig mit anderen Anlagen zu kommunizieren. Die erhaltenen Daten werden am meisten auf dem grafischen LCD-Display dargestellt.  ",
      "Die Kombiinstrumente verfügen über Servicestecker, durch welche das Gerät nicht nur diagnostiziert, sondern auch im Sitz des Kunden oder im Servicepunkt programmiert werden kann,",
      "Unsere Geräte erlauben es dem Kunden, die Software selbst zu ändern. Das macht den Einsatz auf neuen Maschinenmodellen leicht und einfach. Software-Updates werden dem Kunden elektronisch zugesandt.",
    ],
    FR: [
      "Nous proposons des produits conçus pour fonctionner dans des conditions difficiles, résistants à la poussière, à la saleté et aux intempéries. Nous garantissons la résistance au plus grand nombre possible d'erreurs indépendantes de l'appareil, liées au mauvais fonctionnement ou à l'utilisation d'appareils externes avec lesquels l'ensemble d'indicateurs coopère,",
      "La technologie de fabrication est basée sur des composants de type SMD, destinés à être utilisés dans l'industrie automobile et l'industrie lourde,",
      "Nous utilisons des moteurs pas à pas dans les ensembles d'indicateurs, ce qui permet un paramétrage étendu des indications à l'aide d'une échelle non linéaire. Ils sont résistants aux chocs, à la contamination et à d'autres facteurs externes,",
      "L'utilisation du bus CAN permet une communication fiable avec d'autres appareils. Les données reçues sont généralement présentées sur des écrans graphiques LCD,",
      "Les kits sont dotés de connecteurs de service qui permettent, outre le diagnostic, de programmer l'unité dans les locaux du client ou dans un centre de service,",
      "Nos appareils permettent au client de modifier lui-même le logiciel. Ils sont donc faciles à utiliser sur les nouveaux modèles de machines. Les mises à jour du logiciel sont envoyées au client par voie électronique.",
    ],
    EN: [
      "We offer products designed to operate in harsh field conditions resistant to dust, dirt and bad weather and guarantee resistance to maximum number of possible errors independent of the device, but related to the default operations or application of external devices cooperating with the instrument clusters,",
      "the technology is based on SMD-type components, designed for use in automotive and heavy industry,",
      "in our instrument clusters we use stepper motors that enable the broadly understood parameterization of indications on a non-linear scale. They are resistant to shock, pollution and other external factors,",
      "CAN module allows reliable communication with other devices. The obtained data are most frequently presented on graphic LCD screens,",
      "the clusters are equipped with service connectors which can be used for diagnostic or programming purposes in the customer’s seat, or a service centre,",
      "Our devices allow the customer to change the software themselves. This makes them easy and simple to use on new machine models. Software updates are sent to the customer electronically.",
    ],
    IT: [
      "Offriamo prodotti progettati per operare in condizioni difficili, resistenti alla polvere, alla sporcizia e agli agenti atmosferici. Garantiamo la resistenza al maggior numero possibile di errori indipendenti dal dispositivo, legati al malfunzionamento o all'uso di dispositivi esterni con cui il set di indicatori coopera",
      "La tecnologia di produzione si basa su componenti SMD da utilizzare nei settori automobilistico e dell'industria pesante,",
      "Nei kit indicatori applichiamo motori passo-passo che permettono di parametrizzare gli indicatori con l’applicazione sulla scala lineare. Sono resistenti alle scosse, o soggetti da altre condizioni esterne ",
      "L’applicazione del CAN permette di comunicazione affidabile con altri dispositivi. I dati ricevuti vengono presentati più spesso sui display LCD",
      "I quadri sono dotati di connettori di servizio attraverso i quali, oltre alla diagnostica è possibile programmare il dispositivo nella sede del Cliente oppure nelle officine",
      "I nostri dispositivi consentono ai clienti di modificare autonomamente il software. Sono quindi facili da usare su nuovi modelli di macchine. Gli aggiornamenti del software vengono inviati al cliente per via elettronica.",
    ],
  },
  {
    ID: 15,
    PL: "Zastosowanie",
    DE: "Die Anwendung",
    FR: "Application",
    EN: "Application",
    IT: "Applicazione",
  },
  {
    ID: 16,
    PL: "Przykłady zastosowania naszych wyrobów:",
    DE: "Beispiele für den Einsatz unserer Produkte:",
    FR: "Exemples d'utilisation de nos produits :",
    EN: "Examples of the application of our products:",
    IT: "Esempi di utilizzo dei nostri prodotti:",
  },
  {
    ID: 17,
    PL: [
      "Panele kontrolne do maszyn rolniczych, układy sterowania hydrauliką do maszyn budowlanych i rolniczych, rozdzielnie i układy automatyki do zabudów samochodów specjalistycznych, wyświetlacze parametrów maszyn rolniczych i pojazdów ciężkich, systemy sterowania zabudowy ambulansów, kompleksowe systemy sterowania maszyn rolniczych i uniwersalne programowalne sterowniki, piloty do sterowania urządzeń wykorzystanych w pojazdach, zestawy wskaźników do pojazdów spalinowych elektrycznych",
    ],
    DE: [
      "Schalttafeln für Landmaschinen, hydraulische Steuerungen für Bau- und Landmaschinen, Schalt- und Automatisierungssysteme für Spezialfahrzeugaufbauten, Parameteranzeigen für Landmaschinen und Schwerlastfahrzeuge, Steuerungen für Krankenwagenaufbauten, komplexe Steuerungen für Landmaschinen und universelle speicherprogrammierbare Steuerungen, Fernbedienungen für Fahrzeugausrüstungen, Anzeigesätze für elektrische Verbrennungsfahrzeuge.",
    ],
    FR: [
      "Tableaux de contrôle pour machines agricoles, systèmes de commande hydraulique pour machines de construction et agricoles, appareillage de commutation et systèmes d'automatisation pour carrosseries spécialisées, afficheurs de paramètres pour machines agricoles et véhicules lourds, systèmes de commande pour carrosseries d'ambulances, systèmes de commande complexes pour machines agricoles et contrôleurs programmables universels, télécommandes pour la commande d'équipements utilisés dans les véhicules, ensembles d'indicateurs pour véhicules à combustion électrique.",
    ],
    EN: [
      "Control panels for agricultural machinery, hydraulic control systems for construction and agricultural machinery, switchboards and automation systems for specialised vehicles, parameter displays for agricultural and heavy-duty vehicles, control systems for ambulances, complex control systems for agricultural machinery and universal programmable controllers, remote controls for vehicle equipment, instrument clusters for electric combustion vehicles",
    ],
    IT: [
      "Pannelli di controllo per macchine agricole, sistemi di controllo idraulico per macchine edili e agricole, quadri elettrici e sistemi di automazione per carrozzerie specializzate, visualizzatori di parametri per macchine agricole e veicoli pesanti, sistemi di controllo per carrozzerie di ambulanze, sistemi di controllo complessi per macchine agricole e controllori universali programmabili, telecomandi per attrezzature utilizzate nei veicoli, gruppi di indicatori per veicoli a combustione elettrica.",
    ],
  },
  {
    PL: "Oferta",
    DE: "Angebot",
    FR: "Offre",
    EN: "Offer",
    IT: "Offerta",
  },
  {
    PL: "Programowanie i sprzedaż zestawów wskaźników MTA",
    DE: "Programmierung und Verkauf von MTA-Anzeigegeräten.",
    FR: "Programmation et vente de sets d'indicateurs MTA",
    EN: "MTA Dashboard programming and distribution",
    IT: "Programmazione e vendita di kit di indicatori MTA",
  },
  {
    PL: [
      "Eltem jest oficjalnym dystrybutorem zestawów wskaźników firmy MTA",
      "Wybór z wielu typów wskaźników –okrągłe, kwadratowe, prostokątne, owalne",
      "Od bardzo małych  (107 mm zewnętrznej średnicy) do wysokiej klasy wyświetlaczy 310x215 mm",
      "Zasilanie 12V oraz 24V",
      "Przygotowane pod pojazdy Elektryczne – zawierające w standardzie kontrolki i ikony dla pojazdów elektrycznych",
    ],
    DE: [
      "Eltem ist der offizielle Vertreiber von MTA-Anzeigegeräten.",
      "Auswahl aus vielen Arten von Indikatoren - rund, quadratisch, rechteckig, oval",
      "Von sehr kleinen (107 mm Außendurchmesser) bis hin zu High-End-Displays mit 310x215 mm",
      "Stromversorgung 12V und 24V",
      "Vorbereitet für Elektrofahrzeuge - mit Bedienelementen und Symbolen für Elektrofahrzeuge als Standard",
    ],
    FR: [
      "Eltem est le distributeur officiel des sets d'indicateurs MTA.",
      "Choix parmi de nombreux types d'indicateurs - ronds, carrés, rectangulaires, ovales",
      "Des écrans très petits (107 mm de diamètre externe) aux écrans haut de gamme de 310 x 215 mm",
      "Alimentation 12V et 24V",
      "Préparé pour les véhicules électriques - comprenant des boutons de commande et des icônes pour les véhicules électriques en standard",
    ],
    EN: [
      "Eltem is official representative of MTA regarding Customizable Solution ",
      "Selection of various types – Round, Square, Rectangular, Oval and Ovalised",
      "From Extremely small (107 mm outer diameter) to the high-end 310x215 mm display",
      "Both 12V and 24V power supply",
      "Ready for Electric Vehicles – implemented indicators specific for EVs",
    ],
    IT: [
      "Eltem è il distributore ufficiale dei kit di indicatori MTA",
      "Scelta tra diversi tipi di indicatori: rotondi, quadrati, rettangolari, ovali",
      "Da display molto piccoli (107 mm di diametro esterno) a display di gamma alta da 310x215 mm",
      "Alimentazione a 12V e 24V",
      "Predisposto per i veicoli elettrici - include controlli e icone per i veicoli elettrici come standard",
    ],
  },
];
