import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import color from "../../assets/styles/colors";
import { Button, Grid, Typography } from "@material-ui/core";
import Link from "@mui/material/Link";
import PageWrapper from "../PageWrapper";
import banerImage from "../../assets/jpeg/baners/banerHistory.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  navlink: {
    textDecoration: "none",
    marginBottom: "30px",
  },
  button: {
    width: "140px",
    margin: "50px",
  },
  container: {
    marginTop: "100px",
    marginBottom: "100px",
  },
  errorH1: {
    fontWeight: "bold",

    color: color.green1,
  },
  zero: {
    color: color.green1,
  },
  unactiveButton: {
    marginTop: "20px",
    border: "solid 2px",
    borderColor: color.green1,
    width: "139px",
    height: "48px",
    borderRadius: 25,
    color: color.green1,
    padding: "0 25px",
    fontSize: 16,
    textTransform: "none",
    margin: "0px",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: color.green3,
      color: color.white,
      borderColor: color.white,

      fontWeight: "600",
    },
  },
}));

const Page404 = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={banerImage}
        naviChild2="Strona nie została znaleziona (404)"
      >
        <Grid
          className={classes.container}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid align="center" item xs={12}>
            <Typography className={classes.errorH1} variant="h1">
              404
            </Typography>
            <Typography style={{ padding: "20px" }} variant="h4">
              Strona nie została znaleziona{" "}
            </Typography>
            <Link className={classes.navlink} href="/">
              <Button className={classes.unactiveButton}>Powrót</Button>
            </Link>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default Page404;
