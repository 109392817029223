import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/baners/banerCertificates.jpg";
import { Grid, Typography } from "@material-ui/core";
import cert9001 from "../../../assets/svg/sgs9001.svg";
import cert14001 from "../../../assets/svg/sgs14001.svg";
import color from "../../../assets/styles/colors";
import pdf9001 from "../../../assets/pdf/ELTEM_9001_certificate_en_pl_de_2023_2.pdf";
import pdf14001 from "../../../assets/pdf/ELTEM_14001_certificate_en_pl_de_2023_2.pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import translationsSwitch from "../../../translations";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "",
    width: "auto",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    margin: "20px",
  },
  text: {
    "&>b": {
      color: color.green3,
    },
  },
  grid: {
    padding: "20px",
  },
  link: {
    marginTop: "20px",
    color: color.green3,
    "&:hover": {
      color: color.green2,
    },
  },
  icon: {
    marginRight: "10px",
  },
  subtitle: {
    color: color.green3,
  },
}));

const Certificates = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const data = translationsSwitch("certificates", lang);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title={data[0].text}
        naviChild1={data[9].text}
        naviChild2={data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              {data[1].text}
            </Typography>
          </Grid>
          <Grid className={classes.grid} align="center" item xs={12} lg={12}>
            <Typography
              className={classes.subtitle}
              variant="h5"
              align="center"
            >
              {data[2].text}
            </Typography>
            <Typography>{data[3].text}</Typography>
            <br />
            <a
              rel="noreferrer"
              className={classes.link}
              href={pdf9001}
              target="_blank"
            >
              <PictureAsPdfIcon className={classes.icon} />
              ISO 9001:2015 PL EN DE
            </a>
            <br />
            <a
              rel="noreferrer"
              className={classes.link}
              href={pdf14001}
              target="_blank"
            >
              <PictureAsPdfIcon className={classes.icon} />
              ISO 14001:2015 PL EN DE
            </a>
          </Grid>
          <Grid className={classes.grid} align="center" item xs={12} md={6}>
            <img className={classes.image} src={cert9001} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography className={classes.text}>
              <b>{data[4].text}</b> {data[6].text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
          <Grid className={classes.grid} align="center" item xs={12} md={6}>
            <img className={classes.image} src={cert14001} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography className={classes.text}>
              <b>{data[5].text}</b> {data[7].text}{" "}
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={12} lg={10}>
            <Typography align="center">{data[8].text}</Typography>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default Certificates;
