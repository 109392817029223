import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import color from "../../assets/styles/colors";
import image1 from "../../assets/jpeg/aboutUs/image1.jpg";
import image2 from "../../assets/jpeg/aboutUs/image4v2.png";

// import Wave from "../../assets/svg/wave.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  container: {
    padding: "0px",
    marginTop: "-6px",
  },
  headerText: {
    fontWeight: "400!important",
    fontSize: "40px !important",
    fontFamily: `'Rajdhani', sans-serif !important`,
    marginTop: "30px !important",
    color: color.white,
    padding: "10px",
  },
  normalText: {
    fontSize: "18px",
    padding: "15px",
  },
  image: {
    // objectFit: "cover",
    width: "100%",
    // height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  block: {
    background: color.gradientLinear,
    padding: "0px !important",
  },
  subContainer: {
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  wave: {
    marginTop: "-6%",
    zIndex: "-1",
    position: "relative",
    width: "100%",
  },
  hrLine: {
    color: color.green3,
    margin: "20px 0px 20px 0px",
  },
}));

const WhatWeDo = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" id="container">
        <Grid
          className={classes.container}
          container
          direction="row"
          justifyContent="space-around"
          alignContent="flex-start"
        >
          <Grid className={classes.block} item xs={12}>
            <Grid item>
              <Typography
                style={{ textAlign: "center" }}
                variant="h4"
                className={classes.headerText}
              >
                {props.dataHomePage[0].text}
              </Typography>
              <br />
              <Typography
                style={{ color: color.white, paddingBottom: "40px" }}
                align="center"
                className={classes.normalText}
              >
                {props.dataHomePage[1].text}
                <br />
                {props.dataHomePage[18].text}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={11} lg={8}>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                className={classes.subContainer}
              >
                <Grid item xs={12} md={5} lg={6}>
                  <Typography
                    style={{
                      color: color.green3,
                      paddingTop: "0px !important",
                    }}
                    variant="h4"
                    className={classes.headerText}
                  >
                    {props.dataHomePage[2].text}
                  </Typography>
                  <Typography className={classes.normalText}>
                    {props.dataHomePage[3].text}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                  <img className={classes.image} src={image1} alt="" />
                </Grid>
                {/* <Grid item xs={12}>
                  <hr className={classes.hrLine} />
                </Grid> */}
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  style={{
                    backgroundColor: "rgb(248, 248, 249)",
                    marginTop: "20px",
                  }}
                >
                  <Grid item xs={12} md={5} lg={5}>
                    <img className={classes.image} src={image2} alt="" />
                  </Grid>
                  <Grid item xs={12} md={5} lg={6}>
                    <Typography
                      style={{
                        color: color.green3,
                      }}
                      variant="h4"
                      className={classes.headerText}
                    >
                      {props.dataHomePage[4].text}
                    </Typography>
                    <Typography className={classes.normalText}>
                      {props.dataHomePage[5].text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default WhatWeDo;
