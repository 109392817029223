import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
// import classes from "./styles/counter.module.scss";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import icon1 from "../../assets/svg/counter/1co.svg";
import icon2 from "../../assets/svg/counter/2co.svg";
import icon3 from "../../assets/svg/counter/3co.svg";
import icon4 from "../../assets/svg/counter/4co.svg";

import bg from "../../assets/jpeg/counter/bg.jpg";

import { makeStyles } from "@material-ui/core/styles";
import color from "../../assets/styles/colors";
import { Typography } from "@material-ui/core";
// import WorkWithUs from "./WorkWithUs";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  ContainerWrapper: {
    minHeight: "60px",
    width: "100%",
    backgroundColor: color.white,
    padding: "40px 0px 0px 0px",
    backgroundImage: `url('${bg}')`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  },

  headerText: {
    fontSize: "45px",
    fontWeight: "500",
    color: color.green3,
  },
  descText: {
    fontSize: "18px",
    color: color.green3,
    marginBottom: "30px",
  },
  title: {
    fontSize: "46px",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "1.5",
    color: color.green3,
  },
  titleContainer: {
    // background: "rgba(255, 255, 255, 0.8)",
  },
  counterIcon: {
    marginTop: "20px",
    width: "80px",
  },
  counterInsideGrid: {
    // background: "rgba(255, 255, 255, 0.8)",
  },
  mainContainer: {
    background: "rgba(255, 255, 255, 0.8)",
    padding: "20px 0px 20px 0px",
    borderRadius: "20px",
    marginBottom: "40px",
  },
  numberCounter: {
    fontSize: "60px",
    fontWeight: "600",
    color: color.green3,
    // display: "inline-block",
  },
  numberTitle: {
    fontSize: "22px",
    maxWidth: "260px",
    textAlign: "center",
    color: color.green3,
  },
}));
class CounterUpScroll extends Component {
  state = {
    didViewCountUp: false,
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  render() {
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        offset={{
          top: 10,
        }}
        delayedCall
      >
        <div className={this.props.classes.numberCounter}>
          <CountUp
            separator=" "
            start={0}
            end={this.state.didViewCountUp ? this.props.end : 0}
            suffix={this.props.suffix}
            duration={3}
          />
        </div>
      </VisibilitySensor>
    );
  }
}

const Cloud = ({ classes, title }) => {
  return (
    <>
      <Grid className={classes.titleContainer} item align="center">
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
    </>
  );
};

const CounterNumber = ({ classes, icon, end, suffix, title1 }) => {
  return (
    <Grid item className={classes.counterGrid} xs={12} sm={6} lg={6}>
      <div>
        <img className={classes.counterIcon} src={icon} alt={title1} />
        <CounterUpScroll classes={classes} end={end} suffix={suffix} />
      </div>
      <Typography className={classes.numberTitle}>{title1}</Typography>
    </Grid>
  );
};

const CounterInside = ({ classes, data }) => {
  function monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  //production of one year is "37545" so in on
  const amountOfHarnesses =
    monthDiff(new Date(2023, 0), new Date()) * (37545 / 12);
  return (
    <>
      <Grid className={classes.counterInsideGrid}>
        <Typography className={classes.headerText}>{data[12].text}</Typography>
        <Typography className={classes.descText}>{data[13].text}</Typography>
        <Grid container direction="row" jusitfy="center">
          <CounterNumber
            end={new Date().getFullYear() - 1990}
            suffix={"+"}
            classes={classes}
            icon={icon1}
            title1={data[14].text}
          />
          <CounterNumber
            end={400}
            suffix={"+"}
            classes={classes}
            icon={icon2}
            title1={data[15].text}
          />
          <CounterNumber
            end={10500000 + amountOfHarnesses}
            suffix={"+"}
            classes={classes}
            icon={icon3}
            title1={data[16].text}
          />
          <CounterNumber
            end={9}
            suffix={"+"}
            classes={classes}
            icon={icon4}
            title1={data[17].text}
          />
        </Grid>
      </Grid>
    </>
  );
};

const Counter = (props) => {
  // console.log(props.dataHomePage);
  const classes = useStyles();
  return (
    <Grid
      className={classes.ContainerWrapper}
      container
      direction="row"
      alignItems="center"
    >
      <Grid align="center" item xs={12}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid className={classes.mainContainer} item xs={12} lg={6}>
            <Cloud title={props.dataHomePage[11].text} classes={classes} />
            <CounterInside data={props.dataHomePage} classes={classes} />
          </Grid>
        </Grid>
      </Grid>
      {/* <WorkWithUs /> */}
    </Grid>
  );
};

export default Counter;
