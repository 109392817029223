import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/aboutUs/image1.jpg";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/styles/colors";
import { Link, useParams } from "react-router-dom";
import enovaImg from "../../../assets/png/recruitment/enova.png";
import Data from "./DataCareer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },

  text: {
    "&>b": {
      color: color.green3,
    },
    marginBottom: "50px",
  },
  contentWork: {
    position: "relative",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: color.green3,
    top: "-50px",
  },
  titleWork: {
    fontWeight: "600",

    color: color.white,
  },
  containerWork: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  containerBox: {
    marginBottom: "20px",
  },
  imageWork: {
    height: "400px",
    objectFit: "cover",
    width: "100%",
    objectPosition: "center center",
  },
  Link: {
    textDecoration: "unset",
    color: color.white,
    "&:hover": {
      textDecoration: "underline !important",
    },
  },

  serviceLabelText: {
    backgroundColor: color.green3,
    color: color.white,
    fontSize: "18px",
    fontWeight: "700",
    textTransform: "uppercase",
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    transition: "1s",
    border: `2px solid ${color.green3} `,

    "&:hover": {
      textDecoration: "underline !important",
      cursor: "pointer",
      backgroundColor: color.white,
      color: color.green3,
      border: `2px solid ${color.green3} `,

      transition: "0.2s",
    },

    // marginLeft: "20px",
    // marginTop: "10px",
  },
  container: {
    overflow: "hidden",
    position: "",
    paddingBottom: "20px",
  },
  enovaImg: {},
}));

// const Data = {
//   jobs: [
//     {
//       id: 1,
//       title: "Monter wiązek elektrycznych",
//       url: "/career/",
//     },
//     {
//       id: 2,
//       title: "Operator automatów do cięcia i zaciskania",
//       url: "/career/",
//     },
//     {
//       id: 3,
//       title: "Technik utrzymania ruchu",
//       url: "/career/",
//     },
//     {
//       id: 4,
//       title: "Samodzielny specjalista kadrowo-płacowy",
//       url: "/career/",
//     },
//     {
//       id: 5,
//       title: "Elektronik Konstruktor",
//       url: "/career/",
//     },
//   ],
// };

function ConvertText(tekst) {
  // Zamienia wszystkie litery na małe
  tekst = tekst.toLowerCase();

  // Zamienia spacje na myślniki
  tekst = tekst.replace(/\s+/g, "-");

  return tekst;
}

const Work = (props) => {
  const params = useParams();
  return (
    <>
      <Grid item xs={12}>
        <Link
          className={props.classes.Link}
          to={
            "/" +
            params.lang +
            props.url +
            props.id +
            "/" +
            ConvertText(props.title)
          }
        >
          <Grid item className={props.classes.ServiceLabel}>
            <Typography className={props.classes.serviceLabelText}>
              {props.title}
            </Typography>
          </Grid>
        </Link>
      </Grid>
    </>
  );
};

const CareerList = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title="Dołącz do naszego zespołu!"
        naviChild1="Kariera"
        naviChild2="Oferty Pracy"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Typography align="center" className={classes.text}>
              <b> ELTEM Sp. z o.o </b>jeden z czołowych w Polsce producentów
              okablowań elektrycznych do ciągników, maszyn rolniczych, pojazdów
              specjalistycznych, a także zestawów wskaźników i nietypowych
              rozwiązań z zakresu elektroniki, dla odbiorców z rynku rolniczego
              i motoryzacyjnego, działający na rynkach Europy, w związku z
              dalszym rozwojem Spółki, poszukuje osób na stanowiska:
            </Typography>
          </Grid>

          {Data.jobs.map((item, index) => (
            <React.Fragment key={index}>
              <Work
                url={item.url}
                title={item.title}
                id={item.id}
                classes={classes}
                image={item.image}
              />
            </React.Fragment>
          ))}
          <Grid
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
            lg={4}
            item
            align="center"
          >
            <Link to="https://www.enova.pl/" target="_blank">
              <img className={classes.enovaImg} alt="enova" src={enovaImg} />
            </Link>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default CareerList;
