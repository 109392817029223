import { translations as dropMenuData } from "./elements/dropMenu";
import { translations as menuData } from "./elements/menu";
import { translations as homepage } from "./homepage/homepage";
import { translations as footer } from "./elements/footer";
import { translations as europeanUnion } from "./aboutUs/europeanUnion";
import { translations as certificates } from "./quality/certificates";
import { translations as electricalHarnesses } from "./offer/electricalHarnesses";
import { translations as electronic } from "./offer/electronic";
import { translations as qualityPolicy } from "./quality/qualityPolicy";
import { translations as groundCable } from "./offer/groundCable";
import { translations as indicatorSet } from "./offer/inficatorSet";
import { translations as componentsForSale } from "./offer/ComponentsForSale";
import { translations as machinePark } from "./production/MachinePark";
import { translations as qualityManagement } from "./quality/QualityManagement";
import { translations as contactUs } from "./contactUs/contactUs";
import { translations as industry } from "./production/Industry";

function getTranslationsData(data, lang) {
  //set data as table
  const jsonData = data.map((item) => ({
    ID: item.ID,
    text: item[lang],
  }));
  return jsonData;
}

function translationsSwitch(param, lang) {
  switch (param) {
    default:
      return console.log("error - no data or lang");
    case "menu":
      return getTranslationsData(menuData, lang);
    case "dropMenu":
      return getTranslationsData(dropMenuData, lang);
    case "homepage":
      return getTranslationsData(homepage, lang);
    case "footer":
      return getTranslationsData(footer, lang);
    case "europeanUnion":
      return getTranslationsData(europeanUnion, lang);
    case "certificates":
      return getTranslationsData(certificates, lang);
    case "electricalHarnesses":
      return getTranslationsData(electricalHarnesses, lang);
    case "electronic":
      return getTranslationsData(electronic, lang);
    case "qualitypolicy":
      return getTranslationsData(qualityPolicy, lang);
    case "groundCable":
      return getTranslationsData(groundCable, lang);
    case "indicatorSet":
      return getTranslationsData(indicatorSet, lang);
    case "forSale":
      return getTranslationsData(componentsForSale, lang);
    case "machinePark":
      return getTranslationsData(machinePark, lang);
    case "qualityManagement":
      return getTranslationsData(qualityManagement, lang);
    case "contactUs":
      return getTranslationsData(contactUs, lang);
    case "industry":
      return getTranslationsData(industry, lang);
  }
}

export default translationsSwitch;
