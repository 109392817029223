import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import industryBaner from "../../../assets/jpeg/baners/industry.jpg";
// import electricCar from "../../../assets/jpeg/production/industry/electric.jpg";
// import sensor from "../../../assets/jpeg/production/industry/sensor.jpg";
// import harvester from "../../../assets/jpeg/production/industry/harvester.jpg";

import color from "../../../assets/styles/colors";
import CircularNavigation from "./CircularNavigation";
import { useParams } from "react-router-dom";
import translationsSwitch from "../../../translations";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  imageIndustry: {
    objectFit: "cover",
    // width: "100%",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    // padding: "10px",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
}));

const Industry = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("industry", lang);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={industryBaner}
        title={Data[1].text}
        naviChild1={Data[0].text}
        naviChild2={Data[1].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              {Data[2].text}
            </Typography>
          </Grid>
          <CircularNavigation />
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default Industry;
