import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import { Grid, Typography } from "@material-ui/core";
import harness1 from "../../../assets/jpeg/harnesses/harness1.jpg";
import harness2 from "../../../assets/jpeg/harnesses/harness2.jpg";
import harness3 from "../../../assets/jpeg/harnesses/harness3.jpg";
import harness4 from "../../../assets/jpeg/harnesses/harness4.jpg";

import translationsSwitch from "../../../translations";

import banerBg from "../../../assets/jpeg/banerCarousel/1.webp";

import color from "../../../assets/styles/colors";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "400px",
    objectPosition: "center center",
    borderRadius: "5px",
    boxShadow: "rgba(3, 28, 106, 0.16) 0px 6px 20px",
  },
  image2: {
    width: "100%",
  },
  text: {
    fontSize: "18px",
    "&>b": {
      color: color.green3,
    },
    "&>i": {
      color: color.green3,
      fontWeight: "700",
    },
  },
  grid: {
    padding: "20px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
  },
}));

const ElectricalHarnesses = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("electricalHarnesses", lang);
  // console.log(Data);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={banerBg}
        title={Data[0].text}
        naviChild1={Data[13].text}
        naviChild2={Data[0].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <Typography align="center" className={classes.text}>
              {Data[1].text}
            </Typography>
          </Grid>
          <Grid style={{ backgroundColor: "rgb(248, 248, 249)" }} container>
            <Grid className={classes.grid} item xs={12} md={6}>
              <Typography variant="h4" className={classes.subtitle}>
                {Data[2].text}
              </Typography>

              {Data[3].text.map(function (data) {
                return (
                  <ul>
                    <li>{data}</li>
                  </ul>
                );
              })}
            </Grid>
            <Grid className={classes.grid} item xs={12} md={6}>
              <img className={classes.image} src={harness1} alt="" />
            </Grid>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={harness2} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography variant="h4" className={classes.subtitle}>
              {Data[4].text}
            </Typography>

            <ul>
              <li>{Data[5].text}</li>
            </ul>
          </Grid>
          <Grid style={{ backgroundColor: "rgb(248, 248, 249)" }} container>
            <Grid className={classes.grid} item xs={12} md={6}>
              <Typography className={classes.subtitle} variant="h4">
                {Data[6].text}
              </Typography>
              {Data[7].text}
              {Data[8].text.map(function (data) {
                return (
                  <ul>
                    <li>{data}</li>
                  </ul>
                );
              })}
            </Grid>
            <Grid className={classes.grid} item xs={12} md={6}>
              <img className={classes.image} src={harness3} alt="" />
            </Grid>
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <img className={classes.image} src={harness4} alt="" />
          </Grid>
          <Grid className={classes.grid} item xs={12} md={6}>
            <Typography variant="h4" className={classes.subtitle}>
              {Data[9].text}
            </Typography>
            <Typography>
              {Data[10].text}
              <br />
              <br />
              {Data[11].text}
            </Typography>
            {Data[12].text.map(function (data) {
              return (
                <ul>
                  <li>{data}</li>
                </ul>
              );
            })}
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default ElectricalHarnesses;
