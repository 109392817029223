import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import Data from "../../translations/elements/services";

import color from "../../assets/styles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  serviceContainer: {
    overflow: "hidden",
    height: "300px",
    position: "",
  },
  backgroundHolder: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    transform: "scale(1.0)",
    "&:hover": {
      transform: "scale(1.3)",
      textDecoration: "underline !important",
      cursor: "pointer",
    },
    transition: "all 1s",
    width: "100%",
    height: "100%",
  },
  serviceLabel: {
    height: "50px",
    width: "100%",
    bottom: "0",
    // opacity: "80%",
    zIndex: "1000",
    position: "sticky",
  },
  serviceLabelText: {
    color: color.white,
    fontSize: "18px",
    fontWeight: "700",
    textTransform: "uppercase",
    marginLeft: "20px",
    position: "relative",
    top: "12px",
    "&:hover": {
      textDecoration: "underline !important",
      cursor: "pointer",
    },

    // marginLeft: "20px",
    // marginTop: "10px",
  },
  headerText: {
    fontFamily: `'Rajdhani', sans-serif !important`,
    color: color.white,
    padding: "20px",
    fontWeight: "400!important",
    fontSize: "40px !important",
  },
  link: {
    color: color.white,
  },
  container: {
    backgroundColor: color.green3,

    paddingBottom: "100px",
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "0px",
    },
  },
}));

const Service = ({ classes, label, id, image, url }) => {
  const params = useParams();
  return (
    <Grid
      item
      className={classes.serviceContainer}
      xs={12}
      md={6}
      lg={6}
      xl={3}
    >
      <Link className={classes.link} to={"/" + params.lang + url}>
        <Grid
          item
          className={classes.backgroundHolder}
          style={{ backgroundImage: `url(${image})` }}
        />
        <Grid
          item
          style={
            id % 2 === 0
              ? { background: "rgba(42, 92, 27, 0.8)" }
              : { background: "rgba(63, 107, 24, 0.5)" }
          }
          className={classes.serviceLabel}
        >
          <Typography className={classes.serviceLabelText}> {label}</Typography>
        </Grid>
      </Link>
    </Grid>
  );
};

const Services = (props) => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.container}
        style={{}}
      >
        <Grid style={{ backgroundColor: color.green3 }} item xs={12} lg={10}>
          <Typography className={classes.headerText} align="center">
            {props.dataHomePage[6].text}
          </Typography>
          <Grid
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            container
          >
            {Data.services.map((item, index) => (
              <React.Fragment key={index}>
                <Service
                  image={item.image}
                  url={item.url}
                  label={item.label[`${lang}`]}
                  classes={classes}
                  id={item.id}
                />
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Services;
