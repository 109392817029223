import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import color from "../../assets/styles/colors";
import logo from "../../assets/svg/logo6_2.svg";
import ScrollUpButton from "react-scroll-up-button";
import { Link } from "react-router-dom";
// import ScrollTop from "./dynamic/ScrollToTopFab";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  container: {
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0),0px 4px 5px 0px rgba(0, 0, 0, 0.03),0px 1px 10px 0px rgba(0, 0, 0, 0)",
    backgroundColor: color.white,
    backgroundImage: "linear-gradient(222deg, #ffffff 0%, #f9f9f9 74%)",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  copyrightBar: {
    height: "35px",
    backgroundColor: color.green3 + "!important",

    paddingTop: "10px",
    "&>p": {
      color: color.white,
      marginLeft: "20px",
      fontSize: "12px",
      textAlign: "center",
      fontWeight: "300",
      "&>b": {
        fontWeight: "600",
      },
    },
  },
  subContainer: {
    // paddingTop: "20px",
    // paddingBottom: "20px",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0), 0px 4px 5px 0px rgba(0, 0, 0, 0.03), 0px 1px 10px 0px rgba(0, 0, 0, 0)",
    padding: "20px",
  },
  footerText: {
    fontSize: "14px",
    "&>b": {
      color: color.green3,
    },
  },
  logo: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "40px",
      marginBottom: "40px",
    },
    maxWidth: "300px",
  },
}));

const Footer = (dataFooter) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.subContainer}
        >
          <Grid item xs={12} sm={12} md={4}>
            <img className={classes.logo} src={logo} alt="Eltem" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.footerText}>
              {dataFooter.dataFooter[0].text}
            </Typography>
          </Grid>

          <Grid xs={12} item sm={6} md={2}>
            <Typography className={classes.footerText}>
              {dataFooter.dataFooter[1].text}
              <Link style={{ color: color.green3 }} to="/pl/cookies-policy">
                {dataFooter.dataFooter[2].text}
              </Link>
              <br />
              <Link style={{ color: color.green3 }} to="/pl/privacy-rights">
                {dataFooter.dataFooter[3].text}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.copyrightBar} xs={12}>
          <Typography>
            <b>ELTEM Sp. z o.o.</b> - All Rights Reserved
            <b>{" " + new Date().getFullYear()}</b>
          </Typography>
        </Grid>
        <ScrollUpButton
          StopPosition={0}
          ShowAtPosition={150}
          EasingType="easeOutCubic"
          AnimationDuration={500}
          ContainerClassName="ScrollUpButton__Container"
          TransitionClassName="ScrollUpButton__Toggled"
          style={{}}
          ToggledStyle={{}}
        />
      </Grid>
    </>
  );
};

export default Footer;
