import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    width: "100%",
    objectFit: "cover",
    // height: "340px !important",
    backgroundPosition: "center",
    marginTop: "64px",
    [theme.breakpoints.down("xl")]: {
      height: "500px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "460px",
    },
    [theme.breakpoints.down("md")]: {
      height: "460px",
      margin: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      height: "270px",
      marginTop: "55px",
      margin: "unset",
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "55px",
      height: "230px",
    },
  },
}));

const Baner = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item>
        <img className={classes.image} src={props.image} alt="" />
      </Grid>
    </React.Fragment>
  );
};

export default Baner;
