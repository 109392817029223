export const translations = [
  {
    ID: 1,
    PL: "HOME",
    DE: "Startseite",
    FR: "ACCUEIL",
    EN: "HOME",
    IT: "HOME",
  },
  {
    ID: 2,
    PL: "O Firmie",
    DE: "Über uns",
    FR: "À propos de l'entreprise",
    EN: "About us",
    IT: "Chi siamo",
  },
  {
    ID: 3,
    PL: "Oferta",
    DE: "Angebot",
    FR: "Offre",
    EN: "Offer",
    IT: "Offerta",
  },
  {
    ID: 4,
    PL: "Produkcja",
    DE: "Produktion",
    FR: "Production",
    EN: "Production",
    IT: "Produzione",
  },
  {
    ID: 5,
    PL: "Jakość",
    DE: "Qualität",
    FR: "Qualité",
    EN: "Quality",
    IT: "Qualità",
  },
  {
    ID: 6,
    PL: "Kariera",
    DE: "Karriere",
    FR: "Carrière",
    EN: "Career",
    IT: "Carriera ",
  },
  {
    ID: 7,
    PL: "Kontakt",
    DE: "Kontakt",
    FR: "Contact",
    EN: "Contact us",
    IT: "Contattarci",
  },
];
