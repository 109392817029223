import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/baners/contactUs.jpg";
import { Grid } from "@material-ui/core";
import color from "../../../assets/styles/colors";
import pdf9001 from "../../../assets/pdf/ELTEM_9001_certificate_en_pl_de_2023_2.pdf";
import pdf14001 from "../../../assets/pdf/ELTEM_14001_certificate_en_pl_de_2023_2.pdf";
import qualityPolicy from "../../../assets/pdf/Polityka_jakosci_ELTEM.pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormDoc from "../../../assets/doc/ELTEM_formularz_zgloszenia.doc";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useParams } from "react-router-dom";
import translationsSwitch from "../../../translations";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "",
    width: "auto",
    height: "200px",
    objectPosition: "center center",
    borderRadius: "5px",
    margin: "20px",
  },
  icon: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    "&>b": {
      color: color.green3,
      fontSize: "18px",
    },
  },
  grid: {
    padding: "20px",
    marginBottom: "20px",
  },
  table: {
    "&>li": {
      listStyleType: "none",
    },
    "&>li>b": {
      color: color.green3,
    },
    "&>li>a": {
      color: color.green3,
      marginLeft: "5px",
      "&:hover": {
        color: color.green1,
      },
    },
  },
  subtitle: {
    color: color.green3,
    fontWeight: "700",
    fontSize: "18px",
  },
  link: {
    marginTop: "20px",
    color: color.green3,
    "&:hover": {
      color: color.green2,
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const lang = useParams().lang.toUpperCase();
  const Data = translationsSwitch("contactUs", lang);
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title={Data[0].text}
        naviChild1={Data[1].text}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid className={classes.grid} item xs={12} lg={4}>
            <b className={classes.subtitle}>{Data[2].text} </b>
            <ul className={classes.table}>
              <li>
                <b>{Data[3].text}</b>
                ELTEM Sp. z o.o. <br />
                ul. SMOLUCHOWSKIEGO 3 <br />
                20-474 Lublin
              </li>
              <li>
                <b>{Data[4].text}</b> 946-21-22-162
              </li>
            </ul>
          </Grid>
          <Grid className={classes.grid} item xs={12} lg={4}>
            <b className={classes.subtitle}>{Data[5].text}</b>
            <ul className={classes.table}>
              <li>
                <b>{Data[6].text}</b>
                <a href="tel:+48815612200">(+48 81) 561-22-00</a>
                <br />
              </li>
              <li>
                <b>e-mail:</b>
                <a href="mailto:office@eltem.pl">office@eltem.pl</a>
              </li>
            </ul>
            <b className={classes.subtitle}>{Data[7].text}</b>
            <ul className={classes.table}>
              <li>
                <i className={classes.subtitle} style={{ fontWeight: "500" }}>
                  {Data[17].text}
                </i>
                <br />
                <i>Przemysław Malicki</i>
                <br />
                <b>{Data[9].text}:</b>
                <a href="tel:+48815612299">(+48 81) 561-22-99</a>
              </li>
              <li>
                <b>{Data[10].text}:</b>{" "}
                <a href="tel:+48795450943"> (+48) 795-450-943</a>
              </li>
              <li>
                <b>e-mail:</b>{" "}
                <a href="mailto:przemyslaw.malicki@eltem.pl">
                  przemyslaw.malicki@eltem.pl
                </a>
              </li>
            </ul>
            <ul className={classes.table}>
              <i className={classes.subtitle} style={{ fontWeight: "500" }}>
                {Data[18].text}
              </i>
              <li>
                <i>Krzysztof Kamiński</i>
                <br />
                <b>{Data[9].text}:</b>
                <a href="tel:+48815612244">(+48 81) 561-22-44</a>
              </li>
              <li>
                <b>{Data[10].text}</b>{" "}
                <a href="tel:+48606415570"> (+48) 606-415-570</a>
                <br />
              </li>
              <li>
                <b>e-mail:</b> <a href="mailto:tech@eltem.pl">tech@eltem.pl</a>
              </li>
            </ul>
            <b className={classes.subtitle}>{Data[16].text}</b>
            <ul className={classes.table}>
              <li>
                <b>{Data[9].text}:</b>
                <a href="tel:+48815612247">(+48 81) 561-22-47</a>
              </li>
            </ul>

            {/* <b className={classes.subtitle}>{Data[11].text}</b>
            <ul className={classes.table}>
              <li>
                <b>{Data[8].text}:</b> <i>Krzysztof Kamiński</i>
                <br />
                <b>{Data[9].text}:</b>
                <a href="tel:+48815612244">(+48 81) 561-22-44</a>
              </li>
              <li>
                <b>{Data[10].text}</b>{" "}
                <a href="tel:+48606415570"> (+48) 606-415-570</a>
                <br />
              </li>
              <li>
                <b>e-mail:</b> <a href="mailto:tech@eltem.pl">tech@eltem.pl</a>
              </li>
            </ul> */}
            <b className={classes.subtitle}>{Data[12].text}</b>
            <ul className={classes.table}>
              <li>
                <b>{Data[9].text}</b>
                <a href="tel:+48815612215"> (+48 81) 561-22-15</a>
              </li>
              <li>
                <b>{Data[9].text}</b>
                <a href="tel:+48815612215"> (+48 81) 561-22-70</a>
              </li>
              <li>
                <b>e-mail:</b>
                <a href="mailto:rekrutacja@eltem.pl">rekrutacja@eltem.pl</a>
              </li>
            </ul>
          </Grid>
          <Grid className={classes.grid} item xs={12} lg={4}>
            <b className={classes.subtitle}> {Data[13].text}</b>
            <ul className={classes.table}>
              <li>
                <b>e-mail:</b>
                <a href="mailto:zgloszenia@eltem.pl">zgloszenia@eltem.pl</a>
                <br />
              </li>
              <li>
                <a rel="noreferrer" className={classes.link} href={FormDoc}>
                  <InsertDriveFileIcon className={classes.icon} />
                  {Data[14].text}
                </a>
              </li>
            </ul>

            <b className={classes.subtitle}>{Data[15].text}</b>

            <br />
            <ul className={classes.table}>
              <a
                rel="noreferrer"
                className={classes.link}
                href={pdf9001}
                target="_blank"
              >
                <PictureAsPdfIcon className={classes.icon} />
                ISO 9001:2015
              </a>
              <br />
              <a
                rel="noreferrer"
                className={classes.link}
                href={pdf14001}
                target="_blank"
              >
                <PictureAsPdfIcon className={classes.icon} />
                ISO 14001:2015
              </a>
              <br />
              <a
                rel="noreferrer"
                className={classes.link}
                href={qualityPolicy}
                target="_blank"
              >
                <PictureAsPdfIcon className={classes.icon} />
                Polityka Jakości
              </a>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.974417279!2d22.55667027760172!3d51.21954693169002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472257a482d98207%3A0x118509977e3e1500!2sEltem!5e0!3m2!1spl!2spl!4v1695924585629!5m2!1spl!2spl"
              style={{
                width: "100%",
                height: "450px",
                position: "relative",
                margin: "0px",
                border: 0,
              }}
              allowfullscreen=""
              // loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default ContactUs;
