import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./layout/Home";
import EuropeanUnion from "./layout/Pages/EuropeanUnion";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Navigate } from "react-router-dom";

import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Page404 from "./layout/Pages/Page404";
import History from "./layout/Pages/History";
import Certificates from "./layout/Pages/quality/Certificates";
import MachinePark from "./layout/Pages/production/MachinePark";
import ContactUs from "./layout/Pages/contactUs/ContactUs";
import MyMap from "./components/elements/dynamic/Map";
import ElectricalHarnesses from "./layout/Pages/offer/ElectricalHarnesses";
import CustomerElectronics from "./layout/Pages/offer/CustomerElectronics";
import ComponentsForSale from "./layout/Pages/offer/ComponentsForSale";
import GroundWires from "./layout/Pages/offer/GroundWires";
import AdditionalServices from "./layout/Pages/offer/AdditionalServices";
import IndicatorSet from "./layout/Pages/offer/IndicatorSet";
import ConstructorOffice from "./layout/Pages/offer/ConstructorOffice";
import QualityManagement from "./layout/Pages/quality/QualityManagement";
import CareerList from "./layout/Pages/career/CareerList.js";
import { useEffect } from "react";
import Industry from "./layout/Pages/production/Industry";
import CareerJob from "./layout/Pages/career/CareerJob";
import QualityPolicy from "./layout/Pages/quality/QualityPolicy";
import RodoPolicy from "./layout/Pages/policies/RodoPolicy";
import CookiesPolicy from "./layout/Pages/policies/CookiesPolicy";

// import App from "./App";

const theme = createTheme({
  typography: {
    fontFamily: ["Rajdhani"].join(","),
  },
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MuiThemeProvider theme={theme}>
    <React.StrictMode>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* <Route path="/test/" element={<App />} /> */}
          <Route path="/:lang/" element={<Home />} />
          <Route
            path="/:lang/subsidy-from-the-European-Union"
            element={<EuropeanUnion />}
          />
          <Route path="/:lang/our-history" element={<History />} />
          <Route path="/:lang/test-map" element={<MyMap />} />
          <Route path="/:lang/contact-us" element={<ContactUs />} />
          <Route path="/:lang/certificates" element={<Certificates />} />
          <Route
            path="/:lang/offer/electrical-harnesses"
            element={<ElectricalHarnesses />}
          />
          <Route
            path="/:lang/offer/grounding-and-power-cables"
            element={<GroundWires />}
          />
          <Route
            path="/:lang/offer/customer-specific-electronics"
            element={<CustomerElectronics />}
          />
          <Route path="/:lang/offer/indicator-set" element={<IndicatorSet />} />
          <Route
            path="/:lang/offer/design-office-services"
            element={<ConstructorOffice />}
          />
          <Route
            path="/:lang/offer/additional-services"
            element={<AdditionalServices />}
          />
          <Route
            path="/:lang/offer/sale-of-materials-and-components"
            element={<ComponentsForSale />}
          />
          <Route
            path="/:lang/production/machine-park"
            element={<MachinePark />}
          />
          <Route
            path="/:lang/production/application-in-the-industry"
            element={<Industry />}
          />
          <Route
            path="/:lang/quality/quality-management"
            element={<QualityManagement />}
          />
          <Route
            path="/:lang/quality/quality-policy"
            element={<QualityPolicy />}
          />
          <Route path="/:lang/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/:lang/privacy-rights" element={<RodoPolicy />} />
          <Route path="/:lang/career/" element={<CareerList />} />
          <Route path="/:lang/career/:idJob/:seo" element={<CareerJob />} />
          <Route path="/*" element={<Page404 />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/" element={<Navigate to="/pl/" />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </MuiThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
