export const translations = [
  {
    PL: "Polityka jakości",
    DE: "Qualitätspolitik",
    FR: "Politique de qualité",
    EN: "Quality policy",
    IT: "Politica di qualità",
  },
  {
    PL: "Celem ELTEM Sp. z o.o. jest projektowanie i produkcja wiązek przewodów elektrycznych i elektroniki o wysokiej jakości spełniającej oczekiwania klienta w harmonii ze środowiskiem oraz zasadami bezpieczeństwa i higieny pracy. Wysoka jakość produkcji, dbałość o środowisko oraz bezpieczne i higieniczne warunki pracy, gwarantują dalszy rozwój ELTEM Sp. z o.o. oraz umacniają jego pozycję na rynku.",
    DE: "Das Ziel von ELTEM Sp. z o.o. ist es, elektrische Kabelbäume und Elektronik von hoher Qualität zu entwerfen und herzustellen, die den Erwartungen der Kunden entsprechen und im Einklang mit der Umwelt und den Grundsätzen des Arbeitsschutzes stehen. Die hohe Qualität der Produktion, die Sorge um die Umwelt und die sicheren und hygienischen Arbeitsbedingungen garantieren die weitere Entwicklung der ELTEM Sp. z o.o. und stärken ihre Position auf dem Markt.",
    FR: "Le but d'ELTEM Sp. z o.o. est de concevoir et de fabriquer des faisceaux de fils électriques et électroniques de haute qualité répondant aux attentes des clients, dans le respect de l'environnement et des principes de santé et de sécurité au travail. La haute qualité de la production, le respect de l'environnement et les conditions de travail sûres et hygiéniques garantissent le développement futur d'ELTEM Sp. z o.o. et renforcent sa position sur le marché.",
    EN: "The aim of ELTEM Sp. z o.o. is to design and manufacture electrical wiring harnesses and electronics of high quality to meet customer expectations in harmony with the environment and occupational health and safety principles. The high quality of production, care for the environment and safe and healthy working conditions, guarantee the further development of ELTEM Sp. z o.o. and strengthen its position on the market.",
    IT: "L'obiettivo di ELTEM Sp. z o.o. è progettare e produrre cablaggi elettrici ed elettronica di alta qualità per soddisfare le aspettative dei clienti in armonia con l'ambiente e con i principi di salute e sicurezza sul lavoro. L'alta qualità della produzione, l'attenzione per l'ambiente e le condizioni di lavoro sicure e salutari garantiscono l'ulteriore sviluppo di ELTEM Sp. z o.o. e ne rafforzano la posizione sul mercato.",
  },
  {
    PL: "Główne kierunki Polityki Jakości / Środowiskowej realizowane są poprzez:",
    DE: "Die Hauptrichtungen der Qualitäts-/Umweltpolitik werden durch folgende Maßnahmen umgesetzt:",
    FR: "Les principales orientations de la politique en matière de qualité et d'environnement sont mises en œuvre à travers :",
    EN: "The main orientations of the Quality/Environmental Policy are implemented through:",
    IT: "I principali orientamenti della Politica per la Qualità/Ambiente sono attuati attraverso:",
  },
  {
    PL: [
      "kształtowanie przekonań, że wszyscy pracownicy ELTEM Sp. z o.o. odpowiedzialni są za jakość, środowisko oraz bezpieczeństwo i higienę pracy;",
      "doskonalenie procesów i podnoszenie jakości produkowanych wyrobów z uwzględnieniem ryzyk i szans w powiązaniu z przestrzeganiem wymagań prawnych i innych dotyczących produkowanych wyrobów, ochrony środowiska oraz bezpieczeństwa i higieny pracy;",
      "działania zmierzające do: ograniczenia ryzyka zawodowego na stanowiskach pracy, zapobiegania wypadkom przy pracy, chorobom zawodowym, ochrony środowiska, spełnienia zobowiązań dotyczących zgodności, zapobiegania zanieczyszczeniom oraz zrównoważonego wykorzystania zasobów;",
      "ciągłe doskonalenie systemów zarządzania zgodnych z wymaganiami norm: PN-EN ISO 9001:2015, PN-EN ISO 14001:2015;",
      "systematyczne szkolenie pracowników w zakresie systemów zarządzania, zasad dotyczących ochrony środowiska i BHP;",
      "zapewnienie niezbędnych zasobów i środków do wdrożenia i realizacji niniejszej Polityki Jakości / Środowiskowej;",
      "bieżącą ocenę przez Prezesa i Zarząd ELTEM Sp. z o.o. skuteczności i efektywności funkcjonowania systemów zarządzania;",
      "pomoc dostawcom w rozwijaniu i utrzymaniu systemów zarządzania dotyczących wymagań jakościowych i środowiskowych.",
    ],
    DE: [
      "Förderung der Überzeugung, dass alle Mitarbeiter von ELTEM Sp. z o.o. für Qualität, Umwelt, Gesundheit und Sicherheit am Arbeitsplatz verantwortlich sind;",
      "Verbesserung der Prozesse und der Qualität der hergestellten Produkte unter Berücksichtigung von Risiken und Chancen in Verbindung mit der Einhaltung gesetzlicher und sonstiger Anforderungen an die hergestellten Produkte, den Umweltschutz und die Arbeitssicherheit und den Gesundheitsschutz;",
      "Maßnahmen zur Verringerung berufsbedingter Risiken am Arbeitsplatz, zur Verhütung von Arbeitsunfällen und Berufskrankheiten, zum Schutz der Umwelt, zur Einhaltung von Vorschriften, zur Vermeidung von Umweltverschmutzung und zur nachhaltigen Nutzung von Ressourcen;",
      "Kontinuierliche Verbesserung von Managementsystemen, die den Anforderungen der Normen entsprechen: PN-EN ISO 9001:2015, PN-EN ISO 14001:2015;",
      "Systematische Schulung der Mitarbeiter in Bezug auf Managementsysteme, Umwelt-, Gesundheits- und Sicherheitsvorschriften;",
      "Bereitstellung der erforderlichen Ressourcen und Mittel zur Umsetzung und Durchsetzung dieser Qualitäts- und Umweltpolitik;",
      "Ständige Bewertung der Wirksamkeit und Effizienz des Funktionierens der Managementsysteme durch den Geschäftsführer und den Vorstand der ELTEM Sp. z o.o.;",
      "Unterstützung der Lieferanten bei der Entwicklung und Aufrechterhaltung von Managementsystemen für Qualitäts- und Umweltanforderungen.",
    ],
    FR: [
      " encourager la conviction que tous les employés d'ELTEM Sp. z o.o. sont responsables de la qualité, de l'environnement, de la santé et de la sécurité au travail ;",
      "améliorer les processus et la qualité des produits fabriqués, en tenant compte des risques et des opportunités, tout en respectant les exigences légales et autres concernant les produits fabriqués, la protection de l'environnement et la santé et la sécurité au travail ",
      "les activités visant à réduire les risques professionnels sur les lieux de travail, à prévenir les accidents du travail et les maladies professionnelles, à protéger l'environnement, à respecter les obligations de conformité, à prévenir la pollution et à assurer une utilisation durable des ressources ;",
      "l'amélioration continue des systèmes de gestion conformes aux exigences des normes : PN-EN ISO 9001:2015, PN-EN ISO 14001:2015 ;",
      "la formation systématique des employés aux systèmes de gestion et aux règles en matière d'environnement, de santé et de sécurité",
      "fournir les ressources et les moyens nécessaires à la mise en œuvre et à l'application de la présente politique de qualité/environnementale ;",
      "l'évaluation permanente par le président et le conseil d'administration d'ELTEM Sp. z o.o. de l'efficacité et de l'efficience du fonctionnement des systèmes de gestion ;",
      "aider les fournisseurs à élaborer et à maintenir des systèmes de gestion pour les exigences en matière de qualité et d'environnement.",
    ],
    EN: [
      "shaping beliefs that all ELTEM Sp. z o.o. employees are responsible for quality, the environment and occupational health and safety;",
      "improving processes and improving the quality of manufactured products, taking into account risks and opportunities in conjunction with compliance with legal and other requirements concerning manufactured products, environmental protection and occupational health and safety;",
      "taking measures for: reduction of occupational risks at workplaces, prevention of occupational accidents, occupational diseases, protection of the environment, compliance obligations, prevention of pollution and sustainable use of resources;",
      "improving the management systems according to the requirements of the standards: PN-EN ISO 9001:2015, PN-EN ISO 14001:2015;",
      "systematic training of employees in management systems, environmental and occupational health and safety principles;",
      "providing the necessary resources and measures to implement and carry out this Quality/Environmental Policy;",
      "evaluating on an ongoing basis by the CEO and the Management Board of ELTEM Sp. z o.o. the effectiveness and efficiency of the management systems;",
      "assisting suppliers in developing and maintaining management systems for quality and environmental requirements.",
    ],
    IT: [
      "promuovere la convinzione che tutti i dipendenti di ELTEM Sp. z o.o. siano responsabili della qualità, dell'ambiente e della salute e sicurezza sul lavoro;",
      "migliorare i processi e la qualità dei prodotti fabbricati, tenendo conto dei rischi e delle opportunità, nel rispetto dei requisiti legali e di altro tipo relativi ai prodotti fabbricati, alla tutela dell'ambiente e alla salute e sicurezza sul lavoro ",
      "attività volte a ridurre i rischi professionali sul luogo di lavoro, a prevenire gli infortuni e le malattie professionali, a proteggere l'ambiente, a rispettare gli obblighi di conformità, a prevenire l'inquinamento e a garantire l'uso sostenibile delle risorse",
      "Miglioramento continuo dei sistemi di gestione in linea con i requisiti delle norme: PN-EN ISO 9001:2015, PN-EN ISO 14001:2015;",
      "formazione sistematica dei dipendenti sulle norme e i sistemi di gestione in materia di ambiente, salute e sicurezza",
      "fornire le risorse e i mezzi necessari per l'attuazione e l'applicazione di questa Politica di Qualità/Ambiente;",
      "valutazione continua da parte del Presidente e del Consiglio di Amministrazione di ELTEM Sp. z o.o. dell'efficacia e dell'efficienza del funzionamento dei sistemi di gestione;",
      "aiutare i fornitori a sviluppare e mantenere sistemi di gestione per la qualità e i requisiti ambientali.",
    ],
  },
  {
    PL: "Zarząd ELTEM Sp. z o.o. zapewnia, że Polityka Jakości / Środowiskowa jest wdrożona i utrzymywana na wszystkich obszarach działalności Spółki oraz jest dostępna dla zainteresowanych stron.",
    DE: "Der Vorstand der ELTEM Sp. z o.o. stellt sicher, dass die Qualitäts- und Umweltpolitik in allen Bereichen der Geschäftstätigkeit des Unternehmens umgesetzt und aufrechterhalten wird und interessierten Parteien zur Verfügung steht.",
    FR: "Le conseil d'administration d'ELTEM Sp. z o.o. veille à ce que la politique de qualité et d'environnement soit mise en œuvre et maintenue dans tous les domaines d'activité de l'entreprise et qu'elle soit mise à la disposition des parties intéressées.",
    EN: "The Board of Management of ELTEM Sp. z o.o. guarantees that the Quality / Environmental Policy is implemented and maintained in all fields of the Company's activities and is accessible to persons concerned.",
    IT: "Il Consiglio di Amministrazione di ELTEM Sp. z o.o. assicura che la politica per la qualità e l'ambiente sia attuata e mantenuta in tutti i settori di attività dell'azienda e che sia resa disponibile alle parti interessate.",
  },
  {
    PL: "Linki do pobrania:",
    DE: "Download-Links:",
    FR: "Liens à télécharger :",
    EN: "Download:",
    IT: "Link per il download:",
  },
  {
    PL: "(Otwierają się na nowej zakładce.)",
    DE: "(Sie werden in einem neuen Tab geöffnet).",
    FR: "La page s'ouvre dans un nouvel onglet.",
    EN: "(Open in a new tab.)",
    IT: "(Si aprono in una nuova scheda)",
  },
  {
    PL: "ISO 9001:2015 PL EN DE",
    DE: "ISO 9001:2015 PL EN DE",
    FR: "ISO 9001:2015 PL EN DE",
    EN: "ISO 9001:2015 PL EN DE",
    IT: "ISO 9001:2015 PL EN DE",
  },
  {
    PL: "ISO 14001:2015 PL EN DE",
    DE: "ISO 14001:2015 PL EN DE",
    FR: "ISO 14001:2015 PL EN DE",
    EN: "ISO 14001:2015 PL EN DE",
    IT: "ISO 14001:2015 PL EN DE",
  },
  {
    PL: "jakość",
    DE: "qualität",
    FR: "qualité",
    EN: "quality",
    IT: "qualità",
  },
];
