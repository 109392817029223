import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Navigate, NavLink } from "react-router-dom";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import color from "../../../assets/styles/colors";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LanguageIcon from "@mui/icons-material/Language";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  langContainer: {
    // position: "absolute",
    top: "15px",
    marginLeft: "20px",
  },
  arrowIcon: {
    color: color.green3,
    position: "relative",
    top: "8px",
    right: "15px",
  },
  button: {
    fontWeight: "800 !important",
    color: color.green3,
    marginRight: "5px",
    fontSize: "16px",
    borderColor: color.white + "!important",
    backgroundColor: color.white,
    "&:hover": {
      color: color.white,
      backgroundColor: color.green3,
    },
  },
  mainButtonGroup: {
    "&>a": {
      color: color.green3,
      borderColor: color.green1,
      borderRadius: 0,
      backgroundColor: color.white,
      "&:hover": {
        color: color.green3,
        backgroundColor: color.white,
      },
    },
  },
  buttonGroup: {
    "&>a": {
      color: color.green3,
      borderColor: color.green1,
      borderRadius: 0,
      backgroundColor: color.white,
      "&:hover": {
        color: color.white,
        backgroundColor: color.green3,
        fontWeight: "800",
      },
    },
  },
  container: {
    transition: "1s",
    "&>div>div": {
      transition: "1s",
      opacity: "0",
    },
    "&>div>svg": {
      transform: "rotate(90deg)",
      transition: "1s",
    },
    "&:hover": {
      "&>div>svg": {
        transform: "rotate(0deg)",
        transition: "1s",
      },
      "&>div>div": {
        transition: "1s",
        opacity: "1 !important",
      },
    },
  },
  display: {
    display: "unset",
  },
  hide: {
    display: "none",
  },
}));

function CheckerLanguage() {
  const params = useParams();
  if (
    params.lang !== "pl" &&
    params.lang !== "en" &&
    params.lang !== "de" &&
    params.lang !== "fr" &&
    params.lang !== "it"
  ) {
    return <Navigate to="/pl" />;
  }
}

function ShowLanguages(props) {
  const params = useParams();
  console.log(params);
  const path = window.location.pathname;
  const pathWithoutLocale = path.replace(`/${params.lang}`, "");
  // console.log(pathWithoutLocale);
  return (
    <>
      <Grid className={props.classes.container}>
        <LanguageIcon
          style={{
            color: color.green3,
            position: "relative",
            left: "-5px",
            top: "8px",
          }}
        />
        <ButtonGroup id="test" className={props.classes.mainButtonGroup}>
          <Button
            className={props.classes.button}
            component={NavLink}
            to={"/" + params.lang + pathWithoutLocale}
            children={params.lang}
          />
          <KeyboardArrowRightIcon className={props.classes.arrowIcon} />
        </ButtonGroup>
        <Grid style={{ display: "contents" }}>
          <ButtonGroup className={props.classes.buttonGroup} variant="outlined">
            <Button
              className={
                params.lang === "pl"
                  ? props.classes.hide
                  : props.classes.display
              }
              component={NavLink}
              to={"/pl" + pathWithoutLocale}
              children={"PL"}
            />
            <Button
              className={
                params.lang === "en"
                  ? props.classes.hide
                  : props.classes.display
              }
              component={NavLink}
              to={"/en" + pathWithoutLocale}
              children={"EN"}
            />
            <Button
              className={
                params.lang === "de"
                  ? props.classes.hide
                  : props.classes.display
              }
              component={NavLink}
              to={"/de" + pathWithoutLocale}
              children={"DE"}
            />
            <Button
              className={
                params.lang === "it"
                  ? props.classes.hide
                  : props.classes.display
              }
              component={NavLink}
              to={"/it" + pathWithoutLocale}
              children={"IT"}
            />
            <Button
              className={
                params.lang === "fr"
                  ? props.classes.hide
                  : props.classes.display
              }
              component={NavLink}
              to={"/fr" + pathWithoutLocale}
              children={"FR"}
            />
          </ButtonGroup>
        </Grid>
      </Grid>
    </>
  );
}

const LanguageBarMobile = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid className={classes.langContainer}>
        <ShowLanguages classes={classes} />
      </Grid>
      <CheckerLanguage />
    </React.Fragment>
  );
};

export default LanguageBarMobile;
