import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Examples, vehicles } from "../../../translations/production/Industry";
import color from "../../../assets/styles/colors";
import image1 from "../../../../src/assets/png/industry/image1.png";
import image2 from "../../../../src/assets/png/industry/image2.png";
import image3 from "../../../../src/assets/png/industry/image3.png";
import image9 from "../../../../src/assets/png/industry/image9.png";
import image5 from "../../../../src/assets/png/industry/image5.png";
import image6 from "../../../../src/assets/png/industry/image6.png";
import image7 from "../../../../src/assets/png/industry/image7.png";
import image8 from "../../../../src/assets/png/industry/image8.png";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "36px",
  },
  label: {
    color: color.green3,
  },
  image2: {
    width: "auto",
    margin: "auto",
    display: "block",
    transform: "scale(0.8)",
    "&:hover": {
      transform: "scale(0.9)",
    },
    transition: "all 1s",
  },
  imageContainers: {
    "&:hover": {
      boxShadow: "rgba(3, 28, 106, 0.30) 0px 6px 20px",
    },
    transition: "all 1s",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    height: "250px",
    boxShadow: "rgba(3, 28, 106, 0.04) 0px 6px 20px",
  },
  text: {
    margin: "45px !important",
    fontWeight: "500",
  },
  container: {
    marginTop: "40px",
    marginBottom: "40px",
  },
}));

const CircularNavigation = () => {
  const classesN = useStyles();
  const lang = useParams().lang.toUpperCase();
  const [hoveredImage, setHoveredImage] = useState(Examples[0][lang]);
  const currentLang = Examples[0][lang];

  const handleMouseEnter = (image) => {
    setHoveredImage(image);
  };

  useEffect(() => {
    setHoveredImage(currentLang);
  }, [currentLang]);
  const handleMouseLeave = () => {
    setHoveredImage(Examples[0][lang]);
  };

  return (
    <Grid
      key={lang} // Klucz dla re-renderowania po zmianie języka
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classesN.container}
    >
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() =>
          handleMouseEnter(vehicles[0][lang], vehicles[0][lang])
        }
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image1} />
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[1][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image2} />
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[2][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image3} />
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[3][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image9} />
      </Grid>
      <Grid align="center" item xs={12} md={4}>
        <Grid className={classesN.label}>
          <Typography
            className={classesN.text}
            style={{ textTransform: "capitalize" }}
            variant="h4"
          >
            {hoveredImage}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[4][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image5} />
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[5][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image6} />
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[6][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image7} />
      </Grid>
      <Grid
        className={classesN.imageContainers}
        align="center"
        item
        xs={12}
        md={4}
        onMouseEnter={() => handleMouseEnter(vehicles[7][lang])}
        onMouseLeave={handleMouseLeave}
      >
        <img className={classesN.image2} alt="" src={image8} />
      </Grid>
    </Grid>
  );
};

export default CircularNavigation;
