import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../../PageWrapper";
import image from "../../../assets/jpeg/baners/banerCertificates.jpg";
import { Grid } from "@material-ui/core";
import color from "../../../assets/styles/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  image: {
    objectFit: "",
    width: "100%",
    height: "300px",
    objectPosition: "center center",
    borderRadius: "5px",
    margin: "20px",
  },
  text: {
    "&>b": {
      color: color.green3,
    },
    "&>ul>b": {
      color: color.green3,
    },
    marginTop: "10px",
  },
  grid: {
    padding: "20px",
  },
  imageContainer: {
    padding: "20px",
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      display: "block",
      "&>img": {
        margin: "0px",
        height: "300px",
      },
    },
  },
  link: {
    marginTop: "20px",
    color: color.green3,
    "&:hover": {
      color: color.green2,
    },
  },
  icon: {
    marginRight: "10px",
  },
  subtitle: {
    color: color.green3,
    fontWeight: "600",
    marginTop: "20px",
    textTransform: "uppercase",
  },
}));

const CookiesPolicy = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <PageWrapper
        banerImage={image}
        title="Polityka Cookies"
        naviChild1="Polityka Cookies"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs={12} lg={12}>
            <h2>
              <strong> Informacja o plikach cookies</strong>
            </h2>
            <ol>
              <li>Serwis korzysta z plików cookies:</li>
              "cookiesAccepted" - zmienna określająca czy polityka prywatności
              została zaakceptowana przez użytkownika.
              <li>
                Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
                szczególności pliki tekstowe, które przechowywane są w
                urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do
                korzystania ze stron internetowych Serwisu. Cookies zazwyczaj
                zawierają nazwę strony internetowej, z której pochodzą, czas
                przechowywania ich na urządzeniu końcowym oraz unikalny numer.
              </li>
              <li>
                Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
                Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
                operator Serwisu.
              </li>
              <li>
                Pliki cookies wykorzystywane są w następujących celach:
                <ol>
                  <li>W celu poprawności poprawności działania strony.</li>
                </ol>
              </li>
              <li>
                W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
                cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
                cookies). Cookies „sesyjne” są plikami tymczasowymi, które
                przechowywane są w urządzeniu końcowym Użytkownika do czasu
                wylogowania, opuszczenia strony internetowej lub wyłączenia
                oprogramowania (przeglądarki internetowej). „Stałe” pliki
                cookies przechowywane są w urządzeniu końcowym Użytkownika przez
                czas określony w parametrach plików cookies lub do czasu ich
                usunięcia przez Użytkownika.
              </li>
              <li>
                Oprogramowanie do przeglądania stron internetowych (przeglądarka
                internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
                cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu
                mogą dokonać zmiany ustawień w tym zakresie.&nbsp;Przeglądarka
                internetowa umożliwia usunięcie plików cookies. Możliwe jest
                także automatyczne blokowanie plików cookies Szczegółowe
                informacje na ten temat zawiera pomoc lub dokumentacja
                przeglądarki internetowej.
              </li>
              <li>
                Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
                funkcjonalności dostępne na stronach internetowych Serwisu.
              </li>
              <li>
                Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                Serwisu wykorzystywane mogą być również przez współpracujące z
                operatorem Serwisu podmioty, w szczególności dotyczy to firm:
                Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z
                siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).
              </li>
            </ol>
            <h2>
              <strong>
                9. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać
                zgodę?
              </strong>
            </h2>
            <ol>
              <li>
                Jeśli użytkownik nie chce otrzymywać plików cookies, może
                zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie
                obsługi plików cookies niezbędnych dla procesów
                uwierzytelniania, bezpieczeństwa, utrzymania preferencji
                użytkownika może utrudnić,&nbsp;a w skrajnych przypadkach może
                uniemożliwić korzystanie ze stron www
              </li>
              <li>
                W celu zarządzania ustawienia cookies wybierz z listy poniżej
                przeglądarkę internetową, której używasz i postępuj zgodnie z
                instrukcjami:
                <ul>
                  <li>
                    <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
                      Edge
                    </a>
                  </li>
                  <li>
                    <a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                      Internet Explorer
                    </a>
                  </li>
                  <li>
                    <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                      Chrome
                    </a>
                  </li>
                  <li>
                    <a href="http://support.apple.com/kb/PH5042">Safari</a>
                  </li>
                  <li>
                    <a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">
                      Firefox
                    </a>
                  </li>
                  <li>
                    <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                      Opera
                    </a>
                  </li>
                </ul>
                <p>Urządzenia mobilne:</p>
                <ul>
                  <li>
                    <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                      Android
                    </a>
                  </li>
                  <li>
                    <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">
                      Safari (iOS)
                    </a>
                  </li>
                  <li>
                    <a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">
                      Windows Phone
                    </a>
                  </li>
                </ul>
              </li>
            </ol>
          </Grid>
        </Grid>
      </PageWrapper>
    </React.Fragment>
  );
};

export default CookiesPolicy;
