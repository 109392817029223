export const translations = [
  {
    ID: 1,
    PL: "Wiązki przewodów elektrycznych",
    FR: "Faisceaux de câbles électriques",
    EN: "Wire Harnesses",
    IT: "Cablaggi elettrici",
    DE: "Elektrische Kabelbäume",
  },
  {
    ID: 2,
    PL: "Oferujemy Państwu prawie 30 letnie doświadczenie w produkcji wiązek elektrycznych i elektroniki. Przez wiele lat zbieraliśmy wiedzę, szkoliliśmy kadrę, rozbudowywaliśmy park maszynowy i infrastrukturę firmy. Z roku na roku nasze wyroby stają się coraz bardziej kompleksowe, wymagają większej precyzji, doświadczenia oraz wykorzystania coraz bardziej zaawansowanych komponentów i technologii.",
    DE: "Wir bieten Ihnen unsere fast 30 Jahre Erfahrung in  Produktion von elektrischen Kabelbäumen und Elektronik an. Jahrelang haben wir die Kenntnisse gesammelt, unser Personal fortgebildet und den Maschinenpark sowie Infrastruktur der Firma ausgebaut. Von Jahr zu Jahr werden unsere Produkte immer mehr kompliziert und haben immer größere Präzision und Erfahrung sowie Verwendung von immer mehr fortgeschrittenen Komponenten und Technologien erfordert.  ",
    FR: "Nous mettons à votre disposition près de 30 ans d'expérience dans la production de faisceaux électriques et électroniques. Depuis de nombreuses années, nous accumulons des connaissances, nous formons notre personnel, nous développons notre parc de machines et l'infrastructure de notre entreprise. Année après année, nos produits sont devenus de plus en plus complexes, exigeant davantage de précision, d'expérience et l'utilisation de composants et de technologies de plus en plus avancés.",
    EN: "We offer you our almost 30-year experience in production of electrical harness and electronics. For many years, we have accumulated our knowledge, trained our staff, developed our machinery park and company’s infrastructure. From year to year, our products become more and more complex and it has required increased precision, experience and application of more advanced components and technologies.",
    IT: "Vi offriamo quasi 30 anni di esperienza nella produzione di cablaggi elettrici ed elettronica. Per molti anni abbiamo raccolto conoscenze, formato il nostro personale, ampliato i nostri macchinari e l'infrastruttura aziendale. Anno dopo anno, i nostri prodotti sono diventati sempre più complessi, richiedendo maggiore precisione, esperienza e l'utilizzo di componenti e tecnologie sempre più avanzate.",
  },
  {
    ID: 3,
    PL: "Obecnie na ten moment dysponujemy:",
    DE: "Heutzutage verfügen wir über:",
    FR: "Nous disposons actuellement :",
    EN: "As at today we have got:",
    IT: "Al momento disponiamo di:",
  },
  {
    ID: 4,
    PL: [
      "400 osobową kadrą produkcyjną, zdolną do pracy w trybie dwuzmianowym,",
      "zespołem konstrukcyjnym wiązek,",
      "zespołem konstrukcyjnym elektroniki,",
      "zespołem kontroli jakości, sprawującym nadzór nad jakością naszych wyrobów, począwszy od kontroli dostaw komponentów, poprzez wielopunktowe kontrole procesu produkcyjnego, na kontroli elektrycznej wyrobu ostatecznego kończąc",
      "ponad 7900 m2 powierzchni produkcyjno-magazynowej,",
      "zautomatyzowanym parkiem maszynowym do kompleksowej obróbki przewodów, opartym na maszynach Komax, Schleuniger, Schunk, Mecal, Ulmer, Wiedenbach, Imaje, Hitachi, DSG Canusa, TE, WDT, Kabatec, Proeff,",
      "czynną kontrolą procesu zacisku końcówki na przewodzie, prowadzoną przez automaty oraz pracowników,",
      "magazyn materiałów z ilością ponad 6.000 aktywnych kodów komponentów, światowych producentów takich jak AMPHENOL, ATMEL, BINDER, BITRON, BOPLA, BOSCH, COBO, DAIMLER, DEUTSCH, DELPHI, DSG CANUSA, ERICH JAEGER, FORD, HAMMOND, HARTING, HELLA, HERTH & BUSS, HIRSCHMANN, ILME, ITT CANON, KLAUKE, LEAR, MENBERS, MOLEX, MTA, PHOENIX, PNEUTRON, SAMTEC, SCHLEMMER, SIEMENS, ST ELECTRONICS, SUMITOMO, TESA, TYCO, TURCK, WEIDMULLER, WINDSTAR, WURTH, YAZAKI, ZETTLER,",
      "magazyn przewodów o przekrojach od 0,12 mm2 do 120mm2, w zakresie temperatur od 105 do 180 stopni Celsjusza,",
      "bazujemy wyłącznie na oryginalnych komponentach oraz przewodach wykonywanych wg norm europejskich, od wiarygodnych, certyfikowanych dostawców,",
      "procedurami ISO 9001 i 14001, a także normami wewnętrznymi zapewniającymi jakość oraz powtarzalność produkcji, raporty jakościowe dla odbiorcy są prowadzone zgodnie z jego wymaganiami,",
    ],
    DE: [
      "400-Personen-Produktionspersonal, das in der Lage ist, im 2-Schichtsystem zu arbeiten,",
      "Konstruktionsabteilung für Kabelbäume,",
      "Konstruktionsabteilung für Elektronik,",
      "Qualitätsabteilung, die sich um die Qualität unserer  Produkte kümmert:  von der Eingangskontrolle der Komponenten, durch mehrstufige Kontrolle des Produktionsprozesses, bis zur elektrischen Kontrolle des Endprodukts.",
      "Mehr als 7900 m2 Produktions- und Lagerfläche,",
      "Einen automatisierten Maschinenpark für eine komplexe Verarbeitung der Leitungen, bestehenden aus  Komax , Schleuniger, Schunk, Mecal, Ulmer, Wiedenbach, Imaje, Hitachi, DSG Canusa, TE, WDT, Kabatec, Proeff,",
      "Kontrolle des Crimpprozesses des Kontakts an der Leitung , durchgeführt automatisch durch Maschinen und von unseren Mitarbeitern, ",
      "Lager der Materialien mit mehr als 6.000 aktiven Komponentenartikelnummern von weltbekannten Herstellern, wie:  ATMEL, BINDER, BITRON,BOPLA, BOSCH,  COBO, DAIMLER, DEUTSCH, DELPHI, DSG CANUSA, ERICH JAEGER,  FORD, HAMMOND, HARTING, HELLA, HERTH & BUSS, HIRSCHMANN, ILME,  ITT, CANON, KLAUKE,  LEAR, MENBERS,   MOLEX, MTA, PHOENIX, PNEUTRON, SAMTEC, SCHLEMMER, SIEMENS, ST ELECTRONICS, SUMITOMO, TESA, TYCO, TURCK, WEIDMULLER, WINDSTAR, WURTH,  YAZAKI, ZETTLER",
      "Lager der Leitungen mit Querschnitten von  0,12 mm2 bis 120mm2, und in Temperaturbereichen von 105 do 180 Grad Celsius,",
      "Originellen Bauteilen und Leitungen hergestellt nach europäischen Normen, geliefert  von zuverlässigen, zertifizierten Lieferanten,",
      "Die Verfahren nach ISO 9001 und 14001 sowie die internen Normen zur Gewährleistung der Qualität und der Wiederholbarkeit der Produktion, die Qualitätsberichte an die Kunden werden gemäß deren Anforderungen erstellt,",
      "de plus de 7 900 m2 d'espace de production et de stockage,",
    ],
    FR: [
      "d'une équipe de production de 400 personnes, capable de travailler en deux équipes,",
      "d'une équipe de constructeurs de faisceaux",
      "d'une équipe de spécialistes dans le domaine de l'électronique.",
      "d'une équipe de contrôle de la qualité qui supervise la qualité de nos produits, depuis le contrôle de réception des composants jusqu'à l'inspection électrique du produit final, en passant par des vérifications du processus de production en plusieurs points",
      "d'un parc de machines automatisées pour le traitement de fils complexes, basées sur des machines Komax, Schleuniger, Schunk, Mecal, Ulmer, Wiedenbach, Imaje, Hitachi, DSG Canusa, TE, WDT, Kabatec, Proeff,",
      "Nous avons  un contrôle actif du processus de sertissage de la cosse sur le fil, effectué par des machines automatisées et des employés,",
      "d'un stock de matériaux avec plus de 6. 000 codes de composants actifs, de fabricants mondiaux tels que AMPHENOL, ATMEL, BINDER, BITRON, BOPLA, BOSCH, COBO, DAIMLER, DEUTSCH, DELPHI, DSG CANUSA, ERICH JAEGER, FORD, HAMMOND, HARTING, HELLA, HERTH & BUSS, HIRSCHMANN, ILME, ITT CANON, KLAUKE, LEAR, MENBERS, MOLEX, MTA, PHOENIX, PNEUTRON, SAMTEC, SCHLEMMER, SIEMENS, ST ELECTRONICS, SUMITOMO, TESA, TYCO, TURCK, WEIDMULLER, WINDSTAR, WURTH, YAZAKI, ZETTLER",
      "Nous disposons actuellement d'un stock de câbles d'une section allant de 0,12 mm2 à 120 mm2, avec une plage de température allant de 105 à 180 degrés Celsius,",
      "Nous nous appuyons exclusivement sur des composants et des tuyaux d'origine fabriqués selon les normes européennes provenant de fournisseurs fiables et certifiés,",
      "Nous avons appliqué les procédures ISO 9001 et 14001, ainsi que des normes internes visant à garantir la qualité et la répétabilité de la production. Les rapports de qualité destinés aux clients sont établis conformément à leurs exigences,",
    ],

    EN: [
      "400-person production staff, capable of working in two shift system,",
      "electrical harness design team,",
      "electronics design team,",
      "quality control team which supervise the quality of our goods starting from the delivery of components, through the multi-point control of the production and ending with the electric testing of the final products",
      "more than 7900 m2 of the production and storage facilities,",
      "automated machine park used for the comprehensive processing of wires, based on Komax, , Schleuniger, Schunk, Mecal, Ulmer, Wiedenbach, Imaje, Hitachi, DSG Canusa, TE, WDT, Kabatec, Proeff machines",
      "active control of wire crimping force performed automatically and by our staff,",
      "material warehouse containing more than 6.000 active component codes delivered by such international manufacturers as AMPHENOL, ATMEL, BINDER, BITRON,BOPLA, BOSCH,  COBO, DAIMLER, DEUTSCH, DELPHIDSG CANUSA, ERICH JAEGER,  FORD, HAMMOND, HARTING, HELLA, HERTH & BUSS, HIRSCHMANN, ILME,  ITT CANON, KLAUKE,  LEAR, MENBERS,   MOLEX, MTA, PHOENIX, PNEUTRON, SAMTEC, SCHLEMMER, SIEMENS, ST ELECTRONICS, SUMITOMO, TESA, TYCO ,  TURCK, WEIDMULLER, WINDSTAR, WURTH,  YAZAKI, ZETTLER",
      "stock of wires from 0.12 mm2 up to 120mm2 in diameter within the temperature range of 105 up to 180 grades Celsius,",
      "we exclusively rely on original components and wires as well as the wires manufactured in compliance with the European standards provided by credible and certified vendors,",
    ],
    IT: [
      "personale di produzione di 400 persone, in grado di lavorare a 2 turni,",
      "reparto tecnico di cablaggi",
      "reparto tecnico di elettronica",
      "un reparto di controllo qualità, che supervisiona la qualità dei nostri prodotti, cominciando dall’ arrivo dei componenti, collaudo multispot, fino al collaudo elettrico del prodotto finale\n",
      "Più di 7900 m2 di spazio produttivo e di stoccaggio",
      "Automatizzato parco macchine per lavorazione dei cavi basato sulle macchine Komax, Schleuniger, Schunk, Mecal, Ulmer, Wiedenbach, Imaje, Hitachi, DSG Canusa, TE, WDT, Kabatec, Proeff.  ",
      "Collaudo di aggraffatura del terminale sul cavo svolto da sistemi automatici e da personale qualificato ",
      "Magazzino componenti con più di 6000 codici attivi prodotti dai leader mondiali come ad esempio Amphenol, ATMEL, BINDER, BITRON, BOPLA, BOSCH, COBO, DAIMLER, DEUTSCH, DELPHI, DSG CANUSA, ERICH JAEGER,  FORD, HAMMOND, HARTING, HELLA, HERTH & BUSS, HIRSCHMANN, ILME,  ITT CANON, KLAUKE, LEAR, MENBERS, MOLEX, MTA, PHOENIX, PNEUTRON, SAMTEC, SCHLEMMER, SIEMENS, ST ELECTRONICS, SUMITOMO, TESA, TYCO, TURCK, WEIDMULLER, WINDSTAR, WURTH,  YAZAKI, ZETTLER",
      "Magazzino cavi di diametro da 0,12 mm2 a 120mm2 , con range di temperature comprese fra 105 e 180 gradi ",
      "Acquistiamo solamente componenti originali e cavi prodotti secondo le norme europee provenienti dai fornitori credibili e certficati ",
      "Le procedure ISO 9001 e 14001, così come gli standard interni per garantire una produzione di qualità e ripetibile, le relazioni sulla qualità al cliente sono eseguite in conformità con i suoi requisiti,",
    ],
  },
  {
    ID: 15,
    PL: "Ilości:",
    DE: "Mengen:",
    FR: "Quantités :",
    EN: "Volumes:",
    IT: "Quantità:",
  },
  {
    ID: 16,
    PL: "Przyjmujemy zamówienia, które obejmują kilka sztuk rocznie, oraz takie które wymagają uruchomienia produkcji na poziomie kilku tysięcy pozycji miesięcznie. Wykonujemy wiązki prototypowe, preserie w połączeniu z procesem przygotowania wewnętrznej dokumentacji projektowo produkcyjnej zgodnie z potrzebami Klienta. Wprowadzamy i aktualizujemy dokumentacje klienta na podstawie przesłanych informacji o zmianach i modyfikacjach.",
    DE: "Wir nehmen sowohl die Aufträge für einige Stücke pro Jahr an, als auch diejenigen, die die Produktionsumsetzung auf dem Niveau von einigen Tausenden Stücke pro Monat erfordern. Wir führen prototypischen Kabelbäume aus, sowie Vorserien in Verbindung mit dem Prozess der Überprüfung und Anpassung der internen Produktionsdokumentation an die Unterlagen und Bedürfnisse des Kunden. Wir einführen und aktualisieren die Kundendokumentation auf der Basis von den uns zugeschickten Informationen über gewünschte Änderungen und Modifikationen. ",
    FR: "Nous acceptons des commandes allant de quelques unités par an à des séries de plusieurs milliers d'articles par mois. Nous produisons des prototypes de faisceaux, des préséries en liaison avec le processus de préparation de la documentation interne de conception et de production en fonction des besoins du client. Nous introduisons et mettons à jour la documentation du client sur la base des informations soumises concernant les changements et les modifications.",
    EN: "We accept orders consisting of a few pieces per annum as well as those which require the production output on the level of several thousands of items per month. We produce prototype wires and pre-series in combination with the reconciliation of the design-manufacturing documentation and the customer’s needs. We introduce and update customer’s documentation based on the submitted changes and modification  information.",
    IT: "Produciamo cablaggi che comprendono qualche pezzo per anno e fino alla produzione di migliaia pezzi per anno. Produciamo cablaggi prototipi, preserie che vanno concordati secondo la documentazione interna di progettazione e di produzione e con la documentazione e le esigenze del Cliente. Inseriamo e aggiorniamo la documentazione del Cliente sulla base delle informazioni mandateci e riguardanti le modifiche.  ",
  },
  {
    ID: 17,
    PL: "Kontrola jakości",
    DE: "Qualitätssicherung",
    FR: "Contrôle de la qualité",
    EN: "Quality Control",
    IT: "Controllo qualità",
  },
  {
    ID: 18,
    PL: "Wysoka jakość naszych wyrobów uzyskujemy dzięki:",
    DE: "Die hohe Qualität unserer Produkte erreichen wir durch:",
    FR: "La haute qualité de nos produits est obtenue grâce à :",
    EN: "We achieve high quality of our products due to:",
    IT: "Otteniamo alti standard qualitativi nelle nostre produzioni grazie a:",
  },
  {
    ID: 19,
    PL: [
      "zastosowaniu wysokospecjalistycznych maszyn produkcyjnych z czynną kontrolą procesów,",
      "bazowaniu na sprawdzonych, oryginalnych, certyfikowanych komponentach gwarantujących długą żywotność i funkcjonalność wyrobu ostatecznego,",
      "kontroli parametrów zacisku wykonywaną przez pracowników przed rozpoczęciem i po zakończeniu każdej partii produkcyjnej,",
      "wieloetapowej kontroli przeprowadzanej przez Zespół Kontroli Jakości i podczas montażu,",
      "testy elektryczne i pneumatyczne gotowych wiązek wykonywane na specjalistycznych stołach kontrolnych,",
      "pełną kontrolę procesu produkcyjnego, wraz z ewidencją zleceń produkcyjnych, śledzeniem zmian w dokumentacji, archiwizacją wersji dokumentacji i wyrobów,",
      "wykonywanie wiązek prototypowych i preserii przed uruchomieniem produkcji seryjnej, gwarantuje powtarzalność i dopasowanie wiązek do potrzeb odbiorców.",
    ],
    DE: [
      "Einsatz von hochspezialisierten Produktionsmaschinen mit aktiver Prozesskontrolle,",
      "Verwendung  von zuverlässigen, originellen, zertifizierten Komponenten, die eine langjährige Lebensdauer und Funktionalität des Endprodukts gewährleisten,  ",
      "Kontrolle von Crimpparametern durchgeführt von unseren Mitarbeitern vor dem Begin und nach der Beendung jeder Produktionspartie, ",
      "Mehrstufige Kontrolle durchgeführt von den Mitarbeitern der  Qualitätsabteilung und während der Montage, ",
      "Elektrische und pneumatische Tests der fertigen Kabelbäume durchgeführt auf Spezial-Prüfeinrichtungen,",
      "Vollständige Kontrolle des Produktionsprozesses, inklusive Aufzeichnung von Produktionsaufträge, Verfolgung von Änderungen in der Dokumentation, Archivierung von  den Dokumentation- und Produktversionen,",
      "Die Herstellung von Prototypen von Kabelbäumen und Vorserien vor dem Start der Serienproduktion garantiert Wiederholbarkeit und maßgeschneiderte Kabelbäume.",
    ],
    FR: [
      " l'utilisation de machines de production hautement spécialisées et à un contrôle actif des processus,",
      "l'utilisation de composants éprouvés, originaux et certifiés, qui garantissent la longévité et la fonctionnalité du produit final,",
      "au contrôle des paramètres de serrage par nos employés avant et après chaque lot de production,",
      "à une inspection en plusieurs étapes par l'équipe de contrôle de la qualité et au cours de l'assemblage,",
      "La haute qualité de nos produits est obtenue grâce aux tests électriques et pneumatiques des faisceaux finis effectués sur des tables de test spécialisées,",
      "au contrôle total du processus de production avec l'enregistrement des ordres de production, le suivi des modifications de la documentation, l'archivage des versions de la documentation et des produits,",
      "la production de prototypes de faisceaux et de préséries avant le lancement de la production en série garantit la répétabilité et la personnalisation des faisceaux.",
    ],
    EN: [
      "using highly specialised production machinery with active process control,",
      "relying on tried and tested, original, certified components to guarantee the long life and functionality of the final product,",
      "controling crimping parameters before and after the production of every production batch,",
      "carrying out multi-stage checks by the Quality Control Team and during assembly",
      "carrying out electrical and pneumatic tests of complete wires performed on special control tables,",
      "performing complete control of the production process, together with the full record of production orders, monitoring of changes introduced into the documentation and filing of the individual versions of documentation and goods,",
      "making prototype harnesses and preseries before launching batch production, which guarantees repeatability and of produced wires and their compliance with the customer’s needs.",
    ],
    IT: [
      "utilizzo di macchine per la produzione altamente specializzate con controllo attivo dei processi ",
      "basati su componenti collaudati, originali e certificati che garantiscono la lunga durata e la funzionalità del prodotto finale,",
      "controllo di parametri di aggraffatura svolto dai lavoratori prima e dopo aver finito ogni lotto di produzione",
      "collaudo multifase svolto dal reparto di Collaudo Elettrico e durante l’assemblaggio",
      "collaudo elettrico e pneumatico dei cablaggi prodotti svolto su tavole di collaudo dedicate",
      "pieno collaudo della produzione con la registrazione degli ordini di produzione, monitoraggio delle modifiche nella documentazione, archiviazione delle versioni della documentazione e delle produzioni ",
      "la realizzazione di cablaggi prototipali e preserie prima di avviare la produzione in serie garantisce la ripetibilità e la personalizzazione dei cablaggi.",
    ],
  },

  {
    ID: 26,
    PL: "Dokumentacja",
    DE: "Dokumentation",
    FR: "Documentation",
    EN: "Documentation",
    IT: "Documentazione",
  },
  {
    ID: 27,
    PL: "Niezależnie od otrzymanej dokumentacji od Klienta zawsze wykonywana jest wewnętrzna dokumentacja produkcyjna. Dzięki temu jesteśmy uniezależnieni od formatu i jakości dokumentacji Klienta (mogą to być rysunki w formacie CAD lub wykonane w innych programach -również w 3D. Pracujemy w oparciu o: szkice, wzorce, zdjęcia, rysunki odręczne, itp.). Ewentualne zmiany konstrukcyjne wprowadzane przez Klienta podczas projektowania maszyny, wyjaśniane są na etapie uzgadniania dokumentacji i ofertowania. Na życzenie Klienta wykonujemy jedną lub kilka wiązek prototypowych.",
    DE: "Unabhängig von der erhaltenen Dokumentation des Kunden wird immer unsere interne Produktionsdokumentation ausgeführt. Dadurch sind wir von dem Format und Qualität der Dokumentation des Kunden nicht abhängig (es können CAD-Zeichnungen oder Zeichnungen realisiert in anderen Programmen – darunter 3D-Zeichnungen – sein). Akzeptabel sind auch: Skizzen, Muster, Fotos, Handzeichnungen u.Ä. Eventuelle Konstruktionsänderungen eingeführt von dem Besteller während der Maschinenkonstruktion werden in der Dokumentations- und Angebotsphase geklärt. Auf Wunsch des Kunden werden ein oder mehrere prototypischen Kabelbäumen ausgeführt. ",
    FR: "Indépendamment de la documentation reçue du client, une documentation de production interne est toujours produite. Nous sommes ainsi indépendants du format et de la qualité de la documentation du client (il peut s'agir de dessins en format CAO ou réalisés dans d'autres programmes, y compris en 3D). Nous travaillons sur la base de : des croquis, des modèles, des photographies, des dessins à la main, etc.) Toute modification structurelle apportée par le client lors de la conception de la machine est clarifiée au stade de la documentation et du devis. À la demande du client, nous réalisons un ou plusieurs prototypes de harnais.",
    EN: "Irrespective of the documentation received from the customer, we always perform our own internal production documentation. Thus, we are independent of the format and quality of the customer’s documentation (these could be either CAD drawings or the drawings developed in other programs, including in 3D format. These could also be: drawings, patterns, photographs, manual drawings, etc). Any potential design changes introduced by the customer at the design stage of a machine are explained when documentation is reconciled and the offer is submitted. At customer’s request, we perform one or a couple of prototype harnesses.",
    IT: "Indipendentemente dalla documentazione ricevuta dal Cliente, viene sempre rielaborata la documentazione interna per la produzione. Grazie a questo fatto siamo indipendenti dal formato e della qualità della documentazione ricevuta dal Cliente (ci possono essere i disegni nel formato CAD, oppure fatti in altri programmi, schizzi, schermi, foto, disegni fatti a mano, ecc.). Eventuali modifiche di progetto introdotte dal Cliente durante la progettazione della macchina verranno inseriti nella documentazione e  nell’offerta. Su richiesta del Cliente produciamo uno o più cablaggio prototipo. ",
    ZMIANY: "ZMIANY",
  },
  {
    ID: 28,
    PL: "Oferujemy usługi projektowania i wykonania okablowania dla wyrobu dostarczonego do siedziby naszej firmy lub u Klienta. Mogą to być małe i średnie urządzenia, jak też i całe ciągniki lub inne pojazdy.",
    DE: "Wir bieten Design-Dienstleistungen und Ausführung von Verkabelung an für einen zu unserem Sitz gelieferten Produkt bzw. vor Ort bei dem Kunden. Es können kleine oder mittlere Einrichtungen, sowie auch komplette Traktoren oder andere Fahrzeuge sein.",
    FR: "Nous offrons des services de design et de fabrication pour un produit livré dans nos locaux ou dans les locaux du client. Il peut s'agir d'appareils de petite et moyenne taille, mais aussi de tracteurs entiers ou d'autres véhicules.",
    EN: "We provide harness design and manufacturing services for the goods delivered to our seat or customer’s seat. These could be small and medium-size pieces of equipment, as well as whole trailers, or other vehicles.",
    IT: "Offriamo servizi di progettazione e cablaggio per un prodotto consegnato presso la nostra sede o presso la sede del cliente. Può trattarsi di dispositivi di piccole e medie dimensioni, ma anche di interi trattori o altri veicoli.",
  },
  {
    ID: 29,
    PL: [
      "podczas produkcji stosujemy się do zaleceń klienta dotyczących wyboru stosowanych komponentów,",
      "wyroby są oznaczane i pakowane według wymagań klienta,",
      "istnieje możliwość prowadzenia pełnej korespondencji technicznej w oparciu o dokumentację elektroniczną płaską oraz 3D.",
      "jeżeli zaistnieje taka potrzeba służymy doradztwem w sprawach technicznych, doboru komponentów (złącz, osłon i innych detali), sugestiami dotyczącymi sposobu wykonania wiązki,",
      "sugerujemy rozwiązania techniczne i technologiczne podnoszące jakość i trwałość wyrobu ostatecznego,",
      "projektujemy i wykonujemy urządzenia elektroniczne według specyfikacji i wymagań klienta,",
      "projektujemy oprogramowanie do wykonanych przez nas urządzeń, aby w pełni dopasować się do wymogów i potrzeb klienta.",
    ],
    DE: [
      "Während der Produktion befolgen wir die Kundenempfehlungen bezüglich der Quelle der einzusetzenden Komponenten,",
      "Die Produkte werden nach Kundenanforderungen gekennzeichnet und verpackt,",
      "Es besteht die Möglichkeit, basierend auf der elektronischen 2D- oder 3D-Dokumentation, eine komplette, technische Korrespondenz zu führen,",
      "Im Bedarfsfall können wir Sie in technischen Angelegenheiten, im Komponentenauswahl (Stecker, Schütze und andere Bauelemente) sowie mit Ratschlägen bezüglich Kabelbaumausführung unterstützen,",
      "Wir schlagen technische und technologische  Lösungen vor, die die Qualität und Lebensdauer des finalen Produkts erhöhen lassen, ",
      "Wir gestalten und ausführen elektronische Einrichtungen gemäß dem Entwurf und der Anforderungen des Kunden,",
      "Wir entwerfen eine Software für die von uns hergestellten Einrichtungen, um diese völlig an die Anforderungen und Bedürfnisse des Kunden anzupassen.  ",
    ],
    FR: [
      "Lors de la production, nous suivons les recommandations du client en ce qui concerne le choix des composants utilisés,",
      "les produits sont étiquetés et emballés selon les exigences du client,",
      "il est possible de réaliser une correspondance technique complète sur la base d'une documentation électronique à plat et en 3D.",
      "Si nécessaire, nous pouvons fournir des conseils sur des questions techniques, la sélection des composants (connecteurs, couvertures et autres détails), des suggestions sur la façon de réaliser le faisceau,",
      "Nous proposons des solutions techniques et technologiques pour améliorer la qualité et la durabilité du produit final,",
      "nous créons et fabriquons des appareils électroniques en fonction des spécifications et des exigences des clients,",
      "nous créons le programme des appareils que nous fabriquons pour qu'ils répondent parfaitement aux exigences et aux besoins du client.",
    ],
    EN: [
      "in our production process we comply with customer’s recommendations on the application of components,",
      "the goods are labelled and packed according to customer’s requirements,",
      "there is a possibility to organize the complete technical correspondence using the 2D or 3D electronic documentation.",
      "if necessary, we advise our customers on technical issues, selection of components (clips, covers and other details), and we provide suggestion on the development of wires,",
      "we suggest technical and technological solutions which increase the quality and durability of final product",
      "we design and produce tailor-made electronic equipment based on customer’s design or requirements",
      "we develop software to be used in the equipment manufactured by us in order to fully comply with the customer’s requirements and needs.",
    ],
    IT: [
      "Durante la produzione, seguiamo le raccomandazioni del cliente per quanto riguarda la scelta dei componenti utilizzati,",
      "Le nostre produzioni vengono etichettate e imballate secondo le esigenze del cliente",
      "C’è la possibilità di avere uno scambio di corrispondenza tecnica secondo la documentazione elettronica piana e 3D",
      "Se necessario, possiamo fornire consigli su questioni tecniche, sulla scelta dei componenti (connettori, coperture e altri dettagli), suggerimenti su come realizzare il cablaggio,",
      "Suggeriamo le soluzioni tecniche e tecnologiche che aumentano la qualità e durata del prodotto finale, ",
      "progettiamo e produciamo gli apparecchi elettronici in base alle specifiche e ai requisiti dei clienti,",
      "Progettiamo il software per gli apparecchi da noi prodotti per per soddisfare pienamente i requisiti e le esigenze del cliente.",
    ],
  },
  {
    PL: "Oferta",
    DE: "Angebot",
    FR: "Offre",
    EN: "Offer",
    IT: "Offerta",
  },
];
