import React from "react";
import Header from "../components/elements/Header";
import Footer from "../components/elements/Footer";
import { Grid } from "@material-ui/core";
import Services from "../components/elements/Services";
import WhatWeDo from "../components/elements/WhatWeDo";
// import WorkWithUs from "../components/elements/WorkWithUs";
import Counter from "../components/elements/Counter";
// import BanerVideo from "../components/elements/BanerVideo";
import BanerCarousel from "../components/elements/BanerCarousel";
import { useParams } from "react-router-dom";
import translationsSwitch from "../translations";
import CookiesNotification from "../components/elements/CookieNotification";

const Home = () => {
  const lang = useParams().lang.toUpperCase();

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" id="container">
        <Grid container direction="column" justifyContent="space-between">
          <Grid id="Header" item>
            <Header
              dropMenuTranslation={translationsSwitch("dropMenu", lang)}
              menuTranslation={translationsSwitch("menu", lang)}
              header
            />
          </Grid>
          <Grid id="Baner" item>
            <BanerCarousel />
          </Grid>
          <Grid item>
            <WhatWeDo dataHomePage={translationsSwitch("homepage", lang)} />
          </Grid>
          <Grid id="Services" item>
            <Services dataHomePage={translationsSwitch("homepage", lang)} />
          </Grid>
          <Grid style={{ display: "contents" }} id="Counter" item>
            <Counter dataHomePage={translationsSwitch("homepage", lang)} />
          </Grid>
          <Grid
            style={{ position: "", bottom: "0", width: "100%" }}
            id="Footer"
            item
          >
            <Footer dataFooter={translationsSwitch("footer", lang)} />
            <CookiesNotification />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;
