import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Button from "@material-ui/core/Button";
import color from "../../assets/styles/colors";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  notification: {
    backgroundColor: color.green3,
    color: theme.palette.info.contrastText,
    textAlign: "left",
    border: "1px solid" + color.white,
  },
}));

const CookiesNotification = () => {
  const lang = useParams().lang;
  const classes = useStyles();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    // Sprawdzamy, czy użytkownik już zaakceptował pliki cookies
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    // Jeśli nie zaakceptowano jeszcze plików cookies, pokazujemy powiadomienie
    if (!cookiesAccepted) {
      setShowNotification(true);
    }
  }, []);

  const handleAccept = () => {
    // Zapisujemy informację o akceptacji plików cookies w localStorage
    localStorage.setItem("cookiesAccepted", "true");

    // Ukrywamy powiadomienie
    setShowNotification(false);
  };

  return (
    <Snackbar
      open={showNotification}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={() => setShowNotification(false)}
    >
      <SnackbarContent
        className={classes.notification}
        message={
          <p>
            Ta strona używa plików cookies:
            <br />
            <Link
              style={{ color: color.white }}
              to={"/" + lang + "/cookies-policy"}
            >
              {"- Polityka Cookies"}
            </Link>
            <br />
            <Link
              style={{ color: color.white }}
              to={"/" + lang + "/privacy-rights"}
            >
              {"- Polityka prywatności"}
            </Link>
            <br />
            kliknij aby dowiedzieć się więcej.
          </p>
        }
        action={
          <Button color="inherit" size="small" onClick={handleAccept}>
            Akceptuj
          </Button>
        }
      />
    </Snackbar>
  );
};

export default CookiesNotification;
