export const translations = [
  {
    PL: "Przewody masowe i zasilające",
    DE: "Masse- und Stromkabel",
    FR: "Câbles de terre et d'alimentation",
    EN: "Mass cables and power cables",
    IT: "Cavi di massa e di alimentazione",
  },
  {
    PL: "W chwili obecnej obrabiamy przewody do 120 mm2, niemniej jeżeli zaistnieje taka potrzeba możemy rozszerzyć nasz asortyment.\nPodobnie jak w przypadku wiązek, przewody masowe wykonujemy wg specyfikacji klienta.",
    DE: "Zur Zeit verarbeiten wir die Leitungen bis zu 120 mm2, jedoch nach Bedarf kann unser Sortiment erweitert werden.\nÄhnlich wie im Falle von Kabelbäumen, werden die Masseleitungen gemäß der Kundenspezifikation ausgeführt.  ",
    FR: "Nous traitons actuellement des fils jusqu'à 120 mm2, mais nous pouvons étendre notre gamme si nécessaire.\nComme pour les faisceaux, nous fabriquons des fils de terre selon les spécifications du client.",
    EN: "At present, we process wires up to 120 mm2, but we can extend our range if required.\nAs with harnesses, we produce mass cables to customer specifications.",
    IT: "Attualmente lavoriamo fili fino a 120 mm2, ma possiamo ampliare la nostra gamma se necessario.\nCome per i cablaggi, realizziamo fili di massa su specifica del cliente.",
  },
  {
    PL: "Wykonanie:",
    DE: "Produktionsprozess:",
    FR: "Processus de production :",
    EN: "Perroduction process:",
    IT: "Processo di produzione :",
  },
  {
    PL: [
      "tniemy przewody na wymaganą długość,",
      "umieszczamy je w osłonach (rury PCV, rury karbowane), wyginamy (poprzez zastosowanie klipsów firmy Schlemmer), uszczelniamy - wg wymagań odbiorcy,",
      "oferujemy możliwość zacisku na końcach przewodu szerokiego asortymentu klem, końcówek oczkowych oraz osłon plastikowych i gumowych izolujących te złącza,",
      "dysponujemy także układami bezpieczników i specjalistycznymi skrzynkami typu Power Box, zaprojektowanymi do zastosowań w układach zasilania,",
      "oferujemy przewody i osłony odporne na temperaturę do 170 st. Celsjusza.",
    ],
    DE: [
      "Die Leitungen werden auf die gewünschte Länge zugeschnitten,",
      "Die Leitungen werden in gewünschte Schütze eingelegt (PVC-Rohre, Wellrohre), gebogen (durch Anwendung von Clips der Firma Schlemmer), gedichtet  - laut Anforderungen des Empfängers,",
      "r bieten die Möglichkeit an, an Enden der Leitung  ein breites Sortiment von Klemmen, Ringkabelschuhen, sowie diese Verbinder isolierenden Plastik- und Gummischützen zu crimpen,",
      "Wir verfügen auch über  Systeme der  Sicherungen und eine spezielle Art von Gehäusen wie Power Box, entworfen für die Anwendung in Versorgungsystemen,",
      "Wir haben in unserem Angebot die Leitungen und Schütze mit Temperaturresistenz bis zu 170 Grad Celsius.",
    ],
    FR: [
      "nous coupons les câbles à la longueur requise,",
      "Nous les plaçons dans des gaines (tuyaux en PVC, tuyaux ondulés), nous les plions (à l'aide de clips de Schlemmer) et nous les scellons - selon les besoins du client,",
      "nous offrons la possibilité de fixer aux extrémités des câbles une large gamme de colliers, d'œillets et de couvercles en plastique et en caoutchouc isolant ces connecteurs",
      "Nous disposons également de systèmes de fusibles et de boîtiers d'alimentation spécialisés conçus pour les applications de systèmes d'alimentation,",
      "Nous proposons des tuyaux et des couvercles résistantS à des températures allant jusqu'à 170 degrés Celsius.",
    ],
    EN: [
      "we cut the wires to the required length,",
      "we place them in covers (PVC pipes, corrugated pipes), bend them (using Schlemmer clips) and seal them - according to customer requirements,",
      "we offer the possibility of crimping on the cable ends of a wide range of clamps, ring lugs and plastic and rubber covers to isolate these connectors,",
      "in addition, we also offer fuse circuits and specialised power boxes designed for power system applications,",
      "our wires and covers are resistant to temperatures up to 170 degrees Celsius.",
    ],
    IT: [
      "tagliamo i cavi alla lunghezza richiesta",
      "offriamo la possibilità di fissare alle estremità dei cavi un'ampia gamma di morsetti, terminali occhiello e cover in plastica e gomma che isolano questi connettori,",
      "Disponiamo anche di sistemi di fusibili e di scatole di alimentazione specializzate, progettate per le applicazioni dei sistemi di alimentazione,",
      "Li inseriamo in cover (tubi in PVC, tubi corrugati), li pieghiamo (utilizzando le clip Schlemmer) e li sigilliamo - secondo le esigenze del cliente,",
      "Offriamo cavi e coperture in grado di resistere a temperature fino a 170 gradi Celsius.",
    ],
  },
  {
    PL: "Oferta",
    DE: "Angebot",
    FR: "Offre",
    EN: "Offer",
    IT: "Offerta",
  },
];
