import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import PageWrapper from "../PageWrapper";
import { Typography } from "@material-ui/core";
// import color from "../../assets/styles/colors";
import banerImage from "../../assets/jpeg/baners/banerHistory.jpg";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Data from "../../translations/aboutUs/history";
import { useParams } from "react-router-dom";
import color from "../../assets/styles/colors";
const dataNavigator = [
  {
    PL: "o firmie",
    DE: "über uns",
    FR: "à propos de nous",
    EN: "about us",
    IT: "chi siamo",
  },
  {
    PL: "historia",
    DE: "geschichte",
    FR: "histoire",
    EN: "history",
    IT: "storia",
  },
  {
    PL: "Zobacz jak się rozwijamy:",
    DE: "Sehen, wie wir uns entwickeln:",
    FR: "Voir comment nous évoluons:",
    EN: "See how we grow:",
    IT: "Guarda come ci sviluppiamo:",
  },
];

const History = () => {
  const lang = useParams().lang.toLocaleUpperCase();
  // console.log(Data.History[1].content[lang]);
  return (
    <PageWrapper
      banerImage={banerImage}
      naviChild1={dataNavigator[0][lang]}
      naviChild2={dataNavigator[1][lang]}
      title={dataNavigator[2][lang]}
    >
      <Timeline position="alternate">
        {Data.History.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot color="success" />
              <TimelineConnector color="#0000" />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {item.year}
              </Typography>
              {Array.isArray(item.content[lang]) ? (
                item.content[lang].map((contentItem, subIndex) => (
                  <Typography key={subIndex}>
                    {" "}
                    {<b style={{ color: color.green3 }}>- </b>}
                    {contentItem}
                  </Typography>
                ))
              ) : (
                <Typography style={{}}>
                  {<b style={{ color: color.green3 }}>- </b>}
                  {item.content[lang]}
                </Typography>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </PageWrapper>
  );
};

export default History;
